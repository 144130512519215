import { SET_SIDEBAR_POSITION, SIDEBAR_ERROR } from '../actions/types';

const initialState = {
    position: null,
    error: {},
    loading: true
}

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case SET_SIDEBAR_POSITION:
            return {
                ...state,
                position: payload,
                loading: false
            };
        case SIDEBAR_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }
}