import React, {Fragment} from 'react';

const SearchBarClienteItem = ({ clienti, onCLickSelectCliente }) => {
    if(clienti && clienti.length > 0){
        return (
            <Fragment>
                {clienti.map((cliente, i) => (
                <div className="mb-2" key={i}>
                    <a className="cliente-i" onClick = { e => onCLickSelectCliente(e, cliente._id)} >{cliente.cognome} {cliente.nome}</a>
                </div>
                ))}
            </Fragment>  
        )
    }else{
        return null
    }
}

export default SearchBarClienteItem;