import { GET_MONTATORI, CREATE_MONTATORE_SUCCESS, MONTATORE_ERROR } from "../actions/types";

const initialState = {
    montatore: null,
    montatori: [],
    error: {},
    loading: true
}

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case GET_MONTATORI:
            return {
                ...state,
                montatori: payload,
                loading: false
            };
        case CREATE_MONTATORE_SUCCESS:
            return {
                ...state,
                montatori: payload,
                loading: false
            };
        case MONTATORE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}