import axios from 'axios';
import { GET_MONTATORI, CREATE_MONTATORE_SUCCESS, MONTATORE_ERROR } from './types';
import { setAlert } from './alert';
import { setSidebarPosition } from '../actions/sidebar'


export const getMontatori = () => async dispatch => {
    try{
        const res = await axios.get('/api/montatore/montatori');
        dispatch({
            type: GET_MONTATORI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: MONTATORE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const createNewMontatore = ({ username, nome, cognome, password, coloreCalendario }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ username, nome, cognome, password, coloreCalendario });
    try{
        const res = await axios.post('/api/montatore/nuovoMontatore', body, config);
        dispatch({
            type: CREATE_MONTATORE_SUCCESS,
            payload: res.data
        });
        dispatch(setAlert('Nuovo montatore creato!', 'success'));
        dispatch(setSidebarPosition('admin', 'montatori'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: MONTATORE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};