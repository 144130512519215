export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const SET_SIDEBAR_POSITION = 'SET_SIDEBAR_POSITION';
export const SIDEBAR_ERROR = 'SIDEBAR_ERROR';


export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';


export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';


export const GET_CLIENTI = 'GET_CLIENTI';
export const GET_CLIENTE = 'GET_CLIENTE';
export const MODIFICA_CLIENTE = 'MODIFICA_CLIENTE';
export const CREATE_CLIENTE_SUCCESS = 'CREATE_CLIENTE_SUCCESS';
export const FILTRA_CLIENTI = 'FILTER_CLIENTI';
export const CLIENTE_ERROR = 'CLIENTE_ERROR';


export const GET_PREVENTIVI = 'GET_PREVENTIVI';
export const GET_PREVENTIVO = 'GET_PREVENTIVO';
export const CREATE_PREVENTIVO = 'CREATE_PREVENTIVO';
export const FILTRA_PREVENTIVI = 'FILTRA_PREVENTIVI';
export const PREVENTIVO_CONFERMATO = 'PREVENTIVO_CONFERMATO';
export const MODIFICA_PREVENTIVO = 'MODIFICA_PREVENTIVO';
export const MODIFICA_PREVENTIVO_CONFERMATO = 'MODIFICA_PREVENTIVO_CONFERMATO';
export const CONFERMA_PREVENTIVO_ANNULLATA = 'CONFERMA_PREVENTIVO_ANNULLATA';
export const CLEAN_PREVENTIVI = 'CLEAN_PREVENTIVI';
export const ELIMINA_PREVENTIVO = 'ELIMINA_PREVENTIVO';
export const CLEAN_PREVENTIVO = 'CLEAN_PREVENTIVO';
export const CLEAN_ALLEGATI = 'CLEAN_ALLEGATI';
export const UPLOAD_ALLEGATO = 'UPLOAD_ALLEGATO';
export const UPLOAD_ALLEGATI_MODIFICA_PREVENTIVO = 'UPLOAD_ALLEGATI_MODIFICA_PREVENTIVO';
export const ELIMINA_ALLEGATO = 'ELIMINA_ALLEGATO';
export const PREVENTIVO_ERROR = 'PREVENTIVO_ERROR';
export const UPLOAD_ERROR = 'UPLOAD_ERROR';


export const GET_VENDITORI = 'GET_VENDITORI';
export const CREATE_VENDITORE_SUCCESS = 'CREATE_VENDITORE_SUCCESS';
export const VENDITORE_ERROR = 'VENDITORE_ERROR';


export const GET_MONTATORI = 'GET_MONTATORI';
export const CREATE_MONTATORE_SUCCESS = 'CREATE_MONTATORE_SUCCESS';
export const MONTATORE_ERROR = 'MONTATORE_ERROR';


export const GET_ORDINI = 'GET_ORDINI';
export const GET_TUTTI_ORDINI = 'GET_TUTTI_ORDINI';
export const GET_ORDINI_CONFERMATI = 'GET_ORDINI_CONFERMATI';
export const GET_ORDINI_DA_CONSEGNARE = 'GET_ORDINI_DA_CONSEGNARE';
export const GET_ORDINI_DA_CONSEGNARE_MONTATORI = 'GET_ORDINI_DA_CONSEGNARE_MONTATORI';
export const GET_ORDINI_DEBITORI = 'GET_ORDINI_DEBITORI';
export const GET_ORDINE = 'GET_ORDINE';
export const MODIFICA_PAGAMENTO = 'MODIFICA_PAGAMENTO';
export const FILTRA_ORDINI = 'FILTRA_ORDINI';
export const FILTRA_ORDINI_CONFERMATI = 'FILTRA_ORDINI_CONFERMATI';
export const FILTRA_ORDINI_DA_CONSEGNARE = 'FILTRA_ORDINI_DA_CONSEGNARE';
export const FILTRA_ORDINI_DA_CONSEGNARE_MONTATORI = 'FILTRA_ORDINI_DA_CONSEGNARE_MONTATORI';
export const FILTRA_ORDINI_DEBITORI = 'FILTRA_ORDINI_DEBITORI';
export const GET_TUTTI_ORDINI_DEBITORI = 'GET_TUTTI_ORDINI_DEBITORI';
export const SET_ANNULLA_ORDINE = 'SET_ANNULLA_ORDINE';
export const SET_DATA_CONSEGNA_ORDINE = 'SET_DATA_CONSEGNA_ORDINE';
export const SET_DATA_CONSEGNA_ORDINE_ON_DRAG = 'SET_DATA_CONSEGNA_ORDINE_ON_DRAG';
export const SET_ORDINE_CONSEGNATO = 'SET_ORDINE_CONSEGNATO';
export const MODIFICA_MAGAZZINO = 'MODIFICA_MAGAZZINO';
export const SET_TUTTO_MAGAZZINO_ELEMENTI_ORDINE = 'SET_TUTTO_MAGAZZINO_ELEMENTI_ORDINE';
export const MODIFICA_ORDINE = 'MODIFICA_ORDINE';
export const ABILITA_CONSEGNA = 'ABILITA_CONSEGNA';
export const SET_PAGAMENTO_NOTE_ORDINE = 'SET_PAGAMENTO_NOTE_ORDINE';
export const CREATE_ORDINE_RIPARAZIONE = 'CREATE_ORDINE_RIPARAZIONE';
export const UPLOAD_ALLEGATI_ORDINE = 'UPLOAD_ALLEGATI_ORDINE';
export const UPLOAD_ALLEGATO_ORDINE = 'UPLOAD_ALLEGATO_ORDINE';
export const CLEAN_ORDINI = 'CLEAN_ORDINI';
export const CLEAN_ORDINE = 'CLEAN_ORDINE';
export const CLEAN_ALLEGATI_ORDINE = 'CLEAN_ALLEGATI_ORDINE';
export const ORDINE_ERROR = 'ORDINE_ERROR';

export const GET_STATISTICHE_VENDITE_MENSILI_ULTIMO_ANNO = 'GET_STATISTICHE_VENDITE_MENSILI_ULTIMO_ANNO';
export const GET_STATISTICHE_CONFRONTO_ORDINI_PREVENTIVI = 'GET_STATISTICHE_CONFRONTO_ORDINI_PREVENTIVI';
export const GET_STATISTICHE_INCASSO_PUNTI_VENDITA = 'GET_STATISTICHE_INCASSO_PUNTI_VENDITA';
export const GET_STATISTICHE_CONFRONTO_ORDINI_PREVENTIVI_VENDITORI = 'GET_STATISTICHE_CONFRONTO_ORDINI_PREVENTIVI_VENDITORI';
export const GET_STATISTICHE_INCASSI_VENDITORI_TRIMESTRALI = 'GET_STATISTICHE_INCASSI_VENDITORI_TRIMESTRALI';
export const GET_STATISTICHE_TIPOLOGIA_ORDINI = 'GET_STATISTICHE_TIPOLOGIA_ORDINI';
export const STATISTICHE_ERROR = 'STATISTICHE_ERROR';

