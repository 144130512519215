import { combineReducers } from 'redux';

import sidebar from './sidebar';
import alert from './alert';
import auth from './auth';
import cliente from './cliente';
import preventivo from './preventivo';
import ordine from './ordine';
import venditore from './venditore';
import montatore from './montatore';
import statistiche from './statistiche';



export default combineReducers ({
    sidebar,
    alert,
    auth,
    cliente,
    preventivo,
    ordine,
    venditore,
    montatore,
    statistiche
});