import axios from 'axios';
import { GET_STATISTICHE_VENDITE_MENSILI_ULTIMO_ANNO, GET_STATISTICHE_CONFRONTO_ORDINI_PREVENTIVI, GET_STATISTICHE_INCASSO_PUNTI_VENDITA, GET_STATISTICHE_CONFRONTO_ORDINI_PREVENTIVI_VENDITORI, GET_STATISTICHE_INCASSI_VENDITORI_TRIMESTRALI, GET_STATISTICHE_TIPOLOGIA_ORDINI, STATISTICHE_ERROR } from './types';

export const getIncassoPuntiVendita = (dataIncassoPuntoVenditaStart, dataIncassoPuntoVenditaEnd) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        dataIncassoPuntoVenditaStart: dataIncassoPuntoVenditaStart,
        dataIncassoPuntoVenditaEnd: dataIncassoPuntoVenditaEnd
    });
    try{
        const res = await axios.post('/api/statistiche/incassoPuntiVendita', body, config);
        dispatch({
            type: GET_STATISTICHE_INCASSO_PUNTI_VENDITA,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: STATISTICHE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getVenditeMensiliUltimoAnno = () => async dispatch => {
    try{
        const res = await axios.get('/api/statistiche/venditeMensiliUltimoAnno');
        dispatch({
            type: GET_STATISTICHE_VENDITE_MENSILI_ULTIMO_ANNO,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: STATISTICHE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getConfrontoOrdiniPreventivi = (dataConfrontoOrdiniPreventiviStart, dataConfrontoOrdiniPreventiviEnd) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        dataConfrontoOrdiniPreventiviStart: dataConfrontoOrdiniPreventiviStart,
        dataConfrontoOrdiniPreventiviEnd: dataConfrontoOrdiniPreventiviEnd
    });
    try{
        const res = await axios.post('/api/statistiche/confrontoOrdiniPreventivi', body, config);
        dispatch({
            type: GET_STATISTICHE_CONFRONTO_ORDINI_PREVENTIVI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: STATISTICHE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getConfrontoPreventiviOrdiniVenditori = (dataConfrontoOrdiniPreventiviVenditoriStart, dataConfrontoOrdiniPreventiviVenditoriEnd) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        dataConfrontoOrdiniPreventiviVenditoriStart: dataConfrontoOrdiniPreventiviVenditoriStart,
        dataConfrontoOrdiniPreventiviVenditoriEnd: dataConfrontoOrdiniPreventiviVenditoriEnd
    });
    try{
        const res = await axios.post('/api/statistiche/confrontoPreventiviOrdiniVenditori', body, config);
        dispatch({
            type: GET_STATISTICHE_CONFRONTO_ORDINI_PREVENTIVI_VENDITORI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: STATISTICHE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getTipologiaOrdini = (dataTipologiaPreventiviOrdiniStart, dataTipologiaPreventiviOrdiniEnd) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        dataTipologiaPreventiviOrdiniStart: dataTipologiaPreventiviOrdiniStart,
        dataTipologiaPreventiviOrdiniEnd: dataTipologiaPreventiviOrdiniEnd
    });
    try{
        const res = await axios.post('/api/statistiche/tipologiaOrdini', body, config);
        dispatch({
            type: GET_STATISTICHE_TIPOLOGIA_ORDINI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: STATISTICHE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const getIncassiVenditoriTrimestrali = (dataIncassoVenditoreStart, dataIncassoVenditoreEnd) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        dataIncassoVenditoreStart: dataIncassoVenditoreStart,
        dataIncassoVenditoreEnd: dataIncassoVenditoreEnd
    });
    try{
        const res = await axios.post('/api/statistiche/incassiVenditoriTrimestrali', body, config);
        dispatch({
            type: GET_STATISTICHE_INCASSI_VENDITORI_TRIMESTRALI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: STATISTICHE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};
