import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentTimezonePlugin from '@fullcalendar/moment-timezone';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from 'date-fns/locale/it';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import Spinner from '../../layout/Spinner';
import Ordine from '../ordini/Ordine';
import { switchSidebarLogo, trasformaCifra } from '../../../utility/Utility';
import { getOrdiniDaConsegnare, filtraOrdiniDaConsegnare, setDataConsegnaOrdineOnDrag, cleanOrdini } from '../../../actions/ordine';
import { setSidebarPosition } from '../../../actions/sidebar';
import { getMontatori } from '../../../actions/montatore';
registerLocale('it', it)
const Consegne = ({ auth: { user }, sidebar, ordine: { ordini, numberOrdini, loading }, montatore: {montatori}, getOrdiniDaConsegnare, filtraOrdiniDaConsegnare, setDataConsegnaOrdineOnDrag, setSidebarPosition, cleanOrdini, getMontatori }) => {

    const [totalOrdini, setTotalOrdini] = useState(null);
    const [ordineOpen, setOrdineOpen] = useState(false);
    const [idOrdine, setIdOrdine] = useState(null);
    const [puntoVenditaOrdini, setPuntoVenditaOrdini] = useState('');
    const [statoOrdini, setStatoOrdini] = useState('tutti');
    const stato = statoOrdini;
    const puntoVendita = puntoVenditaOrdini;
    const [modalCalendario, setModalCalendar] = useState(false);
    const [parola, setParola] = useState("");
    const [dataAzione, setDataAzione] = useState(new Date());
    const data = dataAzione;

    
    const [calendarWeekends, setCalendarWeekends] = useState(true);
    const [calendarEvents, setCalendarEvents] = useState([]);

    let calendarComponentRef = React.createRef();
    
    const [montatore, setMontatore] = useState(null);
    const [ordineConsegna, setOrdineConsegna] = useState({idOrdine: '', numeroOrdine: '', nome: '', cognome: ''});
    const [nuovaDataConsegnaStart, setNuovaDataConsegnaStart] = useState(new Date());
    const [nuovaDataConsegnaEnd, setNuovaDataConsegnaEnd] = useState(new Date());
    const [ordiniData, setOrdiniData] = useState([]);

    useEffect(() => {
        let array = []
        let arrayCalendar = [];
        ordini.map(el => {
            if(el.stato != 'consegnato'){
                array.push(el)
            }
            if(el.dataConsegnaStart){
                arrayCalendar.push({
                    id: el._id,
                    montatore: el.montatore._id,
                    title: 'N.'+el.numero+' - '+el.cliente.cognome+' '+el.cliente.nome + ' ' + (el.montatore && el.montatore.cognome ? el.montatore.cognome : '') + ' ' + (el.montatore && el.montatore.nome ? el.montatore.nome : '') + (el.stato == 'consegnato' ? ' - (consegnato)' : ''),
                    start: moment(el.dataConsegnaStart).format('YYYY-MM-DD HH:mm'),
                    end: moment(el.dataConsegnaEnd).format('YYYY-MM-DD HH:mm'),
                    //start: moment(el.dataConsegnaStart).utcOffset(120).format('YYYY-MM-DD HH:mm'),
                    //end: moment(el.dataConsegnaEnd).utcOffset(120).format('YYYY-MM-DD HH:mm'),

                    color: el.montatore.coloreCalendario,
                    allDay: false
                })
                  
            }
        })
          
        setCalendarEvents(arrayCalendar)
        setOrdiniData(array)
        setTotalOrdini(array.length)
    }, [ordini]);


    const handleDateClick = arg => {
        ordini.filter((ordine) =>{
            if(!ordine.dataConsegnaStart){
                setModalCalendar(true)
                setNuovaDataConsegnaStart(new Date(arg.date))
                setNuovaDataConsegnaEnd(new Date(arg.date))
                return
            }
        })
      };

      const submitConsegnaPreventivo = arg => {
        setModalCalendar(false)
        let startDate = new Date(nuovaDataConsegnaStart)
        let endDate = new Date (nuovaDataConsegnaEnd)
        setDataConsegnaOrdineOnDrag(ordineConsegna.idOrdine, startDate, endDate, montatore)
        const colorMontatore = montatori.filter(elemento => elemento._id === montatore)[0].coloreCalendario;
            setCalendarEvents([...calendarEvents, {
                title: 'N.'+ordineConsegna.numeroOrdine+' - '+ordineConsegna.cognome+' '+ordineConsegna.nome + ' ' +(ordineConsegna.montatore && ordineConsegna.montatore.cognome ? ordineConsegna.montatore.cognome : '') + ' ' +(ordineConsegna.montatore && ordineConsegna.montatore.nome ? ordineConsegna.montatore.nome : ''),
                start: moment(startDate).format('YYYY-MM-DD HH:mm'),
                end: moment(endDate).format('YYYY-MM-DD HH:mm'),
                //start: moment(startDate).utcOffset(120).format('YYYY-MM-DD HH:mm'),
                //end: moment(endDate).utcOffset(120).format('YYYY-MM-DD HH:mm'),
                allDay: false,
                color: colorMontatore,
                montatore: ordineConsegna.montatore._id
              }])
      }; 

    useEffect(() => {
        getOrdiniDaConsegnare();
        getMontatori();
        return () => {
            cleanOrdini();
        }
    }, [getOrdiniDaConsegnare, cleanOrdini]);

    

    useEffect(() => {
        if(sidebar.position === 'consegne'){
            getOrdiniDaConsegnare();
            setOrdineOpen(false)
        }
    }, [sidebar, getOrdiniDaConsegnare]);
    
    const [openRow, setOpenRow] = useState(null);
    const onClickToggleRow = (id) => {
        if(id === openRow){
            setOpenRow(null);
        }else{
            setOpenRow(id);
        }
    }

    const onChangeSetOrdineConsegna = (idOrdine) => {
        ordini.map((ordine, i) => {
            if(ordine._id === idOrdine){
                setOrdineConsegna({ ...ordineConsegna, idOrdine: idOrdine, numeroOrdine: ordine.numero, nome: ordine.cliente.nome, cognome: ordine.cliente.cognome });
            }
        })
    }



    const onClickGetOrdine = (idOrdine) => {
        setSidebarPosition(user.role, 'ordineconsegna')
        setOrdineOpen(true)
        setIdOrdine(idOrdine)
    }


    const onChangeSearchOrdine = e => {
        setParola(e);
        if(user.role == 'venditore'){
            filtraOrdiniDaConsegnare(e, stato, puntoVendita)
        }else{
            filtraOrdiniDaConsegnare(e, stato, puntoVendita)
        }
    }


    const onChangePuntoVenditaOrdine = e => {
        setPuntoVenditaOrdini(e.target.value);
        filtraOrdiniDaConsegnare(parola, stato, e.target.value)
    }

    const onChangeStatoOrdine = e => {
        setStatoOrdini(e.target.value);
        filtraOrdiniDaConsegnare(parola, e.target.value, puntoVendita)
    }

    const onClickChiudiOrdine = e => {
        setOrdineOpen(false);
        getOrdiniDaConsegnare();
    }

    return ( loading || !ordini || !montatori ? <Spinner /> :
        <div onClick={(e) => e.target.id && e.target.id === 'modalpopup' ? setModalCalendar(false) : null} className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="subheader py-3 py-lg-8  subheader-transparent " id="kt_subheader">
                <div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                            <div className="d-flex align-items-baseline flex-wrap mr-5">
                                <h2 className="subheader-title text-dark font-weight-bold my-1 mr-3">Gestione consegne</h2>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center">
                        <a onClick={() => setSidebarPosition(user.role, 'calendarioconsegne')} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-calendar-alt icon-lg"></i>
                            </span> Calendario
			            </a>
                    </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    {ordineOpen ? 
                        <Ordine
                            idOrdine={idOrdine}
                            onClickChiudiOrdine={onClickChiudiOrdine}
                        /> :
                        <div className="card card-custom">
                            <div className="card-header flex-wrap border-0 pt-6 pb-0">
                                <div className="card-title">
                                    <h3 className="card-label">
                                        Lista Ordini
				                        <span className="d-block  pt-2 font-size-sm">Tutti gli ordini</span>
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="mb-7">
                                    <div className="row align-items-center">
                                        <div className="col-lg-9 col-xl-8">
                                            <div className="row align-items-center">
                                                <div className="col-md-4 my-2 my-md-0">
                                                    <div className="input-icon">
                                                        <input onChange={e => onChangeSearchOrdine(e.target.value)} value={parola} type="text" className="form-control" placeholder="Cerca..." id="kt_datatable_search_query" />
                                                        <span><i className="flaticon2-search-1 "></i></span>
                                                    </div>
                                                </div>
                                                {user && user.puntoVendita.nomeDb === 'arca_arredamenti' && user.role != 'venditore' ?
                                                    <div class="col-md-4 my-2 my-md-0">
                                                        <div className="input">
						                                    <select class="form-control" name="puntoVenditaPreventivo"  onChange={e => onChangePuntoVenditaOrdine(e)} >
							                                    <option selected={puntoVendita === ''} value={''}>Qualsiasi Punto Vendita</option>
							                                    <option selected={puntoVendita === 'arca_arredamenti'} value={'arca_arredamenti'}>Arca Arredamenti</option>
							                                    <option selected={puntoVendita === 'lube_creo_store_pavia'} value={'lube_creo_store_pavia'}>Lube Creo Store Pavia</option>
							                                    <option selected={puntoVendita === 'lube_store_stradella'} value={'lube_store_stradella'}>Lube Store Stradella</option>
							                                    <option selected={puntoVendita === 'veneta_cucine_pavia'} value={'veneta_cucine_pavia'}>Veneta Cucine Pavia</option>
						                                    </select>
					                                    </div>
                                                    </div>
                                                    : null
                                                }
                                                <div class="col-md-4 my-2 my-md-0">
                                                    <div className="input">
						                                <select class="form-control" name="statoOrdine"  onChange={e => onChangeStatoOrdine(e)} >
							                                <option selected={stato === 'tutti'} value={'tutti'}>Qualsiasi Stato</option>
                                                            <option selected={stato === 'attesadataconsegna'} value={'attesadataconsegna'}>Consegna da programmare</option>
							                                <option selected={stato === 'consegna'} value={'consegna'}>Consegna programmata</option>
							                                <option selected={stato === 'consegnaparziale'} value={'consegnaparziale'}>Consegna parziale programmata</option>
							                                <option selected={stato === 'consegnato'} value={'consegnato'}>Consegnato</option>
						                                </select>
					                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded" id="kt_datatable" >
                                    <table className="datatable-table" style={{display: "block"}}>
                                        <thead className="datatable-head">
                                            <tr className="datatable-row" style={{left: "0px"}}>
                                                <th className="datatable-cell datatable-toggle-detail">
                                                    <span></span>
                                                </th>
                                                <th data-field="OrderID" className="datatable-cell">
                                                    <span style={{width: "50px"}}>N.</span>
                                                </th>
                                                <th data-field="ShipAddress" className="datatable-cell">
                                                    <span style={{width: "110px"}}>Cognome</span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell">
                                                    <span style={{width: "110px"}}>Nome</span>
                                                </th>
                                                <th data-field="ShipDate" className="datatable-cell">
                                                    <span style={{width: "160px"}}>Venditore</span>
                                                </th>
                                                <th data-field="Status" className="datatable-cell">
                                                    <span style={{width: "100px"}}>Totale</span>
                                                </th>
                                                <th data-field="Status" className="datatable-cell">
                                                    <span style={{width: "180px"}}>Stato</span>
                                                </th>
                                                <th data-field="Actions" data-autohide-disabled="false" className="datatable-cell">
                                                    <span style={{width: "80px"}}>Modifica</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="datatable-body" >
                                            {loading ? null : 
                                                ordiniData.map((ordine, i) => (
                                                    <Fragment>
                                            <tr data-row="0" className="datatable-row" style={{ background: user.role == 'admin' &&  ordine.acconto < parseFloat(ordine.totale)*0.3 ? 'rgb(255 155 166)' : null }}>
                                                <td className="datatable-cell datatable-toggle-detail">
                                                    <a className="datatable-toggle-detail" onClick ={() => onClickToggleRow(ordine._id)} >
                                                        <i className={ordine._id === openRow ? "fa fa-caret-down" : "fa fa-caret-right"}></i>
                                                    </a>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "50px"}}>{ordine.numero}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "110px"}}>{ordine.cliente.cognome.charAt(0).toUpperCase() + ordine.cliente.cognome.slice(1)}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "110px"}}>{ordine.cliente.nome.charAt(0).toUpperCase() + ordine.cliente.nome.slice(1)}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "160px"}}>
                                                        <div class="d-flex align-items-center">
                                                            <div class="mr-1">
                                                                <a class="text-dark-75  mb-0">{ordine.venditore.cognome.charAt(0).toUpperCase() + ordine.venditore.cognome.slice(1)}</a>                        
                                                            </div>   
                                                            <div class="symbol symbol-50 symbol-sm flex-shrink-0">
                                                                <div class="symbol-label">
                                                                    <img class="h-75 align-self-center" src={switchSidebarLogo(ordine.puntoVendita.nomeDb, user.role)} alt="photo"/>
                                                                </div>
                                                            </div>                  
                                                        </div>
                                                    </span>
                                                </td>
                                                <td data-field="Country" aria-label="Brazil" className="datatable-cell">
                                                    <span style={{width: "100px"}}>{trasformaCifra(ordine.totale)}€</span>
                                                </td>
                                                <td data-field="Status" aria-label="3" className="datatable-cell">
                                                    <span style={{width: "180px"}} >                                                    
                                                        <span className={`${ordine.stato ==='attesadataconsegna' ? "label label-lg font-weight-bold btn btn-info label-inline" : ordine.stato ==='consegnaparziale' ? "btn-custom btn btn-light-success font-weight-bold mr-2-light-success label-inline" : "label label-lg font-weight-bold btn btn-success label-inline"}`}>{ordine.stato === 'attesadataconsegna' ? "Da programmare" : ordine.stato === 'consegnaparziale' ? "Programmata parz." : "Programmata" }</span> 
                                                    </span>
                                                </td>
                                                <td data-field="Actions" data-autohide-disabled="false" aria-label="null" className="datatable-cell">
                                                    <span style={{overflow: "visible", position: "relative", width: "80px"}}>
                                                        <a onClick ={() => onClickGetOrdine(ordine._id)} className="btn btn-sm btn-clean btn-icon mr-2" title="Edit details">
                                                            <span className="svg-icon svg-icon-md">
                                                                <i className="fas fa-edit icon-md"></i>
                                                            </span>
                                                        </a>
                                                        {ordine.ordineRiparato ?
                                                        <a onClick ={() => onClickGetOrdine(ordine.ordineRiparazioneAllegato)} className="btn btn-sm btn-clean btn-icon mr-2" title="Edit details">
                                                            <span className="svg-icon svg-icon-md">
                                                                <i className="far fa-arrow-alt-circle-right icon-lg"></i>
                                                                rip.
                                                            </span>
                                                        </a>
                                                        : null}
                                                        {ordine.ordineRiparazione ?
                                                        <a onClick ={() => onClickGetOrdine(ordine.ordineRiparazioneAllegato)} className="btn btn-sm btn-clean btn-icon mr-2" title="Edit details">
                                                            <span className="svg-icon svg-icon-md">
                                                                <i className="fas fa-arrow-alt-circle-left icon-lg"></i>
                                                                orig.
                                                            </span>
                                                        </a>
                                                        : null}
                                                        </span>
                                                </td>
                                            </tr>
                                            <tr className="datatable-row-detail" style={openRow!==ordine._id ? {display: "none"} : null}>
                                                <td className="datatable-detail" colspan="13">
                                                    <table>
                                                    <tr className="datatable-row">
                                                    <td className="datatable-cell">
                                                                <span>Fattura:</span>
                                                            </td>
                                                    <td data-field="Status" aria-label="3" className="datatable-cell">
                                                                <span style={{width: "110px"}}> 
                                                                    <span className={`label label-lg font-weight-bold label-light${ordine.fattura ? "-primary label-inline" : "-danger label-inline" }`}>{ordine.fattura ? "Si" : "No"}</span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Caparra confirmatoria:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{trasformaCifra(ordine.acconto)}€</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Saldo:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{trasformaCifra(ordine.saldo)}€</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Data preventivo:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}><Moment format='DD/MM/YYYY'>{ordine.date}</Moment></span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Previsione consegna:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{ordine.dataPrevista ? <Moment format='DD/MM/YYYY'>{ordine.dataPrevista}</Moment> : "Da definire"}</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Data conferma preventivo:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}><Moment format='DD/MM/YYYY'>{ordine.dataConfermaPreventivo}</Moment></span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Data consegna:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{ordine.dataConsegnaStart ? <Moment format='DD/MM/YYYY'>{ordine.dataConsegnaStart}</Moment> : "Da definire"}</span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            </Fragment>
                                                )
                                                )}
                                        </tbody>
                                    </table>
                                    <div className="datatable-pager datatable-paging-loaded">
                                        <div className="datatable-pager-info">
                                            <span className="datatable-pager-detail">Risultati: {totalOrdini}</span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class={`modal fade ${modalCalendario ? 'show' : null}`} style={{display: modalCalendario ? 'block': 'none'}} id="modalpopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document" >
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Pianifica consegna</h5>
                <button onClick ={() => setModalCalendar(false)} type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i aria-hidden="true" class="ki ki-close"></i>
                </button>
            </div>
            <div class="modal-body">
                <div className="ordine mb-3">
                    <select class="form-control" name="statoPreventivo"  onChange={e => onChangeSetOrdineConsegna(e.target.value)} >
                    <option selected disabled >Selezione ordine... </option>
                        {ordini.map((ordine) => (
                         !ordine.dataConsegnaStart ?
				        <option value={ordine._id}>{ordine.numero}: {ordine.cliente.cognome} {ordine.cliente.nome}</option>
                        : null
                         ))}
                </select>
            </div>
            <div className="squadra mb-3">
            <select class="form-control" name="statoPreventivo"  onChange={e => setMontatore(e.target.value)} >
				<option selected disabled >Seleziona montatore</option>
                {montatori.map((montatore) => (
				<option value={montatore._id}>{montatore.cognome} - {montatore.nome}</option>
                ))}
			</select>
            </div>
            <div class="card-title py-5"
            ><span class="mx-2">Da</span>
            <DatePicker
                selected={nuovaDataConsegnaStart}
                onChange={date => setNuovaDataConsegnaStart(date)}
                showTimeSelect
                minTime={setHours(setMinutes(new Date(), 0), 8)}
                maxTime={setHours(setMinutes(new Date(), 0), 20)}
                className="field w-input form-control"
                dateFormat='dd/MM/yyyy HH:mm'
                locale="it"
            />
             <span className="mx-2">a</span>
            <DatePicker
                selected={nuovaDataConsegnaEnd}
                onChange={date => setNuovaDataConsegnaEnd(date)}
                showTimeSelect
                minTime={setHours(setMinutes(new Date(), 0), 8)}
                maxTime={setHours(setMinutes(new Date(), 0), 20)}
                className="field w-input form-control"
                dateFormat='dd/MM/yyyy HH:mm'
                locale="it"
            />
                </div>
            </div>
            <div class="modal-footer">
                <button onClick ={() => setModalCalendar(false)} type="button" class="btn btn-primary font-weight-bold" data-dismiss="modal">Annulla</button>
                <button disabled={!ordineConsegna || !montatore ? true : false } onClick ={() => submitConsegnaPreventivo()} type="button" className="btn btn-success mr-2 salva-btn">Salva</button> 
            </div>
        </div>
    </div>
</div>
                                <div className="animated fadeIn p-4 demo-app">
          <div className="demo-app">
              
        <div className="demo-app-calendar">
          <FullCalendar
            timeZone= 'Europe/Rome'
            editable={true}
            slotDuration= '00:30:00'
            expandRows= {true}
            locale= 'it'
            firstDay= '1'
            hiddenDays= {[ 0, 6 ]}
            allDaySlot= {false}
            initialView= 'timeGridWeek'
            slotMinTime='08:00:00'
            slotMaxTime='19:30:00'
            slotLabelFormat= {{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: false, 
                meridiem: 'short'
              }}
            displayEventTime= {true}
            buttonText= {{
                today:    'Oggi',
                month:    'Mese',
                week:     'Settimana',
                day:      'Giorno',
                list:     'Lista'
              }}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay"
            }}
            
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, momentTimezonePlugin]}
            ref={calendarComponentRef}
            weekends={calendarWeekends}
            events={calendarEvents}
            dateClick={handleDateClick}
            eventDurationEditable={false}
            eventStartEditable={false}
            eventClick={info =>{
                setIdOrdine(info.event._def.publicId)
                setSidebarPosition(user.role, 'ordineconsegna')
                setOrdineOpen(true)
            }}
            

          />
          
        </div>
      </div>
          
        </div>
    </div>
                    }
                </div>
            </div>
        </div>
    )
}

Consegne.protoTypes = {
    getOrdiniDaConsegnare: PropTypes.func.isRequired,
    filtraOrdiniDaConsegnare: PropTypes.func.isRequired,
    setDataConsegnaOrdineOnDrag: PropTypes.func.isRequired,
    setSidebarPosition: PropTypes.func.isRequired,
    cleanOrdini: PropTypes.func.isRequired,
    getMontatori: PropTypes.func.isRequired,
    sidebar: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    ordine: PropTypes.object.isRequired,
    montatore: PropTypes.object.isRequired

};

const mapStateToProps = state => ({
    sidebar: state.sidebar,
    auth: state.auth,
    ordine: state.ordine,
    montatore: state.montatore
});

export default connect(mapStateToProps, { getOrdiniDaConsegnare, filtraOrdiniDaConsegnare, setDataConsegnaOrdineOnDrag, setSidebarPosition, cleanOrdini, getMontatori })(Consegne);

