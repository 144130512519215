import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import { createNewVenditore } from '../../../actions/venditore';
import { setSidebarPosition } from '../../../actions/sidebar';
const NuovoVenditore = ({ auth: { user, loading }, createNewVenditore, setSidebarPosition }) => {

    const clienteInitialState = {
        username: '',
        nome: '',
        cognome: '',
        email: '',
        password: '',
        puntoVendita: ''
       }
    const [datiCliente, setDatiCliente] = useState(clienteInitialState);
    const { username, nome, cognome, email, password, puntoVendita } = datiCliente;


    const onChangeSetDatiCliente = e => setDatiCliente({ ...datiCliente, [e.target.name]: e.target.value });

    const onSubmitCreatePreventivo = async e => {
        e.preventDefault();
        createNewVenditore({ username, nome, cognome, email, password, puntoVendita });
     };

    return ( loading || !user ? <Spinner /> :
        <div className="content  d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="subheader py-3 py-lg-8  subheader-transparent " id="kt_subheader">
                <div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                            <div className="d-flex align-items-baseline flex-wrap mr-5">
                                <h2 className="subheader-title text-dark font-weight-bold my-1 mr-3">Staff</h2>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <a onClick={() => setSidebarPosition(user.role, 'venditori')} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-users icon-lg"></i>
                            </span> Venditori
			            </a>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-custom example example-compact">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        Nuovo Venditore
				                    </h3>
                                </div>
                                <form className="form" onSubmit= {e => onSubmitCreatePreventivo(e)} >
                                    <div className="card-body">
                                        <h3 className="font-size-lg text-dark font-weight-bold mb-6">Dati cliente:</h3>
                                        <div className="mb-15">
                                        <div class="form-group row">
                                            <div class="col-lg-4">
						                        <label for="exampleSelect1">Punto vendita: <span class="text-danger">*</span></label>
						                        <select class="form-control" name="puntoVendita" onChange={e => onChangeSetDatiCliente(e)} required >
                                                    <option selected disabled>Scegli punto vendita...</option>
							                        <option value={'arca_arredamenti'}>Arca Arredamenti</option>
							                        <option value={'lube_creo_store_pavia'}>Lube Creo Store Pavia</option>
							                        <option value={'lube_store_stradella'}>Lube Creo Store Stradella</option>
							                        <option value={'veneta_cucine_pavia'}>Veneta Cucine</option>
						                        </select>
                                            </div>
                                            <div class="col-lg-4">
							                        <label>Username: <span class="text-danger">*</span></label>
							                        <input type="text" class="form-control" name="username" value={username} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci username" required/>
						                    </div>
                                            <div class="col-lg-4">
						                            <label>Password: <span class="text-danger">*</span></label>
							                        <input type="password" class="form-control" name="password" value={password} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci password" required/>
						                    </div>
					                    </div>
                                            <div class="form-group row">
						                        <div class="col-lg-4">
							                        <label>Nome: <span class="text-danger">*</span></label>
							                        <input type="text" class="form-control" name="nome" value={nome} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci nome" required/>
						                        </div>
                                                <div class="col-lg-4">
						                            <label>Cognome: <span class="text-danger">*</span></label>
						                            <input type="text" class="form-control" name="cognome" value={cognome} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci cognome" required/>
					                            </div>
                                                <div class="col-lg-4">
							                        <label>Email: <span class="text-danger">*</span></label>
							                        <input type="email" class="form-control" name="email" value={email} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci username" required/>
						                        </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-lg-12 text-right">
                                                <button type="submit" className="btn btn-success mr-2 salva-btn">Salva venditore</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

NuovoVenditore.protoTypes = {
    createNewVenditore: PropTypes.func.isRequired,
    setSidebarPosition: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { createNewVenditore, setSidebarPosition })(NuovoVenditore);

