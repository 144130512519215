import axios from 'axios';
import { GET_PREVENTIVI, GET_PREVENTIVO, CREATE_PREVENTIVO, PREVENTIVO_CONFERMATO, MODIFICA_PREVENTIVO, MODIFICA_PREVENTIVO_CONFERMATO, CONFERMA_PREVENTIVO_ANNULLATA, FILTRA_PREVENTIVI, UPLOAD_ALLEGATO, UPLOAD_ALLEGATI_MODIFICA_PREVENTIVO, ELIMINA_ALLEGATO, CLEAN_PREVENTIVI, ELIMINA_PREVENTIVO, CLEAN_PREVENTIVO, CLEAN_ALLEGATI, PREVENTIVO_ERROR, UPLOAD_ERROR } from './types';
import { setAlert } from './alert';
import { setSidebarPosition } from '../actions/sidebar'



export const getPreventivi = (page, pagination, dataInizio, dataFine) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        page: page,
        pagination: pagination, 
        dataInizio: dataInizio,
        dataFine: dataFine
        });
    try{
        const res = await axios.post('/api/preventivo/preventivi', body, config);
        dispatch({
            type: GET_PREVENTIVI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: PREVENTIVO_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getPreventivo = (idPreventivo) => async dispatch => {
    try{
        const res = await axios.get(`/api/preventivo/preventivi/preventivo/${idPreventivo}`);
        dispatch({
            type: GET_PREVENTIVO,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: PREVENTIVO_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const confermaPreventivo = (idPreventivo) => async dispatch => {
    try{
        const res = await axios.get(`/api/preventivo/conferma/${idPreventivo}`);
        dispatch({
            type: PREVENTIVO_CONFERMATO,
            payload: res.data
        });
        dispatch(setSidebarPosition('admin', 'aggiornapreventivo'));
        dispatch(setAlert('Preventivo confermato con successo!', 'success'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: PREVENTIVO_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const annullaConfermaPreventivo = (idPreventivo) => async dispatch => {
    try{
        const res = await axios.get(`/api/preventivo/annulla/${idPreventivo}`);
        dispatch({
            type: CONFERMA_PREVENTIVO_ANNULLATA,
            payload: res.data
        });
        dispatch(setSidebarPosition('admin', 'ordini'));
        dispatch(setAlert('Ordine annullato con successo!', 'success'));
    }catch(err){
        dispatch({
            type: PREVENTIVO_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const eliminaPreventivo = (idPreventivo) => async dispatch => {
    try{
        const res = await axios.get(`/api/preventivo/preventivi/eliminaPreventivo/${idPreventivo}`);
        dispatch({
            type: ELIMINA_PREVENTIVO
        });
    }catch(err){
        dispatch({
            type: PREVENTIVO_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const createNewPreventivoVecchioCliente = (datiPreventivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        fattura: datiPreventivo.fattura,
        misure: datiPreventivo.misure, 
        totale: datiPreventivo.totale,
        acconto: datiPreventivo.acconto,
        accontiPersonalizzati: datiPreventivo.accontiPersonalizzati,
        saldo: datiPreventivo.saldo,
        descrizioneGenerale: datiPreventivo.descrizioneGenerale,
        note: datiPreventivo.note,
        tipologia: datiPreventivo.tipologia,
        metodoPagamento: datiPreventivo.metodoPagamento,
        notePagamento: datiPreventivo.notePagamento,
        notePreventivo: datiPreventivo.notePreventivo,
        costoProgetto: !datiPreventivo.costoProgetto ? 0 : datiPreventivo.costoProgetto,
        costoMisure: !datiPreventivo.costoMisure ? 0 : datiPreventivo.costoMisure,
        costoMagazzino: !datiPreventivo.costoMagazzino ? 0 : datiPreventivo.costoMagazzino,
        costoSmontaggio: !datiPreventivo.costoSmontaggio ? 0 : datiPreventivo.costoSmontaggio,
        costoElettricista: !datiPreventivo.costoElettricista ? 0 : datiPreventivo.costoElettricista,
        costoIdraulico: !datiPreventivo.costoIdraulico ? 0 : datiPreventivo.costoIdraulico,
        costoElevatore: !datiPreventivo.costoElevatore ? 0 : datiPreventivo.costoElevatore,
        elementi: datiPreventivo.elementi,
        allegati: datiPreventivo.allegati,
        idCliente: datiPreventivo.cliente._id,
        dataPrevista: datiPreventivo.dataPrevista,
        indirizzoPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.indirizzo : datiPreventivo.indirizzoPreventivo,
        capPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.cap : datiPreventivo.capPreventivo,
        cittaPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.citta : datiPreventivo.cittaPreventivo,
        pianoPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.piano : datiPreventivo.pianoPreventivo,
        scalaPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.scala : datiPreventivo.scalaPreventivo,
        ascensorePreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.ascensore : datiPreventivo.ascensorePreventivo,
        ztlPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.ztl : datiPreventivo.ztlPreventivo
        });
    try{
        const res = await axios.post('/api/preventivo/nuovoPreventivoVecchioCliente', body, config);
        dispatch({
            type: CREATE_PREVENTIVO,
            payload: res.data
        });
        dispatch(setAlert('Nuovo Preventivo creato!', 'success'));
        dispatch(setSidebarPosition('admin', 'preventivi'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: PREVENTIVO_ERROR
        });
    }
};

export const createNewPreventivoNuovoCliente = (datiPreventivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        fattura: datiPreventivo.fattura,
        misure: datiPreventivo.misure, 
        totale: datiPreventivo.totale,
        acconto: datiPreventivo.acconto,
        accontiPersonalizzati: datiPreventivo.accontiPersonalizzati,
        saldo: datiPreventivo.saldo,
        elementi: datiPreventivo.elementi,
        descrizioneGenerale: datiPreventivo.descrizioneGenerale,
        note: datiPreventivo.note,
        tipologia: datiPreventivo.tipologia,
        metodoPagamento: datiPreventivo.metodoPagamento,
        notePagamento: datiPreventivo.notePagamento,
        notePreventivo: datiPreventivo.notePreventivo,
        costoProgetto: !datiPreventivo.costoProgetto ? 0 : datiPreventivo.costoProgetto,
        costoMisure: !datiPreventivo.costoMisure ? 0 : datiPreventivo.costoMisure,
        costoMagazzino: !datiPreventivo.costoMagazzino ? 0 : datiPreventivo.costoMagazzino,
        costoSmontaggio: !datiPreventivo.costoSmontaggio ? 0 : datiPreventivo.costoSmontaggio,
        costoElettricista: !datiPreventivo.costoElettricista ? 0 : datiPreventivo.costoElettricista,
        costoIdraulico: !datiPreventivo.costoIdraulico ? 0 : datiPreventivo.costoIdraulico,
        costoElevatore: !datiPreventivo.costoElevatore ? 0 : datiPreventivo.costoElevatore,
        pIvaCodiceFiscale: datiPreventivo.cliente.pIvaCodiceFiscale,
        nome: datiPreventivo.cliente.nome,
        cognome: datiPreventivo.cliente.cognome,
        telefono_1: datiPreventivo.cliente.telefono_1,
        telefono_2: datiPreventivo.cliente.telefono_2,
        telefono_3: datiPreventivo.cliente.telefono_3,
        telefono_4: datiPreventivo.cliente.telefono_4,
        email_1: datiPreventivo.cliente.email_1,
        email_2: datiPreventivo.cliente.email_2,
        indirizzo: datiPreventivo.cliente.indirizzo,
        cap: datiPreventivo.cliente.cap,
        citta: datiPreventivo.cliente.citta,
        piano: datiPreventivo.cliente.piano,
        scala: datiPreventivo.cliente.scala,
        ascensore: datiPreventivo.cliente.ascensore,
        ztl: datiPreventivo.cliente.ztl,
        dataPrevista: datiPreventivo.dataPrevista,
        indirizzoPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.indirizzo : datiPreventivo.indirizzoPreventivo,
        capPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.cap : datiPreventivo.capPreventivo,
        cittaPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.citta : datiPreventivo.cittaPreventivo,
        pianoPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.piano : datiPreventivo.pianoPreventivo,
        scalaPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.scala : datiPreventivo.scalaPreventivo,
        ascensorePreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.ascensore : datiPreventivo.ascensorePreventivo,
        ztlPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.ztl : datiPreventivo.ztlPreventivo,
        allegati: datiPreventivo.allegati
    });
    try{
        const res = await axios.post('/api/preventivo/nuovoPreventivoNuovoCliente', body, config);
        dispatch({
            type: CREATE_PREVENTIVO,
            payload: res.data
        });
        dispatch(setAlert('Nuovo Preventivo creato!', 'success'));
        dispatch(setSidebarPosition('admin', 'preventivi'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: PREVENTIVO_ERROR
        });
    }
};

export const uploadNuovoAllegato = ({ file, fileName, nomeOriginale, fileType, tipologia }) => async dispatch => {
    axios.post("/api/signS3/nuovoAllegato",{
      fileName : fileName,
      fileType : fileType
    })
    .then(response => {
      var returnData = response.data.data.returnData;
      var signedRequest = returnData.signedRequest;
      var options = {
        headers: {
          'Content-Type': fileType
        }
      };
      axios.put(signedRequest,file,options)
      .then(res => {
          console.log(res)
        dispatch({
            type: UPLOAD_ALLEGATO,
            payload: { "url" : response.data.data.urlImg, "nomeOriginale": nomeOriginale, "type": fileType, "tipologia": tipologia }
        });
        dispatch(setAlert('Allegato aggiunto con successo!', 'success'));
      })
      .catch(error => {
        dispatch({
            type: UPLOAD_ERROR,
            payload: { msg: error.response.statusText, status: error.response.status}
        });
      })
    })
    .catch(error => {
        dispatch({
            type: UPLOAD_ERROR,
            payload: { msg: error.response.statusText, status: error.response.status}
        });
    })
};

export const eliminaAllegato = (allegato) => async dispatch => {
    dispatch({
        type: ELIMINA_ALLEGATO,
        payload: allegato
    });
};

export const filtraPreventivi = (parola, puntoVendita, page, pagination, dataInizio, dataFine, venditoreSelezionato) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        parola: parola,
        puntoVendita: puntoVendita,
        page: page,
        pagination: pagination,
        dataInizio: dataInizio,
        dataFine: dataFine,
        venditoreSelezionato: venditoreSelezionato
    });
    try{
        const res = await axios.post('/api/preventivo/filtra', body, config);
            dispatch({
                type: FILTRA_PREVENTIVI,
                payload: res.data
            });
        
    }catch(err){
        dispatch({
            type: PREVENTIVO_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const filtraPreventiviPerNominativo = (parola, page, pagination, dataInizio, dataFine) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        parola: parola,
        page: page,
        pagination: pagination,
        dataInizio: dataInizio, 
        dataFine: dataFine
    });
    try{
        const res = await axios.post('/api/preventivo/filtraPreventivoPerNominativo', body, config);
            dispatch({
                type: FILTRA_PREVENTIVI,
                payload: res.data
            });
        
    }catch(err){
        dispatch({
            type: PREVENTIVO_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const modificaPreventivo = (datiPreventivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        _id: datiPreventivo._id,
        fattura: datiPreventivo.fattura, 
        misure: datiPreventivo.misure, 
        totale: datiPreventivo.totale,
        acconto: datiPreventivo.acconto,
        accontiPersonalizzati: datiPreventivo.accontiPersonalizzati,
        saldo: datiPreventivo.saldo,
        elementi: datiPreventivo.elementi,
        descrizioneGenerale: datiPreventivo.descrizioneGenerale,
        note: datiPreventivo.note,
        notePagamento: datiPreventivo.notePagamento,
        notePreventivo: datiPreventivo.notePreventivo,
        tipologia: datiPreventivo.tipologia,
        costoProgetto: !datiPreventivo.costoProgetto ? 0 : datiPreventivo.costoProgetto,
        costoMisure: !datiPreventivo.costoMisure ? 0 : datiPreventivo.costoMisure,
        costoMagazzino: !datiPreventivo.costoMagazzino ? 0 : datiPreventivo.costoMagazzino,
        costoSmontaggio: !datiPreventivo.costoSmontaggio ? 0 : datiPreventivo.costoSmontaggio,
        costoElettricista: !datiPreventivo.costoElettricista ? 0 : datiPreventivo.costoElettricista,
        costoIdraulico: !datiPreventivo.costoIdraulico ? 0 : datiPreventivo.costoIdraulico,
        costoElevatore: !datiPreventivo.costoElevatore ? 0 : datiPreventivo.costoElevatore,
        metodoPagamento: datiPreventivo.metodoPagamento,
        pIvaCodiceFiscale: datiPreventivo.cliente.pIvaCodiceFiscale,
        nome: datiPreventivo.cliente.nome,
        cognome: datiPreventivo.cliente.cognome,
        telefono_1: datiPreventivo.cliente.telefono_1,
        telefono_2: datiPreventivo.cliente.telefono_2,
        telefono_3: datiPreventivo.cliente.telefono_3,
        telefono_4: datiPreventivo.cliente.telefono_4,
        email_1: datiPreventivo.cliente.email_1,
        email_2: datiPreventivo.cliente.email_2,
        indirizzo: datiPreventivo.cliente.indirizzo,
        cap: datiPreventivo.cliente.cap,
        citta: datiPreventivo.cliente.citta,
        piano: datiPreventivo.cliente.piano,
        scala: datiPreventivo.cliente.scala,
        ascensore: datiPreventivo.cliente.ascensore,
        ztl: datiPreventivo.cliente.ztl,
        dataPrevista: datiPreventivo.dataPrevista,
        indirizzoPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.indirizzo : datiPreventivo.indirizzoPreventivo,
        capPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.cap : datiPreventivo.capPreventivo,
        cittaPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.citta : datiPreventivo.cittaPreventivo,
        pianoPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.piano : datiPreventivo.pianoPreventivo,
        scalaPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.scala : datiPreventivo.scalaPreventivo,
        ascensorePreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.ascensore : datiPreventivo.ascensorePreventivo,
        ztlPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.ztl : datiPreventivo.ztlPreventivo,
        allegati: datiPreventivo.allegati
    });
    try{
        const res = await axios.post('/api/preventivo/preventivi/preventivo/modifica', body, config);
        dispatch({
            type: MODIFICA_PREVENTIVO,
            payload: res.data
        });
        dispatch(setAlert('Preventivo modificato con successo!', 'success'));
        dispatch(setSidebarPosition('admin', 'aggiornapreventivo'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: PREVENTIVO_ERROR
        });
    }
};

export const modificaPreventivoConfermato = (idPreventivo, elementi) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idPreventivo: idPreventivo,
        elementi: elementi,
    });
    try{
        const res = await axios.post('/api/preventivo/preventivi/preventivo/modificaPreventivoConfermato', body, config);
        dispatch({
            type: MODIFICA_PREVENTIVO_CONFERMATO,
            payload: res.data
        });
        dispatch(setAlert('Ordine modificato con successo!', 'success'));
        dispatch(setSidebarPosition('admin', 'escimodificapreventivo'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: PREVENTIVO_ERROR
        });
    }
};

export const uploadAllegatiModificaPreventivo = (allegati) => async dispatch => {
    dispatch({
        type: UPLOAD_ALLEGATI_MODIFICA_PREVENTIVO,
        payload: allegati
    });
};

export const cleanPreventivi = () => async dispatch => {
    dispatch({
        type: CLEAN_PREVENTIVI
    });
};

export const cleanPreventivo = () => async dispatch => {
    dispatch({
        type: CLEAN_PREVENTIVO
    });
};

export const cleanAllegati = () => async dispatch => {
    dispatch({
        type: CLEAN_ALLEGATI
    });
};