import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Ordine from '../../ordini/Ordine';
import PreventivoDaConfermare from './PreventivoDaConfermare';
import ModificaPreventivoDaConfermare from './ModificaPreventivoDaConfermare';
import { getPreventivo, cleanPreventivo } from '../../../../actions/preventivo';
import { setSidebarPosition } from '../../../../actions/sidebar';
import Spinner from '../../../layout/Spinner';
const Preventivo = ({ preventivo: { preventivo, loading }, sidebar: { position }, setSidebarPosition, getPreventivo, cleanPreventivo, idPreventivo, onClickChiudiPreventivo, onClickEliminaPreventivo }) => {

    useEffect(() => {
        getPreventivo(idPreventivo);
        return () => {
            cleanPreventivo();
        }
    }, [getPreventivo, cleanPreventivo]);

    useEffect(() => {
        if(position === 'aggiornapreventivo'){
            getPreventivo(idPreventivo);
            setSidebarPosition('admin', 'preventivo');
            setModificaOpen(false)
        }
    }, [getPreventivo, setSidebarPosition, position]);

    const [modificaOpen, setModificaOpen] = useState(false);
    
    return ( loading || !preventivo ? <Spinner /> :
        modificaOpen ?
            <ModificaPreventivoDaConfermare
                preventivo = { preventivo }
                setModificaOpen = { setModificaOpen }
            />
        :
        preventivo.stato === 'creato' ? 
        <PreventivoDaConfermare
            preventivo={preventivo}
            onClickChiudiPreventivo={onClickChiudiPreventivo}
            onClickEliminaPreventivo={onClickEliminaPreventivo}
            setModificaOpen = { setModificaOpen }
        />
        : 
        preventivo.stato === 'confermato' ? 
        <Ordine
            idOrdine={idPreventivo}
            onClickChiudiOrdine={onClickChiudiPreventivo}
        /> 
        : null
    )
}

Preventivo.protoTypes = {
    getPreventivo: PropTypes.func.isRequired,
    setSidebarPosition: PropTypes.func.isRequired,
    cleanPreventivo: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    preventivo: PropTypes.object.isRequired,
    sidebar: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    preventivo: state.preventivo,
    auth: state.auth,
    sidebar: state.sidebar
});

export default connect(mapStateToProps, { getPreventivo, cleanPreventivo, setSidebarPosition })(Preventivo);

