import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../../layout/Spinner';
import VecchioCLiente from './vecchioCliente/VecchioCliente';
import NuovoCliente from './nuovoCliente/NuovoCliente';
import { getClienti } from '../../../../actions/cliente';
import { cleanAllegati } from '../../../../actions/preventivo';

const NuovoPreventivo = ({ auth: { user, loading }, cliente: { clienti }, getClienti, cleanAllegati }) => {
    useEffect(() => {
        getClienti();
    }, [getClienti]);

    const [modalitaCercaCliente, setModalitaCercaCliente] = useState(false);

    const toggleModalitaCercaCliente = () => {
        cleanAllegati();
        setModalitaCercaCliente(!modalitaCercaCliente);
    }

    return ( loading || !user ? <Spinner /> :
        <div className="content  d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="subheader py-3 py-lg-8  subheader-transparent " id="kt_subheader">
                <div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        <div className="d-flex align-items-baseline flex-wrap mr-5">
                            <h2 className="subheader-title text-dark font-weight-bold my-1 mr-3">
                                Preventivi
	                	            </h2>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <a onClick = {() => { toggleModalitaCercaCliente() }} className={`btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2 ${modalitaCercaCliente? 'active':null}`}>
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                            {modalitaCercaCliente ? <i class="fas fa-user-plus"></i> : <i class="fa fa-search"></i> }
                            </span> {modalitaCercaCliente ? "Nuovo Cliente" : "Cerca Cliente"}
			            </a>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-custom example example-compact">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        Nuovo Preventivo
				                    </h3>
                                </div>
                                {!modalitaCercaCliente ? 
                                    <NuovoCliente
                                    />
                                :
                                    <VecchioCLiente
                                        clienti={clienti}
                                    /> 
                                }   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
NuovoPreventivo.protoTypes = {
    getClienti: PropTypes.func.isRequired,
    cleanAllegati: PropTypes.func.isRequired,
    cliente: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    cliente : state.cliente,
    auth: state.auth
});

export default connect(mapStateToProps, { getClienti, cleanAllegati })(NuovoPreventivo);