import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import { getCliente, modificaCliente } from '../../../actions/cliente';
const ModificaCliente = ({ cliente: { cliente, loading }, getCliente, modificaCliente, idCliente, setModificaCliente}) => {

    useEffect(() => {
        getCliente(idCliente);
    }, [getCliente]);

    const clienteInitialState = {
        pIvaCodiceFiscale: '',
        nome: '',
        cognome: '',
        telefono_1: '',
        telefono_2: '',
        telefono_3: '',
        telefono_4: '',
        email_1: '',
        email_2: '',
        indirizzo: '',
        cap: '',
        citta: '',
        piano: '',
        scala: '',
        ascensore: false,
        ztl: false
       }
    const [datiCliente, setDatiCliente] = useState(clienteInitialState);

    useEffect(() => {
        setDatiCliente({
            pIvaCodiceFiscale: loading || !cliente ? '' : cliente.pIvaCodiceFiscale,
            nome: loading || !cliente ? '' : cliente.nome,
            cognome: loading || !cliente ? '' : cliente.cognome,
            telefono_1: loading || !cliente ? '' : cliente.telefono_1,
            telefono_2: loading || !cliente ? '' : cliente.telefono_2,
            telefono_3: loading || !cliente ? '' : cliente.telefono_3,
            telefono_4: loading || !cliente ? '' : cliente.telefono_4,
            email_1: loading || !cliente ? '' : cliente.email_1,
            email_2: loading || !cliente ? '' : cliente.email_2,
            indirizzo: loading || !cliente ? '' : cliente.indirizzo,
            cap: loading || !cliente ? '' : cliente.cap,
            citta: loading || !cliente ? '' : cliente.citta,
            piano: loading || !cliente ? '' : cliente.piano,
            scala: loading || !cliente ? '' : cliente.scala,
            ascensore: loading || !cliente ? false : cliente.ascensore,
            ztl: loading || !cliente ? false : cliente.ztl,
        });
    }, [cliente, loading]);
    const { pIvaCodiceFiscale, nome, cognome, telefono_1, telefono_2, telefono_3, telefono_4, email_1, email_2, indirizzo, cap, citta, piano, scala, ascensore, ztl } = datiCliente;


    const onChangeSetDatiCliente = e => setDatiCliente({ ...datiCliente, [e.target.name]: e.target.value });
    const onChangeSetAscensoreCliente = e => setDatiCliente({ ...datiCliente, ascensore: e.target.checked });
    const onChangeSetZTLCliente = e => setDatiCliente({ ...datiCliente, ztl: e.target.checked });

    const onSubmitCreatePreventivo = async e => {
        e.preventDefault();
        modificaCliente(idCliente, pIvaCodiceFiscale, nome, cognome, telefono_1, telefono_2, telefono_3, telefono_4, email_1, email_2, indirizzo, cap, citta, piano, scala, ascensore, ztl );
     };

    return ( loading || !cliente ? <Spinner /> :
                <div className=" container ">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-custom example example-compact">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        Nuovo Cliente
				                    </h3>
                                </div>
                                <form className="form" onSubmit= {e => onSubmitCreatePreventivo(e)} >
                                    <div className="card-body">
                                        <h3 className="font-size-lg text-dark font-weight-bold mb-6">Dati cliente:</h3>
                                        <div className="mb-15">
                                            <div class="form-group row">
						                        <div class="col-lg-6">
							                        <label>P.Iva/C.F.:</label>
							                        <input type="text" class="form-control" name="pIvaCodiceFiscale" value={pIvaCodiceFiscale} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci P.Iva/C.F."/>
						                        </div>
					                        </div>
                                            <div class="form-group row">
                                            <div class="col-lg-3">
						                            <label>Cognome: <span class="text-danger">*</span></label>
						                            <input type="text" class="form-control" name="cognome" value={cognome} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci cognome"/>
					                            </div>
						                        <div class="col-lg-3">
							                        <label>Nome: <span class="text-danger">*</span></label>
							                        <input type="text" class="form-control" name="nome" value={nome} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci nome"/>
						                        </div>
                                                <div class="col-lg-3">
						                            <label>Email 1:</label>
							                        <input type="email" class="form-control" name="email_1" value={email_1} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci email"/>
						                        </div>
                                                <div class="col-lg-3">
							                        <label>Email 2:</label>
							                        <input type="email" class="form-control" name="email_2" value={email_2} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci email"/>
						                        </div>
					                        </div>
                                            <div class="form-group row">
                                                <div class="col-lg-3">
						                            <label>Telefono 1: <span class="text-danger">*</span></label>
							                        <input type="text" class="form-control" name="telefono_1" value={telefono_1} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci telefono"/>
						                        </div>
						                        <div class="col-lg-3">
							                        <label>Telefono 2:</label>
							                        <input type="text" class="form-control" name="telefono_2" value={telefono_2} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci telefono"/>
						                        </div>
                                                <div class="col-lg-3">
						                            <label>Telefono 3:</label>
						                            <input type="text" class="form-control" name="telefono_3" value={telefono_3} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci telefono"/>
					                            </div>
                                                <div class="col-lg-3">
						                            <label>Telefono 4:</label>
							                        <input type="text" class="form-control" name="telefono_4" value={telefono_4} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci telefono"/>
						                        </div>
					                        </div>
                                            <div class="form-group row">
						                        <div class="col-lg-6">
						                            <label>Indirizzo:</label>
			    		                            <input type="text" class="form-control" name="indirizzo" value={indirizzo} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci indirizzo"/>
						                        </div>
                                                <div class="col-lg-3">
							                        <label>CAP:</label>
							                        <input type="text" class="form-control" name="cap" value={cap} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci cap"/>
						                        </div>
                                                <div class="col-lg-3">
						                            <label>Città:</label>
						                            <input type="text" class="form-control" name="citta" value={citta} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci città"/>
					                            </div>
				                            </div>
                                            <div class="form-group row">
					                            <div class="col-lg-3">
						                            <label>Piano:</label>
							                        <input type="text" class="form-control" name="piano" value={piano} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci piano"/>
						                        </div>
                                                <div class="col-lg-3">
							                        <label>Scala:</label>
							                        <input type="text" class="form-control" name="scala" value={scala} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci scala"/>
						                        </div>
                                                <div class="col-lg-6">
                                                    <label>Accessibilità:</label>
                                                    <div class="checkbox-inline">
                                                        <label class="checkbox">
                                                        <input type="checkbox" checked={ ascensore } onChange={e => onChangeSetAscensoreCliente(e)} />
                                                            <span></span>
                                                            Ascensore
                                                            
                                                        </label>
                                                        <label class="checkbox">
                                                        <input type="checkbox" checked={ ztl } onChange={e => onChangeSetZTLCliente(e)} />
                                                            <span></span>
                                                            ZTL
                                                            
                                                        </label>
                                                    </div>
						                        </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                        <div className="col-lg-6 text-left">
                                                <button onClick={() => setModificaCliente(false)} className="btn btn-secondary mr-2">Annulla</button>
                                            </div>
                                            <div className="col-lg-6 text-right">
                                                <button type="submit" className="btn btn-success mr-2">Modifica cliente</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
    )
}

ModificaCliente.protoTypes = {
    getCliente: PropTypes.func.isRequired,
    modificaCliente: PropTypes.func.isRequired,
    cliente: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    cliente: state.cliente
});

export default connect(mapStateToProps, { getCliente, modificaCliente })(ModificaCliente);

