import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import { createNewCliente } from '../../../actions/cliente';
import { setSidebarPosition } from '../../../actions/sidebar';
const NuovoCliente = ({ auth: { user, loading }, createNewCliente, setSidebarPosition }) => {

    const clienteInitialState = {
        pIvaCodiceFiscale: '',
        nome: '',
        cognome: '',
        telefono_1: '',
        telefono_2: '',
        telefono_3: '',
        telefono_4: '',
        email_1: '',
        email_2: '',
        indirizzo: '',
        cap: '',
        citta: '',
        piano: '',
        scala: '',
        ascensore: false,
        ztl: false
       }
    const [datiCliente, setDatiCliente] = useState(clienteInitialState);
    const { pIvaCodiceFiscale, nome, cognome, telefono_1, telefono_2, telefono_3, telefono_4, email_1, email_2, indirizzo, cap, citta, piano, scala, ascensore, ztl } = datiCliente;


    const onChangeSetDatiCliente = e => setDatiCliente({ ...datiCliente, [e.target.name]: e.target.value });
    const onChangeSetAscensoreCliente = e => setDatiCliente({ ...datiCliente, ascensore: e.target.checked });
    const onChangeSetZTLCliente = e => setDatiCliente({ ...datiCliente, ztl: e.target.checked });

    const onSubmitCreatePreventivo = async e => {
        e.preventDefault();
        createNewCliente({ pIvaCodiceFiscale, nome, cognome, telefono_1, telefono_2, telefono_3, telefono_4, email_1, email_2, indirizzo, cap, citta, piano, scala, ascensore, ztl });
     };

    return ( loading || !user ? <Spinner /> :
        <div className="content  d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="subheader py-3 py-lg-8  subheader-transparent " id="kt_subheader">
                <div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                            <div className="d-flex align-items-baseline flex-wrap mr-5">
                                <h2 className="subheader-title text-dark font-weight-bold my-1 mr-3">Clienti</h2>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <a onClick={() => setSidebarPosition(user.role, 'nuovopreventivo')} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-file-medical icon-lg"></i>
                            </span> Nuovo Preventivo
			            </a>
                        <a onClick={() => setSidebarPosition(user.role, 'clienti')} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-users icon-lg"></i>
                            </span> Clienti
			            </a>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-custom example example-compact">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        Nuovo Cliente
				                    </h3>
                                </div>
                                <form className="form" onSubmit= {e => onSubmitCreatePreventivo(e)} >
                                    <div className="card-body">
                                        <h3 className="font-size-lg text-dark font-weight-bold mb-6">Dati cliente:</h3>
                                        <div className="mb-15">
                                            <div class="form-group row">
						                        <div class="col-lg-6">
							                        <label>P.Iva/C.F.:</label>
							                        <input type="text" class="form-control" name="pIvaCodiceFiscale" value={pIvaCodiceFiscale} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci P.Iva/C.F."/>
						                        </div>
					                        </div>
                                            <div class="form-group row">
                                                <div class="col-lg-3">
						                            <label>Cognome: <span class="text-danger">*</span></label>
						                            <input type="text" class="form-control" name="cognome" value={cognome} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci cognome" required/>
					                            </div>
                                                <div class="col-lg-3">
							                        <label>Nome: <span class="text-danger">*</span></label>
							                        <input type="text" class="form-control" name="nome" value={nome} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci nome" required/>
						                        </div>
                                                <div class="col-lg-3">
							                        <label>Email 1:</label>
							                        <input type="email" class="form-control" name="email_1" value={email_1} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci email"/>
						                        </div>
                                                <div class="col-lg-3">
							                        <label>Email 2:</label>
							                        <input type="email" class="form-control" name="email_2" value={email_2} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci email"/>
						                        </div>
					                        </div>
                                            <div class="form-group row">
                                                <div class="col-lg-3">
						                            <label>Telefono 1: <span class="text-danger">*</span></label>
							                        <input type="text" class="form-control" name="telefono_1" value={telefono_1} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci telefono" required/>
						                        </div>
                                                <div class="col-lg-3">
						                            <label>Telefono 2:</label>
						                            <input type="text" class="form-control" name="telefono_2" value={telefono_2} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci telefono"/>
					                            </div>
                                                <div class="col-lg-3">
							                        <label>Telefono 3:</label>
							                        <input type="text" class="form-control" name="telefono_3" value={telefono_3} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci telefono"/>
						                        </div>
                                                <div class="col-lg-3">
						                            <label>Telefono 4:</label>
							                        <input type="text" class="form-control" name="telefono_4" value={telefono_4} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci telefono"/>
						                        </div>
					                        </div>
                                            <div class="form-group row">
						                        <div class="col-lg-6">
						                            <label>Indirizzo:</label>
			    		                            <input type="text" class="form-control" name="indirizzo" value={indirizzo} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci indirizzo"/>
						                        </div>
                                                <div class="col-lg-3">
							                        <label>CAP:</label>
							                        <input type="text" class="form-control" name="cap" value={cap} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci cap"/>
						                        </div>
                                                <div class="col-lg-3">
						                            <label>Città:</label>
						                            <input type="text" class="form-control" name="citta" value={citta} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci città"/>
					                            </div>
				                            </div>
                                            <div class="form-group row">
					                            <div class="col-lg-3">
						                            <label>Piano:</label>
							                        <input type="text" class="form-control" name="piano" value={piano} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci piano"/>
						                        </div>
                                                <div class="col-lg-3">
							                        <label>Scala:</label>
							                        <input type="text" class="form-control" name="scala" value={scala} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci scala"/>
						                        </div>
                                                <div class="col-lg-6">
                                                    <label>Accessibilità:</label>
                                                    <div class="checkbox-inline">
                                                        <label class="checkbox">
                                                        <input type="checkbox" checked={ ascensore } onChange={e => onChangeSetAscensoreCliente(e)} />
                                                            <span></span>
                                                            Ascensore
                                                            
                                                        </label>
                                                        <label class="checkbox">
                                                        <input type="checkbox" checked={ ztl } onChange={e => onChangeSetZTLCliente(e)} />
                                                            <span></span>
                                                            ZTL
                                                            
                                                        </label>
                                                    </div>
						                        </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-lg-12 text-right">
                                                <button type="submit" className="btn btn-success mr-2">Aggiungi cliente</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

NuovoCliente.protoTypes = {
    createNewCliente: PropTypes.func.isRequired,
    setSidebarPosition: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { createNewCliente, setSidebarPosition })(NuovoCliente);

