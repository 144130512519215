import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment'
import Spinner from '../../layout/Spinner';
import ModificaCliente from './ModificaCliente';
import { pageButtonUI } from '../../../utility/Utility';
import { getClienti, filtraClienti } from '../../../actions/cliente';
import { setSidebarPosition } from '../../../actions/sidebar';
const Clienti = ({ auth, cliente: { clienti, numberClienti, loading }, sidebar, getClienti, filtraClienti, setSidebarPosition }) => {

    const [totalClienti, setTotalClienti] = useState(null);
    const [page, setPage] = useState(0);
    const [modificaCliente, setModificaCliente] = useState(false);
    const [idCliente, setIdCliente] = useState(null);
    const [parola, setParola] = useState("");
    const pagination = 10;

    useEffect(() => {
        getClienti(page, pagination);
    }, [getClienti]);

    useEffect(() => {
        if(sidebar.position === 'clienti'){
            getClienti(page, pagination);
            setModificaCliente(false)
        }
    }, [sidebar, getClienti]);

    useEffect(() => {
        setTotalClienti(numberClienti);
        if(numberClienti/pagination < page){
            filtraClienti(parola, 0, pagination)
            setPage(0)
        }
    }, [clienti]);

    const onClickGoToPage = (page) => {
        filtraClienti(parola, page, pagination)
        setPage(page)
        
    }

    const onClickNextPage = () => {
        if(page+1 !== Math.ceil(totalClienti/pagination)){
            filtraClienti(parola, page+1, pagination)
            setPage(page+1)
        }
    }

    const onClickBackPage = () => {
        if(page !== 0){
            filtraClienti(parola, page-1, pagination)
            setPage(page-1)
        }
    }

    
    const [openRow, setOpenRow] = useState(null);
    const onClickToggleRow = (id) => {
        if(id === openRow){
            setOpenRow(null);
        }else{
            setOpenRow(id);
        }
    }

   
const onClickGetCliente = (idCliente) => {
    setSidebarPosition(auth.user.role, 'cliente')
    setModificaCliente(true)
    setIdCliente(idCliente)
}

const onChangeSearchCliente = e => {
    setParola(e);
    filtraClienti(e, page, pagination)
}



    return ( loading ? <Spinner /> :
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="subheader py-3 py-lg-8  subheader-transparent " id="kt_subheader">
                <div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                            <div className="d-flex align-items-baseline flex-wrap mr-5">
                                <h2 className="subheader-title text-dark font-weight-bold my-1 mr-3">Clienti</h2>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <a onClick={() => setSidebarPosition(auth.user.role, 'nuovopreventivo')} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-file-medical icon-lg"></i>
                            </span> Nuovo Preventivo
			            </a>
                        <a onClick={() => setSidebarPosition(auth.user.role, 'nuovocliente')} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-user-plus icon-lg"></i>
                            </span> Nuovo Cliente
			            </a>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    {modificaCliente ? 
                        <ModificaCliente 
                        idCliente = { idCliente }
                        setModificaCliente= { setModificaCliente }
                        />
                    :
                    <div className="card card-custom">
                        <div className="card-header flex-wrap border-0 pt-6 pb-0">
                            <div className="card-title">
                                <h3 className="card-label">
                                    Lista Clienti
				<span className="d-block  pt-2 font-size-sm">Tutti i clienti</span>
                                </h3>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="mb-7">
                                <div className="row align-items-center">
                                    <div className="col-lg-9 col-xl-8">
                                        <div className="row align-items-center">
                                            <div className="col-md-4 my-2 my-md-0">
                                                <div className="input-icon">
                                                <input onChange={e => onChangeSearchCliente(e.target.value)} value={parola} type="text" className="form-control" placeholder="Cerca..." id="kt_datatable_search_query" />
                                                    <span><i className="flaticon2-search-1 "></i></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded" id="kt_datatable" >
                                    <table className="datatable-table" style={{display: "block"}}>
                                        <thead className="datatable-head">
                                            <tr className="datatable-row" style={{left: "0px"}}>
                                                <th className="datatable-cell datatable-toggle-detail">
                                                    <span></span>
                                                </th>
                                                <th data-field="OrderID" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "150px"}}>P.IVA/C.F.</span>
                                                </th>
                                                <th data-field="ShipAddress" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "90px"}}>Cognome</span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "90px"}}>Nome</span>
                                                </th>
                                                <th data-field="ShipDate" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "110px"}}>Telefono 1</span>
                                                </th>
                                                <th data-field="Status" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "130px"}}>Email 1</span>
                                                </th>
                                                <th data-field="Actions" data-autohide-disabled="false" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "80px"}}>Modifica</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="datatable-body" >
                                            {loading ? null : 
                                                clienti.map((cliente, i) => (
                                                    <Fragment>

                                            <tr data-row="0" className="datatable-row" style={{left: "0px"}}>
                                                <td className="datatable-cell datatable-toggle-detail">
                                                    <a className="datatable-toggle-detail" onClick ={() => onClickToggleRow(cliente._id)} >
                                                        <i className={cliente._id === openRow ? "fa fa-caret-down" : "fa fa-caret-right"}></i>
                                                    </a>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "150px"}}>{cliente.pIvaCodiceFiscale}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "90px"}}>{cliente.cognome}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "90px"}}>{cliente.nome}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "110px"}}>{cliente.telefono_1}</span>
                                                </td>
                                                <td data-field="Country" aria-label="Brazil" className="datatable-cell">
                                                    <span style={{width: "130px"}}>{cliente.email_1}</span>
                                                </td>
                                                <td data-field="Actions" data-autohide-disabled="false" aria-label="null" className="datatable-cell">
                                                    <span style={{overflow: "visible", position: "relative", width: "80px"}}>
                                                        <a onClick ={() => onClickGetCliente(cliente._id)} className="btn btn-sm btn-clean btn-icon mr-2" title="Edit details">
                                                            <span className="svg-icon svg-icon-md">
                                                                <i className="fas fa-edit icon-md"></i>
                                                            </span>
                                                        </a>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="datatable-row-detail" style={openRow!==cliente._id ? {display: "none"} : null}>
                                                <td className="datatable-detail" colspan="13">
                                                    <table>
                                                    {cliente.telefono_2 ?
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Telefono 2</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{cliente.telefono_2}</span>
                                                            </td>
                                                        </tr>
                                                    : null}
                                                    {cliente.telefono_3 ?
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Telefono 3</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{cliente.telefono_3}</span>
                                                            </td>
                                                        </tr>
                                                    : null }
                                                    {cliente.telefono_4 ?
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Telefono 4</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{cliente.telefono_4}</span>
                                                            </td>
                                                        </tr>
                                                    : null }
                                                    {cliente.email_2 ?
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Email 2</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{cliente.email_2}</span>
                                                            </td>
                                                        </tr>
                                                    : null }
                                                    {cliente.indirizzo ?
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Indirizzo</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{cliente.indirizzo}</span>
                                                            </td>
                                                        </tr>
                                                    : null }
                                                    {cliente.cap ?
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>CAP</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{cliente.cap}</span>
                                                            </td>
                                                        </tr>
                                                    : null } 
                                                    {cliente.citta ?   
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Città</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{cliente.citta}</span>
                                                            </td>
                                                        </tr>
                                                    : null }
                                                    {cliente.piano ?  
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Piano</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{cliente.piano}</span>
                                                            </td>
                                                        </tr>
                                                    : null }
                                                    {cliente.scala ?  
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Scala</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{cliente.scala}</span>
                                                            </td>
                                                        </tr>
                                                    : null }
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Ascensore</span>
                                                            </td>
                                                            <td data-field="Status" aria-label="3" className="datatable-cell">
                                                                <span style={{width: "110px"}}> 
                                                                    <span className={`label label-lg font-weight-bold label-light${cliente.ascensore ? "-primary label-inline" : "-danger label-inline" }`}>{cliente.ascensore ? "Si" : "No"}</span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>ZTL</span>
                                                            </td>
                                                            <td data-field="Status" aria-label="3" className="datatable-cell">
                                                                <span style={{width: "110px"}}>
                                                                    <span className={`label label-lg font-weight-bold label-light${cliente.ztl ? "-primary label-inline" : "-danger label-inline" }`}>{cliente.ztl ? "Si" : "No"}</span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Primo contatto</span>
                                                            </td>
                                                            <td data-field="Status" aria-label="3" className="datatable-cell">
                                                                <span style={{width: "110px"}}><Moment format='DD/MM/YYYY'>{cliente.date}</Moment></span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Registrato da</span>
                                                            </td>
                                                            <td data-field="Status" aria-label="3" className="datatable-cell">
                                                                <span style={{width: "110px"}}>{cliente.puntoVendita.nome}</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Ordini</span>
                                                            </td>
                                                            <td data-field="Status" aria-label="3" className="datatable-cell">
                                                                <span style={{width: "110px"}}>{cliente.ordini}</span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            </Fragment>
                                                   
                                                )
                                                )}
                                        </tbody>
                                    </table>
                                    <div className="datatable-pager datatable-paging-loaded">
                                        <ul className="datatable-pager-nav mb-5 mb-sm-0">
                                            <li>
                                                <a title="Previous" className={`datatable-pager-link datatable-pager-link-prev ${page === 0 ?"datatable-pager-link-disabled" : null}`} data-page="1" disabled={page === 0} onClick ={() => onClickBackPage()}>
                                                    <i className="flaticon2-back"></i>
                                                </a>
                                            </li>
                                            <li ></li>
                                            {pageButtonUI(totalClienti, pagination, page, onClickGoToPage)}
                                            <li>
                                                <a title="Next" className={`datatable-pager-link datatable-pager-link-first ${page+1 === Math.ceil(totalClienti/pagination) ?"datatable-pager-link-disabled" : null}`} data-page="1" disabled={page+1 === Math.ceil(totalClienti/pagination)} onClick ={() => onClickNextPage()}>
                                                    <i className="flaticon2-next"></i>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="datatable-pager-info">
                                            <span className="datatable-pager-detail">Risultati: {totalClienti}</span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                }
                </div>
            </div>
        </div>
    )
}

Clienti.protoTypes = {
    getClienti: PropTypes.func.isRequired,
    filtraClienti: PropTypes.func.isRequired,
    setSidebarPosition: PropTypes.func.isRequired,
    sidebar: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    clienti: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    sidebar: state.sidebar,
    auth: state.auth,
    cliente: state.cliente
});

export default connect(mapStateToProps, { getClienti, filtraClienti, setSidebarPosition })(Clienti);

