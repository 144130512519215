import { GET_CLIENTI, GET_CLIENTE, MODIFICA_CLIENTE, CREATE_CLIENTE_SUCCESS, FILTRA_CLIENTI, CLIENTE_ERROR } from "../actions/types";

const initialState = {
    cliente: null,
    clienti: [],
    numberClienti: 0,
    error: {},
    loading: true
}

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case GET_CLIENTI:
        case FILTRA_CLIENTI:
        case CREATE_CLIENTE_SUCCESS:
            return {
                ...state,
                clienti: payload.clienti,
                numberClienti: payload.numberClienti,
                loading: false
            };
        case GET_CLIENTE:
        case MODIFICA_CLIENTE:
            return {
                ...state,
                cliente: payload,
                loading: false
            };
        case CLIENTE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}