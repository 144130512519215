import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Sidebar from './Sidebar';
import Ordini from './ordini/Ordini';
import OrdiniMagazziniere from './ordini/OrdiniMagazziniere';
import OrdiniDebitori from './ordini/OrdiniDebitori';
import NuovoPreventivo from './preventivi/nuovoPreventivo/NuovoPreventivo';
import Preventivi from './preventivi/Preventivi';
import NuovoCliente from './clienti/NuovoCliente';
import Clienti from './clienti/Clienti';
import Venditori from './venditori/Venditori';
import NuovoVenditore from './venditori/NuovoVenditore';
import Montatori from './montatori/Montatori';
import NuovoMontatore from './montatori/NuovoMontatore';
import Magazzino from './magazzino/Magazzino';
import Consegne from './magazzino/Consegne';
import CalendarioConsegne from './magazzino/CalendarioConsegne';
import ConsegneMontatori from './magazzino/ConsegneMontatori';
import StatisticheGenerali from './statistiche/StatisticheGenerali';
import { setSidebarPosition } from '../../actions/sidebar';
import { switchSidebarLogo } from '../../utility/Utility';



const Dashboard = ({ auth: { user, loading }, sidebar }) => {
    const switchSidebarPosition = (position) => {
        if(user && user.role === 'montatore'){
            switch(position) {
            case 'consegnemontatori':
                return <ConsegneMontatori />
            default:
                return <ConsegneMontatori />
            }
        }else if(user && user.role === 'magazziniere'){
            switch(position) {
            case 'ordinimagazziniere':
                return <OrdiniMagazziniere />
            default:
                return <OrdiniMagazziniere />
            }
        }else{
        switch(position) {
            case 'ordini':
            case 'ordine':
                return <Ordini />
            case 'preventivi':
            case 'preventivo':
            case 'aggiornapreventivo':
                return <Preventivi/>;
            case 'nuovopreventivo':
                return <NuovoPreventivo/>;
            case 'clienti':
            case 'cliente':
                return <Clienti/>;
            case 'nuovocliente':
                return <NuovoCliente/>;
            case 'venditori':
                return <Venditori/>;
            case 'nuovovenditore':
                return <NuovoVenditore/>;
            case 'montatori':
                return <Montatori/>;
            case 'nuovomontatore':
                return <NuovoMontatore/>;
            case 'magazzino':
            case 'ordinemagazzino':
                return <Magazzino/>;
            case 'consegne':
            case 'ordineconsegna':
                return <Consegne/>;
            case 'calendarioconsegne':
                return <CalendarioConsegne/>;
            case 'statistichegenerali':
                return <StatisticheGenerali/>;
            case 'ordinidebitori':
            case 'ordinedebitore':
                return <OrdiniDebitori/>;
            default:
                return <Ordini/>;
        }
        }
      }
    

      return ( 
        <div>
            <div id="kt_header_mobile" className="header-mobile noprint">
	            <a onClick={() => setSidebarPosition(user.role, 'ordini')}>
                {loading || !user ? null :
                user.role !== 'montatore' && user.role !== 'magazziniere' ?
                    <img alt="Logo" src={switchSidebarLogo(user.puntoVendita.nomeDb, user.role)} className="logo-default max-h-50px"/>
                    :
                    <img alt="Logo" src={switchSidebarLogo()} className="logo-default max-h-50px"/>
				}
	            </a>
	            <div className="d-flex align-items-center">
				    <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
				        <span></span>
			        </button>
		        </div>
            </div>
            <div className="d-flex flex-column flex-root">
                <div className="d-flex flex-row flex-column-fluid page">
                <Sidebar/>
                <div className="d-flex flex-column flex-row-fluid wrapper expanding-print">
                <div className="content d-flex flex-column flex-column-fluid">
                    {switchSidebarPosition(sidebar.position)}
                </div>
                </div>
                
                </div>
            </div>
        </div>
)};

Dashboard.propTypes ={
    sidebar: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
    sidebar : state.sidebar,
    auth: state.auth
});

 export default connect(mapStateToProps, {})(Dashboard);