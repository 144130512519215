import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import startOfMonth from 'date-fns/startOfMonth';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import DatePicker, { registerLocale } from "react-datepicker";
import it from 'date-fns/locale/it';
import "react-datepicker/dist/react-datepicker.css";
import Spinner from '../../layout/Spinner';
import Preventivo from './preventivo/Preventivo'
import { switchSidebarLogo, trasformaCifra, pageButtonUI } from '../../../utility/Utility';
import { getPreventivi, filtraPreventivi, cleanPreventivi, filtraPreventiviPerNominativo, eliminaPreventivo } from '../../../actions/preventivo';
import { getVenditori } from '../../../actions/venditore';
import { setSidebarPosition } from '../../../actions/sidebar';
registerLocale('it', it)
const Preventivi = ({ auth: { user }, sidebar, preventivo: {preventivi, numberPreventivi, loading}, getPreventivi, filtraPreventivi, setSidebarPosition, cleanPreventivi, venditore: { venditori}, getVenditori, filtraPreventiviPerNominativo, eliminaPreventivo }) => {
    
    const [totalPreventivi, setTotalPreventivi] = useState(null);
    const [page, setPage] = useState(0);
    const [preventivoOpen, setPreventivoOpen] = useState(false);
    const [idPreventivo, setIdPreventivo] = useState(null);
    const [puntoVenditaPreventivi, setPuntoVenditaPreventivi] = useState('');
    const [venditoreSelezionato, setVenditoreSelezionato] = useState('');
    const [parola, setParola] = useState("");
    const puntoVendita = puntoVenditaPreventivi;
    const pagination = 10;
    const [dataInizio, setDataInizio] = useState(setHours(setMinutes(startOfMonth(new Date()), 0), 0));
    const [dataFine, setDataFine] = useState(setHours(setMinutes(lastDayOfMonth(new Date()), 59), 23));
    

    useEffect(() => {
        getPreventivi(page, pagination, dataInizio, dataFine);
        getVenditori()
        return () => {
            cleanPreventivi();
        }
    }, [getPreventivi, cleanPreventivi, getVenditori]);

    useEffect(() => {
        setTotalPreventivi(numberPreventivi);
        if (numberPreventivi / pagination < page) {
            filtraPreventivi(parola, puntoVendita, 0, pagination, dataInizio, dataFine, venditoreSelezionato)
            setPage(0);
        }
    }, [preventivi]);

    useEffect(() => {
        filtraPreventivi(parola, puntoVendita, 0, pagination, dataInizio, dataFine, venditoreSelezionato)
        setPage(0);
}, [dataInizio, dataFine]);


    useEffect(() => {
        if(sidebar.position === 'preventivi'){
            getPreventivi(page, pagination, dataInizio, dataFine, venditoreSelezionato);
            setPreventivoOpen(false)
        }
    }, [sidebar, getPreventivi]);




    const onClickGoToPage = (page) => {
        filtraPreventivi(parola, puntoVendita, page, pagination, dataInizio, dataFine, venditoreSelezionato)
        setPage(page)
        
    }

    const onClickNextPage = () => {
        if(page+1 !== Math.ceil(totalPreventivi/pagination)){
            filtraPreventivi(parola, puntoVendita, page+1, pagination, dataInizio, dataFine, venditoreSelezionato)
            setPage(page+1)
        }
    }

    const onClickBackPage = () => {
        if(page !== 0){
            filtraPreventivi(parola, puntoVendita, page-1, pagination, dataInizio, dataFine, venditoreSelezionato)
            setPage(page-1)
        }
    }

    
    const [openRow, setOpenRow] = useState(null);
    const onClickToggleRow = (id) => {
        if(id === openRow){
            setOpenRow(null);
        }else{
            setOpenRow(id);
        }
    }

    

    const onClickGetPreventivo = (idPreventivo) => {
        setSidebarPosition(user.role, 'preventivo')
        setPreventivoOpen(true)
        setIdPreventivo(idPreventivo)
    }



    const onChangeSearchPreventivo = e => {
        setParola(e);
        filtraPreventiviPerNominativo(e, page, pagination, dataInizio, dataFine);
    }

    const onChangePuntoVenditaPreventivo = e => {
        setPuntoVenditaPreventivi(e.target.value);
        filtraPreventivi(parola, e.target.value, page, pagination, dataInizio, dataFine, venditoreSelezionato)
    }
    

    const onClickChiudiPreventivo = e => {
        setPreventivoOpen(false);
        getPreventivi(page, pagination, dataInizio, dataFine, venditoreSelezionato);
        setDataInizio(setHours(setMinutes(startOfMonth(new Date()), 0), 0));
        setDataFine(setHours(setMinutes(lastDayOfMonth(new Date()), 59), 23));
        setPuntoVenditaPreventivi('');
        setVenditoreSelezionato('');
        setParola("");
    }

    const onClickEliminaPreventivo = (idPreventivo) => {
        eliminaPreventivo(idPreventivo)
        setPreventivoOpen(false);
        getPreventivi(page, pagination, dataInizio, dataFine, venditoreSelezionato);
        setDataInizio(setHours(setMinutes(startOfMonth(new Date()), 0), 0));
        setDataFine(setHours(setMinutes(lastDayOfMonth(new Date()), 59), 23));
        setPuntoVenditaPreventivi('');
        setVenditoreSelezionato('');
        setParola("");
    }
    
    const onChangeVenditoreOrdine = e => {
        setVenditoreSelezionato(e.target.value);
        filtraPreventivi(parola, puntoVendita, page, pagination, dataInizio, dataFine, e.target.value);
    }

    return ( loading || !preventivi ? <Spinner />:
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="subheader py-3 py-lg-8  subheader-transparent noprint" id="kt_subheader">
                <div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                            <div className="d-flex align-items-baseline flex-wrap mr-5">
                                <h2 className="subheader-title text-dark font-weight-bold my-1 mr-3">Preventivi</h2>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <a onClick={() => setSidebarPosition(user.role, 'nuovopreventivo')} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-file-medical icon-lg"></i>
                            </span> Nuovo Preventivo
			            </a>
                        <a onClick={() => setSidebarPosition(user.role, 'nuovocliente')} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-user-plus icon-lg"></i>
                            </span> Nuovo Cliente
			            </a>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    {preventivoOpen ? 
                        <Preventivo
                            idPreventivo={idPreventivo}
                            onClickChiudiPreventivo={onClickChiudiPreventivo}
                            onClickEliminaPreventivo={onClickEliminaPreventivo}
                        /> :
                        <div className="card card-custom">
                            <div className="card-header flex-wrap border-0 pt-6 pb-0">
                                <div className="card-title">
                                    <h3 className="card-label">
                                        Lista Preventivi
				                        <span className="d-block  pt-2 font-size-sm">Tutti i preventivi</span>
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="mb-7">
                                    <div className="row align-items-center">
                                        <div className="col-lg-8">
                                            <div className="row align-items-center">
                                                <div className="col-md-4 my-2 my-md-0">
                                                    <div className="input-icon">
                                                        <input onChange={e => onChangeSearchPreventivo(e.target.value)} value={parola} type="text" className="form-control" placeholder="Cerca..." id="kt_datatable_search_query" />
                                                        <span><i className="flaticon2-search-1 "></i></span>
                                                    </div>
                                                </div>
                                                {user && user.role === 'admin' ?
                                                    <div class="col-md-4 my-2 my-md-0">
                                                        <div className="input">
						                                    <select class="form-control" name="puntoVenditaPreventivo"  onChange={e => onChangePuntoVenditaPreventivo(e)} >
							                                    <option selected={puntoVendita === ''} value={''}>Qualsiasi Punto Vendita</option>
							                                    <option selected={puntoVendita === 'arca_arredamenti'} value={'arca_arredamenti'}>Arca Arredamenti</option>
							                                    <option selected={puntoVendita === 'lube_creo_store_pavia'} value={'lube_creo_store_pavia'}>Lube Creo Store Pavia</option>
							                                    <option selected={puntoVendita === 'lube_store_stradella'} value={'lube_store_stradella'}>Lube Store Stradella</option>
							                                    <option selected={puntoVendita === 'veneta_cucine_pavia'} value={'veneta_cucine_pavia'}>Veneta Cucine Pavia</option>
						                                    </select>
					                                    </div>
                                                    </div>
                                                    : null
                                                }
                                                {user && user.role === 'admin' && venditori && venditori.length > 0 ?
                                                    <div class="col-md-4 my-2 my-md-0">
                                                        <div className="input">
                                                            <select class="form-control" name="venditoreSelezionato" onChange={e => onChangeVenditoreOrdine(e)} >
                                                            <option selected={true} value={''}>Qualsiasi Venditore</option>
                                                            {venditori.map((venditore, i) => (
                                                                <option selected={venditoreSelezionato === venditore._id} value={venditore._id}>{venditore.cognome} {venditore.nome} - {venditore.puntoVendita.nome}</option>
                                                            ))}
                                                            </select>
                                                        </div>
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                        <div className="card card-custom bgi-no-repeat card-stretch gutter-b" style={{"margin-left":"auto", "margin-bottom":"0px", "box-shadow": "0 0 0 0"}}>
                                            <div className="card-header h-auto" style={{"border": "0px", "padding": "0px"}}>
                                                <div class="card-title py-5">
                                                <span className="mx-2">Da</span>
                                                        <DatePicker
                                                            selected={dataInizio}
                                                            onChange={date => setDataInizio(date)}
                                                            className="field w-input form-control"
                                                            dateFormat='dd/MM/yyyy'
                                                            locale="it"
                                                            required={true}
                                                        />
                                                        <span className="mx-2">a</span>
                                                        <DatePicker
                                                            selected={dataFine}
                                                            onChange={date => setDataFine(date)}
                                                            className="field w-input form-control"
                                                            dateFormat='dd/MM/yyyy'
                                                            locale="it"
                                                            required={true}
                                                        />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded" id="kt_datatable" >
                                    <table className="datatable-table" style={{display: "block"}}>
                                        <thead className="datatable-head">
                                            <tr className="datatable-row" style={{left: "0px"}}>
                                                <th className="datatable-cell datatable-toggle-detail">
                                                    <span></span>
                                                </th>
                                                <th data-field="OrderID" className="datatable-cell">
                                                    <span style={{width: "80px"}}>N.</span>
                                                </th>
                                                <th data-field="ShipAddress" className="datatable-cell">
                                                    <span style={{width: "120px"}}>Cognome</span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell">
                                                    <span style={{width: "120px"}}>Nome</span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell">
                                                    <span style={{width: "100px"}}>Data</span>
                                                </th>
                                                <th data-field="ShipDate" className="datatable-cell">
                                                    <span style={{width: "160px"}}>Venditore</span>
                                                </th>
                                                <th data-field="Status" className="datatable-cell">
                                                    <span style={{width: "80px"}}>Totale</span>
                                                </th>
                                                <th data-field="Actions" data-autohide-disabled="false" className="datatable-cell">
                                                    <span style={{width: "85px"}}>Modifica</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="datatable-body" >
                                            {loading ? null : 
                                                preventivi.map((preventivo, i) => (
                                                    <Fragment>
                                                    <tr data-row="0" className="datatable-row" style={{ left: "0px" }}>
                                                <td className="datatable-cell datatable-toggle-detail">
                                                    <a className="datatable-toggle-detail" onClick ={() => onClickToggleRow(preventivo._id)} >
                                                        <i className={preventivo._id === openRow ? "fa fa-caret-down" : "fa fa-caret-right"}></i>
                                                    </a>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "80px"}}>{preventivo.numero}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "120px"}}>{preventivo.cliente.cognome.charAt(0).toUpperCase() + preventivo.cliente.cognome.slice(1)}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "120px"}}>{preventivo.cliente.nome.charAt(0).toUpperCase() + preventivo.cliente.nome.slice(1)}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "100px"}}><Moment format='DD/MM/YYYY'>{preventivo.date}</Moment></span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "160px"}}>
                                                        <div class="d-flex align-items-center">
                                                            <div class="mr-1">
                                                                <a class="text-dark-75  mb-0">{preventivo.venditore.cognome.charAt(0).toUpperCase() + preventivo.venditore.cognome.slice(1,5)}..</a>                        
                                                            </div>   
                                                            <div class="symbol symbol-50 symbol-sm flex-shrink-0">
                                                                <div class="symbol-label">
                                                                    <img class="h-75 align-self-center" src={switchSidebarLogo(preventivo.puntoVendita.nomeDb, user.role)} alt="photo"/>
                                                                </div>
                                                            </div>                  
                                                        </div>
                                                    </span>
                                                </td>
                                                <td data-field="Country" aria-label="Brazil" className="datatable-cell">
                                                    <span style={{width: "80px"}}>{trasformaCifra(preventivo.totale)}€</span>
                                                </td>
                                                <td data-field="Actions" data-autohide-disabled="false" aria-label="null" className="datatable-cell">
                                                    <span style={{overflow: "visible", position: "relative", width: "85px"}}>
                                                        <a onClick ={() => onClickGetPreventivo(preventivo._id, preventivo.stato)} className="btn btn-sm btn-clean btn-icon mr-2" title="Edit details">
                                                            <span className="svg-icon svg-icon-md">
                                                                <i className="fas fa-edit icon-md"></i>
                                                            </span>
                                                        </a>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="datatable-row-detail" style={openRow!==preventivo._id ? {display: "none"} : null}>
                                                <td className="datatable-detail" colspan="13">
                                                    <table>
                                                    <tr className="datatable-row">
                                                    <td className="datatable-cell">
                                                                <span>Fattura:</span>
                                                            </td>
                                                    <td data-field="Status" aria-label="3" className="datatable-cell">
                                                                <span style={{width: "110px"}}> 
                                                                    <span className={`label label-lg font-weight-bold label-light${preventivo.fattura ? "-primary label-inline" : "-danger label-inline" }`}>{preventivo.fattura ? "Si" : "No"}</span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Caparra confirmatoria:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{trasformaCifra(preventivo.acconto)}€</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Saldo:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{trasformaCifra(preventivo.saldo)}€</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Data preventivo:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}><Moment format='DD/MM/YYYY'>{preventivo.date}</Moment></span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Previsione consegna:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}><Moment format='DD/MM/YYYY'>{preventivo.dataPrevista}</Moment></span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Data conferma preventivo:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                            <span style={{width: "136px"}}>{preventivo.dataConfermaPreventivo ? <Moment format='DD/MM/YYYY'>{preventivo.dataConfermaPreventivo}</Moment> : "Da definire"}</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Data consegna:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{preventivo.dataConsegnaStart ? <Moment format='DD/MM/YYYY'>{preventivo.dataConsegnaStart}</Moment> : "Da definire"}</span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            </Fragment>
                                                )
                                                )}
                                        </tbody>
                                    </table>
                                    <div className="datatable-pager datatable-paging-loaded">
                                        <ul className="datatable-pager-nav mb-5 mb-sm-0">
                                            <li>
                                                <a title="Previous" className={`datatable-pager-link datatable-pager-link-prev ${page === 0 ?"datatable-pager-link-disabled" : null}`} data-page="1" disabled={page === 0} onClick ={() => onClickBackPage()}>
                                                    <i className="flaticon2-back"></i>
                                                </a>
                                            </li>
                                            <li ></li>
                                            {pageButtonUI(totalPreventivi, pagination, page, onClickGoToPage)}
                                            <li>
                                                <a title="Next" className={`datatable-pager-link datatable-pager-link-first ${page+1 === Math.ceil(totalPreventivi/pagination) ?"datatable-pager-link-disabled" : null}`} data-page="1" disabled={page+1 === Math.ceil(totalPreventivi/pagination)} onClick ={() => onClickNextPage()}>
                                                    <i className="flaticon2-next"></i>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="datatable-pager-info">
                                            <span className="datatable-pager-detail">Risultati: {totalPreventivi}</span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

Preventivi.protoTypes = {
    getPreventivi: PropTypes.func.isRequired,
    filtraPreventivi: PropTypes.func.isRequired,
    setSidebarPosition: PropTypes.func.isRequired,
    cleanPreventivi: PropTypes.func.isRequired,
    getVenditori: PropTypes.func.isRequired,
    sidebar: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    preventivo: PropTypes.object.isRequired,
    filtraPreventiviPerNominativo: PropTypes.func.isRequired,
    eliminaPreventivo: PropTypes.func.isRequired

};

const mapStateToProps = state => ({
    sidebar: state.sidebar,
    auth: state.auth,
    preventivo: state.preventivo,
    venditore: state.venditore
});

export default connect(mapStateToProps, { getPreventivi, filtraPreventivi, setSidebarPosition, cleanPreventivi, getVenditori, filtraPreventiviPerNominativo, eliminaPreventivo })(Preventivi);

