import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import logoArca from '../../img/arca_arredamenti.png';


const Login = ({ login, isAuthenticated, auth: {user} }) => {
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });


    const { username, password } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });
    const onSubmit = async e => {
        e.preventDefault();
        login({ username, password });
    };

    if (isAuthenticated && user) {
        return <Redirect to="/" />
    }


    return (
        <div className="d-flex flex-column flex-root vh-100">
            <div className="login login-4 wizard d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="login-container order-2 order-lg-1 d-flex flex-center flex-row-fluid px-7 pt-lg-0 pb-lg-0 pt-4 pb-6 bg-white">
                    <div className="login-content d-flex flex-column pt-lg-0 pt-12">
                        <a className="login-logo" style={{marginLeft : -20 }}>
                            <img src={logoArca} style={{maxWidth: 160 }} />
                        </a>
                        <div className="login-form">
                            <form className="form fv-plugins-bootstrap fv-plugins-framework" id="kt_login_singin_form" action="" novalidate="novalidate" onSubmit={e => onSubmit(e)}>
                                <div className="pb-5 pb-lg-5">
                                    <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">Accedi al portale</h3>
                                </div>
                                <div className="form-group fv-plugins-icon-container">
                                    <label className="font-size-h6 font-weight-bolder text-dark">Username</label>
                                    <input className="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="text" name="username" value={username} onChange={e => onChange(e)} />
                                    <div className="fv-plugins-message-container"></div></div>
                                <div className="form-group fv-plugins-icon-container">
                                <label className="font-size-h6 font-weight-bolder text-dark">Password</label>
                                    <input className="form-control form-control-solid h-auto py-7 px-6 rounded-lg border-0" type="password" name="password" value={password} onChange={e => onChange(e)} />
                                    <div className="fv-plugins-message-container"></div></div>
                                <div className="pb-lg-0 pb-5">
                                    <button type="submit" id="kt_login_singin_form_submit_button" className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3">Accedi</button>
                                </div>
                                <div></div></form>
                        </div>
                    </div>
                </div>
                <div className="login-aside order-1 order-lg-2 bgi-no-repeat bgi-position-x-right">
                    <div className="login-conteiner bgi-no-repeat bgi-position-x-right bgi-position-y-bottom">



                    </div>
                </div>
            </div>
        </div>
)};

Login.propTypes ={
                    login: PropTypes.func.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    auth: PropTypes.object.isRequired
}

const mapStateToProps = state => ({
                    isAuthenticated: state.auth.isAuthenticated,
                    auth: state.auth
});

 export default connect(mapStateToProps, {login})(Login);