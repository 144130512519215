import React, { useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ResponsiveBar } from '@nivo/bar'
import Spinner from '../../layout/Spinner';
import Moment from 'react-moment';
import startOfMonth from 'date-fns/startOfMonth';
import lastDayOfMonth from 'date-fns/lastDayOfMonth';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from 'date-fns/locale/it';
import Ordine from '../ordini/Ordine';
import Preventivo from '../preventivi/preventivo/Preventivo'
import { switchSidebarLogo, trasformaCifra } from '../../../utility/Utility';
import { getIncassoPuntiVendita, getVenditeMensiliUltimoAnno, getConfrontoOrdiniPreventivi, getConfrontoPreventiviOrdiniVenditori, getIncassiVenditoriTrimestrali, getTipologiaOrdini } from '../../../actions/statistiche';
import 'moment/locale/it';
registerLocale('it', it)
const StatisticheGenerali = ({ auth: { user }, statistiche: { venditeMensiliUltimoAnno, confrontoOrdiniPreventivi, incassoPuntiVendita, incassiVenditoriTrimestrali, confrontoPreventiviOrdiniVenditori, tipologiaOrdini, loading }, getVenditeMensiliUltimoAnno, getConfrontoOrdiniPreventivi, getIncassoPuntiVendita, getConfrontoPreventiviOrdiniVenditori, getIncassiVenditoriTrimestrali, getTipologiaOrdini }) => {

    const [ordineOpen, setOrdineOpen] = useState(false);
    const [preventivoOpen, setPreventivoOpen] = useState(false);
    const [idOrdine, setIdOrdine] = useState('');
    const [idPreventivo, setIdPreventivo] = useState('');


    const [dataConfrontoOrdiniPreventiviStart, setDataConfrontoOrdiniPreventiviStart] = useState(setHours(setMinutes(startOfMonth(new Date()), 0), 0));
    const [dataConfrontoOrdiniPreventiviEnd, setDataConfrontoOrdiniPreventiviEnd] = useState(setHours(setMinutes(lastDayOfMonth(new Date()), 59), 23));

    const [dataConfrontoOrdiniPreventiviVenditoriStart, setDataConfrontoOrdiniPreventiviVenditoriStart] = useState(setHours(setMinutes(startOfMonth(new Date()), 0), 0));
    const [dataConfrontoOrdiniPreventiviVenditoriEnd, setDataConfrontoOrdiniPreventiviVenditoriEnd] = useState(setHours(setMinutes(lastDayOfMonth(new Date()), 59), 23));

    const [dataIncassoPuntoVenditaStart, setDataIncassoPuntiVenditaStart] = useState(setHours(setMinutes(new Date(), 0), 0));
    const [dataIncassoPuntoVenditaEnd, setDataIncassoPuntiVenditaEnd] = useState(setHours(setMinutes(new Date(), 59), 23));

    const [dataTipologiaPreventiviOrdiniStart, setDataTipologiaPreventiviOrdiniStart] = useState(setHours(setMinutes(startOfMonth(new Date()), 0), 0));
    const [dataTipologiaPreventiviOrdiniEnd, setDataTipologiaPreventiviOrdiniEnd] = useState(setHours(setMinutes(lastDayOfMonth(new Date()), 59), 23));


    const [dataIncassoVenditoreStart, setDataIncassoVenditoreStart] = useState(setHours(setMinutes(startOfMonth(new Date()), 0), 0));
    const [dataIncassoVenditoreEnd, setDataIncassoVenditoreEnd] = useState(setHours(setMinutes(lastDayOfMonth(new Date()), 59), 23));


    useEffect(() => {
        getConfrontoOrdiniPreventivi(dataConfrontoOrdiniPreventiviStart, dataConfrontoOrdiniPreventiviEnd)
    }, [dataConfrontoOrdiniPreventiviStart, dataConfrontoOrdiniPreventiviEnd]);

    useEffect(() => {
        getConfrontoPreventiviOrdiniVenditori(dataConfrontoOrdiniPreventiviVenditoriStart, dataConfrontoOrdiniPreventiviVenditoriEnd);
    }, [dataConfrontoOrdiniPreventiviVenditoriStart, dataConfrontoOrdiniPreventiviVenditoriEnd]);

    useEffect(() => {
        getIncassoPuntiVendita(dataIncassoPuntoVenditaStart, dataIncassoPuntoVenditaEnd)
    }, [dataIncassoPuntoVenditaStart, dataIncassoPuntoVenditaEnd]);

    useEffect(() => {
        getTipologiaOrdini(dataTipologiaPreventiviOrdiniStart, dataTipologiaPreventiviOrdiniEnd);
    }, [dataTipologiaPreventiviOrdiniStart, dataTipologiaPreventiviOrdiniEnd]);

    useEffect(() => {
        getIncassiVenditoriTrimestrali(dataIncassoVenditoreStart, dataIncassoVenditoreEnd)
    }, [dataIncassoVenditoreStart, dataIncassoVenditoreEnd]);

    useEffect(() => {      
        getVenditeMensiliUltimoAnno();
        getIncassoPuntiVendita(dataIncassoPuntoVenditaStart, dataIncassoPuntoVenditaEnd)
        getConfrontoOrdiniPreventivi(dataConfrontoOrdiniPreventiviStart, dataConfrontoOrdiniPreventiviEnd);
        getConfrontoPreventiviOrdiniVenditori(dataConfrontoOrdiniPreventiviVenditoriStart, dataConfrontoOrdiniPreventiviVenditoriEnd);
        getIncassiVenditoriTrimestrali(dataIncassoVenditoreStart, dataIncassoVenditoreEnd);
        getTipologiaOrdini(dataTipologiaPreventiviOrdiniStart, dataTipologiaPreventiviOrdiniEnd);
    }, [getVenditeMensiliUltimoAnno, getIncassoPuntiVendita, getConfrontoOrdiniPreventivi, getConfrontoPreventiviOrdiniVenditori, getIncassiVenditoriTrimestrali, getTipologiaOrdini]);

    const onClickChiudiOrdine = e => {
        setIdOrdine('');
        setOrdineOpen(false);
    }

    const onClickChiudiPreventivo = e => {
        setIdPreventivo('');
        setPreventivoOpen(false);
    }

    const onClickGetOrdine = (idOrdine) => {
        setIdOrdine(idOrdine);
        setOrdineOpen(true);
    }

    const onClickGetPreventivo = (idPreventivo) => {
        setIdPreventivo(idPreventivo);
        setPreventivoOpen(true);
    }

    return (loading || !venditeMensiliUltimoAnno || !confrontoOrdiniPreventivi || !incassoPuntiVendita || !confrontoPreventiviOrdiniVenditori || !incassiVenditoriTrimestrali || !tipologiaOrdini ? <Spinner /> :
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="subheader py-3 py-lg-8  subheader-transparent " id="kt_subheader">
                <div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                            <div className="d-flex align-items-baseline flex-wrap mr-5">
                                <h2 className="subheader-title text-dark font-weight-bold my-1 mr-3">Statistiche</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {ordineOpen ? 
                        <div className="container">
                <Ordine
                    idOrdine={idOrdine}
                    onClickChiudiOrdine={onClickChiudiOrdine}
                />
                </div> :
                preventivoOpen ? 
                <div className="container">
                <Preventivo
                    idPreventivo={idPreventivo}
                    onClickChiudiPreventivo={onClickChiudiPreventivo}
                />
                </div>
                 :
            <div>
                <div className="container">
            <div class="card card-custom bgi-no-repeat card-stretch gutter-b" style={{ backgroundColor: "inherit", boxShadow:"0 0"}}>
            <div class="card-header h-auto" style={{ padding:"0"}}>
            <div class="card-title py-5">
					<h3 class="card-label">
						Incassi punti vendita <Moment locale="it" format='D MMMM YYYY'>{dataIncassoPuntoVenditaStart}</Moment> a <Moment format='D MMMM YYYY'>{dataIncassoPuntoVenditaEnd}</Moment>
					</h3>
                </div>
                <div class="card-title py-5"><span class="mx-2">Da</span>
                <DatePicker
                    selected={dataIncassoPuntoVenditaStart}
                    onChange={date => setDataIncassoPuntiVenditaStart(date)}
                    selectsStart
                    startDate={dataIncassoPuntoVenditaStart}
                    endDate={dataIncassoPuntoVenditaEnd}
                    className="field w-input form-control"
                    dateFormat='dd/MM/yyyy'
                    locale="it"
                /><span class="mx-2">a</span>
                <DatePicker
                    selected={dataIncassoPuntoVenditaEnd}
                    onChange={date => setDataIncassoPuntiVenditaEnd(date)}
                    selectsEnd
                    startDate={dataIncassoPuntoVenditaStart}
                    endDate={dataIncassoPuntoVenditaEnd}
                    minDate={dataIncassoPuntoVenditaStart}
                    className="field w-input form-control"
                    dateFormat='dd/MM/yyyy'
                    locale="it"
                />
				</div></div>
                <div class="row">
                    {incassoPuntiVendita.map((incassoPuntoVendita) => (
                        <div class="col-xl-3">
                            <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
                                <div class="card-body">
                                    <span class="svg-icon svg-icon-2x svg-icon-info">
                                        <img alt="Logo" src={switchSidebarLogo(incassoPuntoVendita.nomeDb, user.role)} className="max-h-60px" />
                                    </span>
                                    <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-2 d-block"><span className="titolo_statistica">TOTALE:</span> <span className="cifra_statistica">{trasformaCifra(incassoPuntoVendita.incasso)}€</span></span>
                                    <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-2 d-block"><span className="titolo_statistica">N.Ordini:</span> <span className="cifra_statistica">{incassoPuntoVendita.contatore}</span></span>
                                    <span class="font-weight-bold   font-size-sm">{incassoPuntoVendita.puntoVendita}</span>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                    <div class="col-xl-6">
                            <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
                                <div class="card-body">
                                    <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"><span className="titolo_statistica">Totale di tutti i negozi:</span> <span className="cifra_statistica">{trasformaCifra(incassoPuntiVendita.reduce((a, b) => a + (b['incasso'] || 0), 0))}€</span></span>
                                </div>
                            </div>
                        </div>
                </div>
            </div></div>
            <div className="container">
            
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
                        <div class="card-header h-auto">
                        <div class="card-title py-5">
					<h3 class="card-label">
                    Confronto preventivi/ordini da <Moment locale="it" format='D MMMM YYYY'>{dataConfrontoOrdiniPreventiviStart}</Moment> a <Moment format='D MMMM YYYY'>{dataConfrontoOrdiniPreventiviEnd}</Moment>
					</h3>
				</div>
                <div class="card-title py-5"><span class="mx-2">Da</span>
                <DatePicker
                    selected={dataConfrontoOrdiniPreventiviStart}
                    onChange={date => setDataConfrontoOrdiniPreventiviStart(date)}
                    selectsStart
                    startDate={dataConfrontoOrdiniPreventiviStart}
                    endDate={dataConfrontoOrdiniPreventiviEnd}
                    className="field w-input form-control"
                    dateFormat='dd/MM/yyyy'
                    locale="it"
                /><span class="mx-2">a</span>
                <DatePicker
                    selected={dataConfrontoOrdiniPreventiviEnd}
                    onChange={date => setDataConfrontoOrdiniPreventiviEnd(date)}
                    selectsEnd
                    startDate={dataConfrontoOrdiniPreventiviStart}
                    endDate={dataConfrontoOrdiniPreventiviEnd}
                    minDate={dataConfrontoOrdiniPreventiviStart}
                    className="field w-input form-control"
                    dateFormat='dd/MM/yyyy'
                    locale="it"
                />
                </div>
			</div>
                            <div className="d-flex flex-column-fluid">
                <div className=" container " style={{ height: 300 }}>
                    <ResponsiveBar
                        data={confrontoOrdiniPreventivi}
                        keys={['preventivi', 'ordini']}
                        indexBy='puntoVendita'
                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                        padding={0.3}
                        groupMode="grouped"
                        colors={[ '#ffb326', '#32a852']}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Punto vendita',
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        axisLeft={{
                            tickValues: [5],
                            tickSize: 1,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Preventivi/Ordini',
                            legendPosition: 'middle',
                            legendOffset: -40
                        }}
                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                    />
                </div></div>
                </div>
                </div>

            </div></div>
            
            <div className="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
                        <div class="card-header h-auto">
				<div class="card-title py-5">
					<h3 class="card-label">
                    Vendite totali anno {new Date().getFullYear()}

					</h3>
				</div>
			</div>
                            <div className="d-flex flex-column-fluid">
                <div className=" container " style={{ height: 300 }}>
                    <ResponsiveBar
                        data={venditeMensiliUltimoAnno}
                        keys={['vendite']}
                        indexBy="mese"
                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                        padding={0}
                        groupMode="grouped"
                        colors={['#32a852']}
                        colorBy="index"
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        fill={[
                            {
                                match: {
                                    id: 'fries'
                                },
                                id: 'dots'
                            },
                            {
                                match: {
                                    id: 'sandwich'
                                },
                                id: 'lines'
                            }
                        ]}
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Mese',
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        axisLeft={{
                            tickValues: [5],
                            tickSize: 1,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Incassato',
                            legendPosition: 'middle',
                            legendOffset: -40
                        }}
                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                    />
                </div></div></div></div>

            </div></div>

            <div className="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card card-custom bgi-no-repeat card-stretch gutter-b pb-10">
                        <div class="card-header h-auto">
                        <div class="card-title py-5">
					<h3 class="card-label">
						Confronto preventivi/ordini venditori da <Moment locale="it" format='D MMMM YYYY'>{dataConfrontoOrdiniPreventiviVenditoriStart}</Moment> a <Moment format='D MMMM YYYY'>{dataConfrontoOrdiniPreventiviVenditoriEnd}</Moment>
					</h3>
				</div>
                <div class="card-title py-5"><span class="mx-2">Da</span>
                <DatePicker
                    selected={dataConfrontoOrdiniPreventiviVenditoriStart}
                    onChange={date => setDataConfrontoOrdiniPreventiviVenditoriStart(date)}
                    selectsStart
                    startDate={dataConfrontoOrdiniPreventiviVenditoriStart}
                    endDate={dataConfrontoOrdiniPreventiviVenditoriEnd}
                    className="field w-input form-control"
                    dateFormat='dd/MM/yyyy'
                    locale="it"
                /><span class="mx-2">a</span>
                <DatePicker
                    selected={dataConfrontoOrdiniPreventiviVenditoriEnd}
                    onChange={date => setDataConfrontoOrdiniPreventiviVenditoriEnd(date)}
                    selectsEnd
                    startDate={dataConfrontoOrdiniPreventiviVenditoriStart}
                    endDate={dataConfrontoOrdiniPreventiviVenditoriEnd}
                    minDate={dataConfrontoOrdiniPreventiviVenditoriStart}
                    className="field w-input form-control"
                    dateFormat='dd/MM/yyyy'
                    locale="it"
                />
                </div>
			</div>
                            <div className="d-flex flex-column-fluid">
                <div className=" container " style={{ height: 300 }}>
                    <ResponsiveBar
                        data={confrontoPreventiviOrdiniVenditori}
                        keys={['preventivi', 'commissioni']}
                        indexBy='venditore'
                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                        padding={0.3}
                        groupMode="grouped"
                        colors={[ '#ffb326', '#32a852']}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'venditore',
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        axisLeft={{
                            tickValues: [5],
                            tickSize: 1,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Preventivi/Commissioni',
                            legendPosition: 'middle',
                            legendOffset: -40
                        }}
                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                    />
                </div></div>
                <div className="d-flex flex-column-fluid pt-10">
                    <div className=" container ">
                        <div className="col-xl-12">
                            <h3 style={{"text-align": "center"}}>Preventivi</h3>
                        </div>
                    </div>
                    <div className=" container ">
                        <div className="col-xl-12">
                            <h3 style={{"text-align": "center"}}>Commissioni</h3>
                        </div>
                    </div>
                </div>
                {confrontoPreventiviOrdiniVenditori.map((venditore, i) => (
                    venditore.arrayOrdini.length > 0 || venditore.arrayPreventivi.length > 0 ?
                    <Fragment>
                        <div class="card-header h-auto">
                            <div class="card-title py-5">
                            <div><span className="text-nome-cognome">{venditore.cognomeVenditore}: <span style={{ "background":" rgb(255 192 42)", "padding": "5px", "border-radius": "10px"}}>Preventivi = {venditore.numeroPreventiviOrdini}</span> | <span style={{ "background":" #7fea97", "padding": "5px", "border-radius": "10px"}}>Ordini: {venditore.commissioni}</span></span></div>
                            </div>
                            <div class="card-title py-5">
                            <div><span className="text-nome-cognome">Totale per ordini: {venditore.totale}</span></div>
                            </div>
                        </div>
                    <div className="d-flex flex-column-fluid">
                        <div className=" container ">
                            <div className="col-xl-12">
                                <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded" id="kt_datatable" >
                                    <table className="datatable-table" style={{ "display": "block", "background": "#ffc02b6e" }}>
                                        <thead className="datatable-head">
                                            <tr className="datatable-row" style={{ left: "0px" }}>
                                                <th data-field="OrderID" className="datatable-cell">
                                                    <span style={{ width: "50px" }}>N.</span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell">
                                                    <span style={{ width: "100px" }}>Cliente</span>
                                                </th>
                                                <th data-field="ShipAddress" className="datatable-cell">
                                                    <span style={{ width: "100px" }}>Telefono</span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell">
                                                    <span style={{ width: "100px" }}>Totale</span>
                                                </th>
                                                <th data-field="Actions" data-autohide-disabled="false" className="datatable-cell">
                                                    <span style={{ width: "85px" }}>apri</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="datatable-body" >
                                            {
                                            venditore.arrayPreventivi.map((ordine, i) => (
                                                <Fragment>
                                                    <tr data-row="0" className="datatable-row">
                                                        <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                            <span style={{ width: "50px" }}>{ordine.numero}</span>
                                                        </td>
                                                        <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                            <span style={{ width: "100px" }}>{ordine.cliente.cognome.charAt(0).toUpperCase() + ordine.cliente.cognome.slice(1)}</span>
                                                        </td>
                                                        <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                            <span style={{ width: "100px" }}>{ordine.cliente.telefono_1 && ordine.cliente.telefono_1}</span>
                                                        </td>
                                                        <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                            <span style={{ width: "100px" }}>{trasformaCifra(ordine.totale)}€</span>
                                                        </td>
                                                        <td data-field="Actions" data-autohide-disabled="false" aria-label="null" className="datatable-cell">
                                                            <span style={{ overflow: "visible", position: "relative", width: "85px" }}>
                                                            <a  onClick ={() => onClickGetPreventivo(ordine._id)}  className="btn btn-sm btn-clean btn-icon mr-2" title="Edit details">
                                                                    <span className="svg-icon svg-icon-md">
                                                                        <i className="fas fa-edit icon-md"></i>
                                                                    </span>
                                                                </a>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                    </div>
                </div>
                        <div className=" container ">
                            <div className="col-xl-12">
                                <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded" id="kt_datatable" >
                                    <table className="datatable-table" style={{ "display": "block", "background": "#7fea9769" }}>
                                        <thead className="datatable-head">
                                            <tr className="datatable-row" style={{ left: "0px" }}>
                                                <th data-field="OrderID" className="datatable-cell">
                                                    <span style={{ width: "50px" }}>N.</span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell">
                                                    <span style={{ width: "100px" }}>Cliente</span>
                                                </th>
                                                <th data-field="ShipAddress" className="datatable-cell">
                                                    <span style={{ width: "100px" }}>Telefono</span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell">
                                                    <span style={{ width: "100px" }}>Totale</span>
                                                </th>
                                                <th data-field="Actions" data-autohide-disabled="false" className="datatable-cell">
                                                    <span style={{ width: "85px" }}>Apri</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="datatable-body" >
                                            {
                                            venditore.arrayOrdini.map((ordine, i) => (
                                                <Fragment>
                                                    <tr data-row="0" className="datatable-row">
                                                        <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                            <span style={{ width: "50px" }}>{ordine.numero}</span>
                                                        </td>
                                                        <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                            <span style={{ width: "100px" }}>{ordine.cliente.cognome.charAt(0).toUpperCase() + ordine.cliente.cognome.slice(1)}</span>
                                                        </td>
                                                        <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                            <span style={{ width: "100px" }}>{ordine.cliente.telefono_1 && ordine.cliente.telefono_1}</span>
                                                        </td>
                                                        <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                            <span style={{ width: "100px" }}>{trasformaCifra(ordine.totale)}€</span>
                                                        </td>
                                                        <td data-field="Actions" data-autohide-disabled="false" aria-label="null" className="datatable-cell">
                                                            <span style={{ overflow: "visible", position: "relative", width: "85px" }}>
                                                                <a  onClick ={() => onClickGetOrdine(ordine._id)}  className="btn btn-sm btn-clean btn-icon mr-2" title="Edit details">
                                                                    <span className="svg-icon svg-icon-md">
                                                                        <i className="fas fa-edit icon-md"></i>
                                                                    </span>
                                                                </a>
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                    </div>
                </div></div>
                </Fragment> : null))
}
                </div></div>

            </div></div>

            <div className="container">
                <div class="row">
                    <div class="col-xl-12">
                        <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
                        <div class="card-header h-auto">
                        <div class="card-title py-5">
					<h3 class="card-label">
                    Tipologia preventivi/ordini
					</h3>
				</div>
                <div class="card-title py-5"><span class="mx-2">Da</span>
                <DatePicker
                    selected={dataTipologiaPreventiviOrdiniStart}
                    onChange={date => setDataTipologiaPreventiviOrdiniStart(date)}
                    selectsStart
                    startDate={dataTipologiaPreventiviOrdiniStart}
                    endDate={dataTipologiaPreventiviOrdiniEnd}
                    className="field w-input form-control"
                    dateFormat='dd/MM/yyyy'
                    locale="it"
                /><span class="mx-2">a</span>
                <DatePicker
                    selected={dataTipologiaPreventiviOrdiniEnd}
                    onChange={date => setDataTipologiaPreventiviOrdiniEnd(date)}
                    selectsEnd
                    startDate={dataTipologiaPreventiviOrdiniStart}
                    endDate={dataTipologiaPreventiviOrdiniEnd}
                    minDate={dataTipologiaPreventiviOrdiniStart}
                    className="field w-input form-control"
                    dateFormat='dd/MM/yyyy'
                    locale="it"
                />
				</div>
			</div>
                            <div className="d-flex flex-column-fluid">
                <div className=" container " style={{ height: 300 }}>
                    <ResponsiveBar
                        data={tipologiaOrdini}
                        keys={['preventivi', 'ordini']}
                        indexBy='nome'
                        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                        padding={0.3}
                        groupMode="grouped"
                        colors={[ '#ffb326', '#32a852']}
                        defs={[
                            {
                                id: 'dots',
                                type: 'patternDots',
                                background: 'inherit',
                                color: '#38bcb2',
                                size: 4,
                                padding: 1,
                                stagger: true
                            },
                            {
                                id: 'lines',
                                type: 'patternLines',
                                background: 'inherit',
                                color: '#eed312',
                                rotation: -45,
                                lineWidth: 6,
                                spacing: 10
                            }
                        ]}
                        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={{
                            tickSize: 5,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Tipologia',
                            legendPosition: 'middle',
                            legendOffset: 32
                        }}
                        axisLeft={{
                            tickValues: [5],
                            tickSize: 1,
                            tickPadding: 5,
                            tickRotation: 0,
                            legend: 'Preventivi/Ordini',
                            legendPosition: 'middle',
                            legendOffset: -40
                        }}
                        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                        legends={[
                            {
                                dataFrom: 'keys',
                                anchor: 'bottom-right',
                                direction: 'column',
                                justify: false,
                                translateX: 120,
                                translateY: 0,
                                itemsSpacing: 2,
                                itemWidth: 100,
                                itemHeight: 20,
                                itemDirection: 'left-to-right',
                                itemOpacity: 0.85,
                                symbolSize: 20,
                                effects: [
                                    {
                                        on: 'hover',
                                        style: {
                                            itemOpacity: 1
                                        }
                                    }
                                ]
                            }
                        ]}
                        animate={true}
                        motionStiffness={90}
                        motionDamping={15}
                    />
                </div></div></div></div>

            </div></div>




            <div className="container">
            <div class="card card-custom bgi-no-repeat card-stretch gutter-b" style={{ backgroundColor: "inherit", boxShadow:"0 0"}}>
            <div class="card-header h-auto" style={{ padding:"0"}}>
            <div class="card-title py-5">
					<h3 class="card-label">
						Incassi punti vendita <Moment locale="it" format='D MMMM YYYY'>{dataIncassoPuntoVenditaStart}</Moment> a <Moment format='D MMMM YYYY'>{dataIncassoPuntoVenditaEnd}</Moment>
					</h3>
                </div>
                <div class="card-title py-5"><span class="mx-2">Da</span>
                <DatePicker
                    selected={dataIncassoPuntoVenditaStart}
                    onChange={date => setDataIncassoPuntiVenditaStart(date)}
                    selectsStart
                    startDate={dataIncassoPuntoVenditaStart}
                    endDate={dataIncassoPuntoVenditaEnd}
                    className="field w-input form-control"
                    dateFormat='dd/MM/yyyy'
                    locale="it"
                /><span class="mx-2">a</span>
                <DatePicker
                    selected={dataIncassoPuntoVenditaEnd}
                    onChange={date => setDataIncassoPuntiVenditaEnd(date)}
                    selectsEnd
                    startDate={dataIncassoPuntoVenditaStart}
                    endDate={dataIncassoPuntoVenditaEnd}
                    minDate={dataIncassoPuntoVenditaStart}
                    className="field w-input form-control"
                    dateFormat='dd/MM/yyyy'
                    locale="it"
                />
				</div>
                </div>
                <div class="row">
                    {incassoPuntiVendita.map((incassoPuntoVendita) => (
                        <div class="col-xl-3">
                            <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
                                <div class="card-body">
                                    <span class="svg-icon svg-icon-2x svg-icon-info">
                                        <img alt="Logo" src={switchSidebarLogo(incassoPuntoVendita.nomeDb, user.role)} className="max-h-60px" />
                                    </span>
                                    <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-2 d-block"><span className="titolo_statistica">TOTALE:</span> <span className="cifra_statistica">{trasformaCifra(incassoPuntoVendita.incasso)}€</span></span>
                                    <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-2 d-block"><span className="titolo_statistica">INCASSATO:</span> <span className="cifra_statistica">{trasformaCifra(incassoPuntoVendita.incassato)}€</span></span>
                                    <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-2 d-block"><span className="titolo_statistica">DA INCASSARE:</span> <span className="cifra_statistica">{trasformaCifra(incassoPuntoVendita.incasso - incassoPuntoVendita.incassato)}€</span></span>
                                    <span class="font-weight-bold   font-size-sm">{incassoPuntoVendita.puntoVendita}</span>
                                </div>
                            </div>
                        </div>
                    ))
                    }
                    <div class="col-xl-6">
                            <div class="card card-custom bgi-no-repeat card-stretch gutter-b">
                                <div class="card-body">
                                    <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"><span className="titolo_statistica">Totale:</span> <span className="cifra_statistica">{trasformaCifra(incassoPuntiVendita.reduce((a, b) => a + (b['incasso'] || 0), 0))}€</span></span>
                                    <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"><span className="titolo_statistica">Totale incasso:</span> <span className="cifra_statistica">{trasformaCifra(incassoPuntiVendita.reduce((a, b) => a + (b['incassato'] || 0), 0))}€</span></span>
                                    <span class="card-title font-weight-bolder text-dark-75 font-size-h2 mb-0 mt-6 d-block"><span className="titolo_statistica">Totale da incassare:</span> <span className="cifra_statistica">{trasformaCifra(incassoPuntiVendita.reduce((a, b) => a + (b['incasso'] || 0), 0) - incassoPuntiVendita.reduce((a, b) => a + (b['incassato'] || 0), 0))}€</span></span>
                                </div>
                            </div>
                        </div>
                </div>
            </div></div>





            <div className="container">
            <div class="card card-custom bgi-no-repeat" style={{ backgroundColor: "inherit", boxShadow:"0 0"}}>
            <div class="card-header h-auto" style={{ padding:"0"}}>
            <div class="card-title py-5">
					<h3 class="card-label">
						Incassi venditori <Moment locale="it" format='D MMMM YYYY'>{dataIncassoVenditoreStart}</Moment> a <Moment format='D MMMM YYYY'>{dataIncassoVenditoreEnd}</Moment>
					</h3>
                </div>
                <div class="card-title py-5"><span class="mx-2">Da</span>
                <DatePicker
                    selected={dataIncassoVenditoreStart}
                    onChange={date => setDataIncassoVenditoreStart(date)}
                    selectsStart
                    startDate={dataIncassoVenditoreStart}
                    endDate={dataIncassoVenditoreEnd}
                    className="field w-input form-control"
                    dateFormat='dd/MM/yyyy'
                    locale="it"
                /><span class="mx-2">a</span>
                <DatePicker
                    selected={dataIncassoVenditoreEnd}
                    onChange={date => setDataIncassoVenditoreEnd(date)}
                    selectsEnd
                    startDate={dataIncassoVenditoreStart}
                    endDate={dataIncassoVenditoreEnd}
                    minDate={dataIncassoVenditoreStart}
                    className="field w-input form-control"
                    dateFormat='dd/MM/yyyy'
                    locale="it"
                />
				</div>
                </div>
                </div>
                <div class="row">
    
    <div class="col-xl-12">
        
<div class="card card-custom gutter-b">
    <div class="card-body pt-2 pb-0">
        <div class="table-responsive">
            <table class="table table-borderless table-vertical-center">
                <thead>
                    <tr>
                        <th class="p-0" style={{width: "50px"}}></th>
                        <th class="p-0" style={{minWidth: "150px"}}></th>
                        <th class="p-0" style={{minWidth: "120px"}}></th>
                        <th class="p-0" style={{minWidth: "70px"}}></th>
                        <th class="p-0" style={{minWidth: "70px"}}></th>
                        <th class="p-0" style={{minWidth: "50px"}}></th>
                    </tr>
                </thead>
                <tbody>
                {incassiVenditoriTrimestrali.map((incassoVenditoriTrimestrali) => (
                    <tr style={{borderBottom: "1px solid whitesmoke"}}>
                        <td class="pl-0">
                            <div class="symbol symbol-50 symbol-light mr-2 mt-2">
                                <span class="symbol-label">
                                <img src={switchSidebarLogo(incassoVenditoriTrimestrali.nomeDb, user.role)}  class="h-75" alt="" />
                                </span>
                            </div>
                        </td>
                        <td class="pl-0">
                            <a href="#" class="text-dark font-weight-bolder text-hover-primary mb-1 font-size-lg">{incassoVenditoriTrimestrali.cognome} {incassoVenditoriTrimestrali.nome}</a>  
                        </td>
                        <td></td>
                        <td class="text-right">
                            <span class=" font-weight-bold d-block font-size-sm">
                                Incasso
                            </span>
                            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {trasformaCifra(incassoVenditoriTrimestrali.incasso)}€
                            </span>
                        </td>
                        <td class="text-right">
                            <span class="font-weight-bolder text-primary">
                            {incassoVenditoriTrimestrali.percentuale}
                            </span>
                        </td><td class="text-right">
                            <span class=" font-weight-bold d-block font-size-sm">
                                Commisione
                            </span>
                            <span class="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {trasformaCifra(incassoVenditoriTrimestrali.commissione)}€
                            </span>
                        </td>
                    </tr> 
                     ))
                    }     
                </tbody>
            </table>
        </div> 
    </div>
</div>
    </div>
</div>
            </div>
    </div>
}
        </div>
    )
}

StatisticheGenerali.protoTypes = {
    getVenditeMensiliUltimoAnno: PropTypes.func.isRequired,
    getConfrontoOrdiniPreventivi: PropTypes.func.isRequired,
    getIncassoPuntiVendita: PropTypes.func.isRequired,
    getConfrontoPreventiviOrdiniVenditori: PropTypes.func.isRequired,
    getIncassiVenditoriTrimestrali: PropTypes.func.isRequired,
    getTipologiaOrdini: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    statistiche: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    statistiche: state.statistiche
});

export default connect(mapStateToProps, { getVenditeMensiliUltimoAnno, getConfrontoOrdiniPreventivi, getIncassoPuntiVendita, getConfrontoPreventiviOrdiniVenditori, getIncassiVenditoriTrimestrali, getTipologiaOrdini })(StatisticheGenerali);

