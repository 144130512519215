import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import { getRandomColor } from '../../../utility/Utility';
import { createNewMontatore } from '../../../actions/montatore';
import { setSidebarPosition } from '../../../actions/sidebar';
const NuovoMontatore = ({ auth: { user, loading }, createNewMontatore, setSidebarPosition }) => {

    const clienteInitialState = {
        username: '',
        nome: '',
        cognome: '',
        password: '',
        coloreCalendario: getRandomColor()
       }
    const [datiMontatore, setDatiMontatore] = useState(clienteInitialState);
    const { username, nome, cognome, password, coloreCalendario } = datiMontatore;


    const onChangesetDatiMontatore = e => setDatiMontatore({ ...datiMontatore, [e.target.name]: e.target.value });

    const onSubmitCreateMontatore = async e => {
        e.preventDefault();
        createNewMontatore({ username, nome, cognome, password, coloreCalendario });
     };

    return ( loading || !user ? <Spinner /> :
        <div className="content  d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="subheader py-3 py-lg-8  subheader-transparent " id="kt_subheader">
                <div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                            <div className="d-flex align-items-baseline flex-wrap mr-5">
                                <h2 className="subheader-title text-dark font-weight-bold my-1 mr-3">Staff</h2>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <a onClick={() => setSidebarPosition(user.role, 'venditori')} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-users icon-lg"></i>
                            </span> Montatori
			            </a>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-custom example example-compact">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        Nuovo Montatore
				                    </h3>
                                </div>
                                <form className="form" onSubmit= {e => onSubmitCreateMontatore(e)} >
                                    <div className="card-body">
                                        <h3 className="font-size-lg text-dark font-weight-bold mb-6">Dati montatore:</h3>
                                        <div className="mb-15">
                                            <div class="form-group row">
                                            <div class="col-lg-3">
							                        <label>Username:</label>
							                        <input type="text" class="form-control" name="username" value={username} onChange={e => onChangesetDatiMontatore(e)} placeholder="Inserisci username" required/>
						                        </div>
						                        <div class="col-lg-3">
							                        <label>Nome:</label>
							                        <input type="text" class="form-control" name="nome" value={nome} onChange={e => onChangesetDatiMontatore(e)} placeholder="Inserisci nome" required/>
						                        </div>
                                                <div class="col-lg-3">
						                            <label>Cognome:</label>
						                            <input type="text" class="form-control" name="cognome" value={cognome} onChange={e => onChangesetDatiMontatore(e)} placeholder="Inserisci cognome" required/>
					                            </div>
                                                <div class="col-lg-3">
						                            <label>Password:</label>
							                        <input type="password" class="form-control" name="password" value={password} onChange={e => onChangesetDatiMontatore(e)} placeholder="Inserisci password" required/>
						                        </div>
                                                <div class="col-lg-3 mt-5">
						                            <label className="mr-5">Colore calendario:</label>
                                                    <input type="color" name="coloreCalendario" value={coloreCalendario} onChange={e => onChangesetDatiMontatore(e)} />			                        
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <div className="row">
                                            <div className="col-lg-12 text-right">
                                                <button type="submit" className="btn btn-success mr-2 salva-btn">Salva montatore</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

NuovoMontatore.protoTypes = {
    createNewMontatore: PropTypes.func.isRequired,
    setSidebarPosition: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { createNewMontatore, setSidebarPosition })(NuovoMontatore);

