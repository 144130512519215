import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import Spinner from '../../layout/Spinner';
import Ordine from '../ordini/Ordine';
import { switchSidebarLogo, trasformaCifra, pageButtonUI } from '../../../utility/Utility';
import { getOrdiniConfermati, filtraOrdiniConfermati, cleanOrdini } from '../../../actions/ordine';
import { setSidebarPosition } from '../../../actions/sidebar';
const Magazzino = ({ auth: { user }, sidebar, ordine: { ordini, numberOrdini, loading }, getOrdiniConfermati, filtraOrdiniConfermati, setSidebarPosition, cleanOrdini }) => {

    const [totalOrdini, setTotalOrdini] = useState(null);
    const [page, setPage] = useState(0);
    const [ordineOpen, setOrdineOpen] = useState(false);
    const [idOrdine, setIdOrdine] = useState(null);
    const [puntoVenditaOrdini, setPuntoVenditaOrdini] = useState('');
    const puntoVendita = puntoVenditaOrdini;
    const pagination = 10;
    
    
    useEffect(() => {
        getOrdiniConfermati(page, pagination);
        return () => {
            cleanOrdini();
        }
    }, [getOrdiniConfermati, cleanOrdini]);

    useEffect(() => {
        setTotalOrdini(numberOrdini);
        if(numberOrdini/pagination < page){
            filtraOrdiniConfermati(parola, puntoVendita, 0, pagination)
            setPage(0)
        }
    }, [ordini]);

    useEffect(() => {
        if(sidebar.position === 'magazzino'){
            getOrdiniConfermati(page, pagination);
            setOrdineOpen(false)
        }
    }, [sidebar, getOrdiniConfermati]);


    const onClickGoToPage = (page) => {
        filtraOrdiniConfermati(parola, puntoVendita, page, pagination)
        setPage(page)
        
    }

    const onClickNextPage = () => {
        if(page+1 !== Math.ceil(totalOrdini/pagination)){
            filtraOrdiniConfermati(parola, puntoVendita, page+1, pagination)
            setPage(page+1)
        }
    }

    const onClickBackPage = () => {
        if(page !== 0){
            filtraOrdiniConfermati(parola, puntoVendita, page-1, pagination)
            setPage(page-1)
        }
    }

    
    const [openRow, setOpenRow] = useState(null);
    const onClickToggleRow = (id) => {
        if(id === openRow){
            setOpenRow(null);
        }else{
            setOpenRow(id);
        }
    }


    const onClickGetOrdine = (idOrdine) => {
        setSidebarPosition(user.role, 'ordinemagazzino')
        setOrdineOpen(true)
        setIdOrdine(idOrdine)
    }

    const [parola, setParola] = useState("");

    const onChangeSearchOrdine = e => {
        setParola(e);
        filtraOrdiniConfermati(e, puntoVendita, page, pagination)
    }


    const onChangePuntoVenditaOrdine = e => {
        setPuntoVenditaOrdini(e.target.value);
        filtraOrdiniConfermati(parola, e.target.value, page, pagination)
    }

    const onClickChiudiOrdine = e => {
        setOrdineOpen(false);
        getOrdiniConfermati(page, pagination);
    }

    return ( loading || !ordini ? <Spinner /> :
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="subheader py-3 py-lg-8  subheader-transparent " id="kt_subheader">
                <div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                            <div className="d-flex align-items-baseline flex-wrap mr-5">
                                <h2 className="subheader-title text-dark font-weight-bold my-1 mr-3">Gestione magazzino</h2>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <a onClick={() => setSidebarPosition(user.role, 'nuovopreventivo')} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-file-medical icon-lg"></i>
                            </span> Nuovo Preventivo
			            </a>
                        <a onClick={() => setSidebarPosition(user.role, 'nuovocliente')} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-user-plus icon-lg"></i>
                            </span> Nuovo Cliente
			            </a>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    {ordineOpen ? 
                        <Ordine
                            idOrdine={idOrdine}
                            onClickChiudiOrdine={onClickChiudiOrdine}
                        /> :
                        <div className="card card-custom">
                            <div className="card-header flex-wrap border-0 pt-6 pb-0">
                                <div className="card-title">
                                    <h3 className="card-label">
                                        Lista Ordini
				                        <span className="d-block  pt-2 font-size-sm">Tutti gli ordini</span>
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="mb-7">
                                    <div className="row align-items-center">
                                        <div className="col-lg-9 col-xl-8">
                                            <div className="row align-items-center">
                                                <div className="col-md-4 my-2 my-md-0">
                                                    <div className="input-icon">
                                                        <input onChange={e => onChangeSearchOrdine(e.target.value)} value={parola} type="text" className="form-control" placeholder="Cerca..." id="kt_datatable_search_query" />
                                                        <span><i className="flaticon2-search-1 "></i></span>
                                                    </div>
                                                </div>
                                                {user && user.puntoVendita.nomeDb === 'arca_arredamenti' ?
                                                    <div class="col-md-4 my-2 my-md-0">
                                                        <div className="input">
						                                    <select class="form-control" name="puntoVenditaPreventivo"  onChange={e => onChangePuntoVenditaOrdine(e)} >
							                                    <option selected={puntoVendita === ''} value={''}>Qualsiasi Punto Vendita</option>
							                                    <option selected={puntoVendita === 'arca_arredamenti'} value={'arca_arredamenti'}>Arca Arredamenti</option>
							                                    <option selected={puntoVendita === 'lube_creo_store_pavia'} value={'lube_creo_store_pavia'}>Lube Creo Store Pavia</option>
							                                    <option selected={puntoVendita === 'lube_store_stradella'} value={'lube_store_stradella'}>Lube Store Stradella</option>
							                                    <option selected={puntoVendita === 'veneta_cucine_pavia'} value={'veneta_cucine_pavia'}>Veneta Cucine Pavia</option>
						                                    </select>
					                                    </div>
                                                    </div>
                                                    : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded" id="kt_datatable" >
                                    <table className="datatable-table" style={{display: "block"}}>
                                        <thead className="datatable-head">
                                            <tr className="datatable-row" style={{left: "0px"}}>
                                                <th className="datatable-cell datatable-toggle-detail">
                                                    <span></span>
                                                </th>
                                                <th data-field="OrderID" className="datatable-cell">
                                                    <span style={{width: "50px"}}>N.</span>
                                                </th>
                                                <th data-field="ShipAddress" className="datatable-cell">
                                                    <span style={{width: "110px"}}>Cognome</span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell">
                                                    <span style={{width: "110px"}}>Nome</span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell">
                                                    <span style={{width: "110px"}}>Data</span>
                                                </th>
                                                <th data-field="ShipDate" className="datatable-cell">
                                                    <span style={{width: "160px"}}>Venditore</span>
                                                </th>
                                                <th data-field="Status" className="datatable-cell">
                                                    <span style={{width: "100px"}}>Totale</span>
                                                </th>
                                                <th data-field="Status" className="datatable-cell">
                                                    <span style={{width: "160px"}}>Stato</span>
                                                </th>
                                                <th data-field="Actions" data-autohide-disabled="false" className="datatable-cell">
                                                    <span style={{width: "80px"}}>Modifica</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="datatable-body" >
                                            {loading ? null : 
                                                ordini.map((ordine, i) => (
                                                    <Fragment>
                                            <tr data-row="0" className="datatable-row" style={{left: "0px"}}>
                                                <td className="datatable-cell datatable-toggle-detail">
                                                    <a className="datatable-toggle-detail" onClick ={() => onClickToggleRow(ordine._id)} >
                                                        <i className={ordine._id === openRow ? "fa fa-caret-down" : "fa fa-caret-right"}></i>
                                                    </a>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "50px"}}>{ordine.numero}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "110px"}}>{ordine.cliente.cognome.charAt(0).toUpperCase() + ordine.cliente.cognome.slice(1)}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "110px"}}>{ordine.cliente.nome.charAt(0).toUpperCase() + ordine.cliente.nome.slice(1)}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "110px"}}><Moment format='DD/MM/YYYY'>{ordine.dataConfermaPreventivo}</Moment></span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "160px"}}>
                                                        <div class="d-flex align-items-center">
                                                            <div class="mr-1">
                                                                <a class="text-dark-75  mb-0">{ordine.venditore.cognome.charAt(0).toUpperCase() + ordine.venditore.cognome.slice(1)}</a>                        
                                                            </div>   
                                                            <div class="symbol symbol-50 symbol-sm flex-shrink-0">
                                                                <div class="symbol-label">
                                                                    <img class="h-75 align-self-center" src={switchSidebarLogo(ordine.puntoVendita.nomeDb, user.role)} alt="photo"/>
                                                                </div>
                                                            </div>                  
                                                        </div>
                                                    </span>
                                                </td>
                                                <td data-field="Country" aria-label="Brazil" className="datatable-cell">
                                                    <span style={{width: "100px"}}>{trasformaCifra(ordine.totale)}€</span>
                                                </td>
                                                <td data-field="Status" aria-label="3" className="datatable-cell">
                                                    <span style={{width: "160px"}} >                                                    
                                                        <span className={`${ordine.stato ==='magazzinovuoto' ? "label label-lg font-weight-bold btn btn-secondary label-inline" : ordine.stato ==='magazzinoparziale' ? "btn-custom btn btn-light-primary font-weight-bold mr-2-light-primary label-inline" : "label label-lg font-weight-bold btn btn-primary label-inline"}`}>{ordine.stato === 'magazzinovuoto' ? "Magazzino vuoto" : ordine.stato === 'magazzinoparziale' ? "Magazzino parz." : "Tutto in magazzino" }</span>
                                                    </span>
                                                </td>
                                                <td data-field="Actions" data-autohide-disabled="false" aria-label="null" className="datatable-cell">
                                                    <span style={{overflow: "visible", position: "relative", width: "80px"}}>
                                                        <a onClick ={() => onClickGetOrdine(ordine._id)} className="btn btn-sm btn-clean btn-icon mr-2" title="Edit details">
                                                            <span className="svg-icon svg-icon-md">
                                                                <i className="fas fa-edit icon-md"></i>
                                                            </span>
                                                        </a>
                                                    {ordine.ordineRiparato ?
                                                        <a onClick ={() => onClickGetOrdine(ordine.ordineRiparazioneAllegato)} className="btn btn-sm btn-clean btn-icon mr-2" title="Edit details">
                                                            <span className="svg-icon svg-icon-md">
                                                                <i className="far fa-arrow-alt-circle-right icon-lg"></i>
                                                                rip.
                                                            </span>
                                                        </a>
                                                        : null}
                                                        {ordine.ordineRiparazione ?
                                                        <a onClick ={() => onClickGetOrdine(ordine.ordineRiparazioneAllegato)} className="btn btn-sm btn-clean btn-icon mr-2" title="Edit details">
                                                            <span className="svg-icon svg-icon-md">
                                                                <i className="fas fa-arrow-alt-circle-left icon-lg"></i>
                                                                orig.
                                                            </span>
                                                        </a>
                                                        : null}
                                                        </span>
                                                </td>
                                            </tr>
                                            <tr className="datatable-row-detail" style={openRow!==ordine._id ? {display: "none"} : null}>
                                                <td className="datatable-detail" colspan="13">
                                                    <table>
                                                    <tr className="datatable-row">
                                                    <td className="datatable-cell">
                                                                <span>Fattura:</span>
                                                            </td>
                                                    <td data-field="Status" aria-label="3" className="datatable-cell">
                                                                <span style={{width: "110px"}}> 
                                                                    <span className={`label label-lg font-weight-bold label-light${ordine.fattura ? "-primary label-inline" : "-danger label-inline" }`}>{ordine.fattura ? "Si" : "No"}</span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Caparra confirmatoria:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{trasformaCifra(ordine.acconto)}€</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Saldo:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{trasformaCifra(ordine.saldo)}€</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Data preventivo:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}><Moment format='DD/MM/YYYY'>{ordine.date}</Moment></span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Previsione consegna:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}><Moment format='DD/MM/YYYY'>{ordine.dataPrevista}</Moment></span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Data conferma preventivo:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}><Moment format='DD/MM/YYYY'>{ordine.dataConfermaPreventivo}</Moment></span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Data consegna:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{ordine.dataConsegnaStart ? <Moment format='DD/MM/YYYY'>{ordine.dataConsegnaStart}</Moment> : "Da definire"}</span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            </Fragment>
                                                )
                                                )}
                                        </tbody>
                                    </table>
                                    <div className="datatable-pager datatable-paging-loaded">
                                        <ul className="datatable-pager-nav mb-5 mb-sm-0">
                                            <li>
                                                <a title="Previous" className={`datatable-pager-link datatable-pager-link-prev ${page === 0 ?"datatable-pager-link-disabled" : null}`} data-page="1" disabled={page === 0} onClick ={() => onClickBackPage()}>
                                                    <i className="flaticon2-back"></i>
                                                </a>
                                            </li>
                                            <li ></li>
                                            {pageButtonUI(totalOrdini, pagination, page, onClickGoToPage)}
                                            <li>
                                                <a title="Next" className={`datatable-pager-link datatable-pager-link-first ${page+1 === Math.ceil(totalOrdini/pagination) ?"datatable-pager-link-disabled" : null}`} data-page="1" disabled={page+1 === Math.ceil(totalOrdini/pagination)} onClick ={() => onClickNextPage()}>
                                                    <i className="flaticon2-next"></i>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="datatable-pager-info">
                                            <span className="datatable-pager-detail">Risultati: {totalOrdini}</span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

Magazzino.protoTypes = {
    getOrdiniConfermati: PropTypes.func.isRequired,
    filtraOrdiniConfermati: PropTypes.func.isRequired,
    setSidebarPosition: PropTypes.func.isRequired,
    cleanOrdini: PropTypes.func.isRequired,
    sidebar: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    ordine: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    sidebar: state.sidebar,
    auth: state.auth,
    ordine: state.ordine
});

export default connect(mapStateToProps, { getOrdiniConfermati, filtraOrdiniConfermati, setSidebarPosition, cleanOrdini })(Magazzino);

