import axios from 'axios';
import { GET_VENDITORI, CREATE_VENDITORE_SUCCESS, VENDITORE_ERROR } from './types';
import { setAlert } from './alert';
import { setSidebarPosition } from '../actions/sidebar'


export const getVenditori = () => async dispatch => {
    try{
        const res = await axios.get('/api/venditore/venditori');
        dispatch({
            type: GET_VENDITORI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: VENDITORE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const createNewVenditore = ({ username, nome, cognome, password, puntoVendita }) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ username, nome, cognome, password, puntoVendita });
    try{
        const res = await axios.post('/api/venditore/nuovo-venditore', body, config);
        dispatch({
            type: CREATE_VENDITORE_SUCCESS,
            payload: res.data
        });
        dispatch(setAlert('Nuovo Venditore creato!', 'success'));
        dispatch(setSidebarPosition('admin', 'venditori'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: VENDITORE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};