import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from 'date-fns/locale/it';
import Spinner from '../../../../layout/Spinner';
import { createNewPreventivoNuovoCliente, uploadNuovoAllegato, eliminaAllegato, cleanAllegati } from '../../../../../actions/preventivo';
registerLocale('it', it)

const NuovoCliente = ({ auth: { user, loading }, allegati, createNewPreventivoNuovoCliente, uploadNuovoAllegato, eliminaAllegato, cleanAllegati }) => {

    useEffect(() => {
        cleanAllegati();
    }, [cleanAllegati]);

    const [elementiPreventivo, setElementiPreventivo] = useState([{ marca: "", descrizione: "", prezzoListino: null, prezzo: null }]);
    const addElementoPreventivo = () => setElementiPreventivo([...elementiPreventivo, { marca: "", descrizione: "", prezzoListino: null, prezzo: null }]);
    const deleteElementoPreventivo = i =>{
        let arrayElementiPreventivo = elementiPreventivo;
        arrayElementiPreventivo.splice(i,1)
        setElementiPreventivo([...elementiPreventivo]);
        let data = [...elementiPreventivo];
        let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
        data.map((elemento) => (
            somma = somma + elemento.prezzo
        ));
        setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2) });
    };

    const createUIElementiPreventivo = (elementiPreventivo) => {
        return elementiPreventivo.map((elementoPreventivo, i) => (
            <div class="form-group row">
                <div class="col-lg-2">
                    <textarea value={elementoPreventivo.marca} type="text" class="form-control" placeholder="Inserisci fornitore" name="marca" onChange={e => onChangeSetElementoPreventivo(e, i)} required={elementoPreventivo.prezzo >= 0 || elementoPreventivo.descrizione.length > 0} />
                </div>
                <div class="col-lg-5">
                    <textarea value={elementoPreventivo.descrizione} type="text" class="form-control" placeholder="Inserisci descrizione" name="descrizione" onChange={e => onChangeSetElementoPreventivo(e, i)} required={elementoPreventivo.prezzo >= 0 || elementoPreventivo.marca.length > 0} />
                </div>
                <div class="col-lg-2">
                    <input value={elementoPreventivo.prezzoListino} type="number" min="0" max="100000"  class="form-control prezzo-item" placeholder="Inserisci prezzo" name="prezzoListino" onChange={e => onChangeSetElementoPreventivo(e, i)} />
                </div>
                <div class="col-lg-2">
                    <input value={elementoPreventivo.prezzo} type="number" min="0" max="100000"  class="form-control prezzo-item" placeholder="Inserisci prezzo" name="prezzo" onChange={e => onChangeSetElementoPreventivo(e, i)} required={elementoPreventivo.descrizione.length > 0 || elementoPreventivo.marca.length > 0} />
                </div>
                {elementiPreventivo.length == 1 ?
                null :
                <div class="col-lg-1">
                    <a class="mt-2 btn btn-danger font-weight-bold btn-pill" onClick={() => deleteElementoPreventivo(i)}>-</a>
                </div>
                }
            </div>
        ))
    }


    const onChangeSetElementoPreventivo = (e, i) => {
        let data = [...elementiPreventivo];
        if(e.target.name === 'prezzo'){
            if (isNaN(parseFloat(e.target.value)) || !e.target.value) {
                data[i] = { ...data[i], prezzo: null};
                setElementiPreventivo(data);  
                let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));      
                setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2) });        
            } else {
                data[i] = { ...data[i], prezzo: parseFloat(e.target.value) };
                setElementiPreventivo(data);  
                let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2) });
                setAlertPrezziTotaleControl(false)
            }
        }else{
            data[i] = { ...data[i], [e.target.name]: e.target.value };
            setElementiPreventivo(data);        
        }
    }

    const [alertPrezziTotaleControl, setAlertPrezziTotaleControl] = useState(false);
    const prezziTotaleControl = (totale) => {
        let data = [...elementiPreventivo];
        let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
        data.map((elemento) => (
            somma = somma + elemento.prezzo
        ));
        if(somma !== totale){
            setAlertPrezziTotaleControl(true)
        }
        else{
            setAlertPrezziTotaleControl(false)
        }
    }


    const [datiCliente, setDatiCliente] = useState({
        pIvaCodiceFiscale: '',
        nome: '',
        cognome: '',
        telefono_1: '',
        telefono_2: '',
        telefono_3: '',
        telefono_4: '',
        email_1: '',
        email_2: '',
        indirizzo: '',
        cap: '',
        citta: '',
        piano: '',
        scala: '',
        ascensore: false,
        ztl: false
    });
    const { pIvaCodiceFiscale, nome, cognome, telefono_1, telefono_2, telefono_3, telefono_4, email_1, email_2, indirizzo, cap, citta, piano, scala, ascensore, ztl } = datiCliente;


    const onChangeSetDatiCliente = e => setDatiCliente({ ...datiCliente, [e.target.name]: e.target.value });
    const onChangeSetAscensoreCliente = e => setDatiCliente({ ...datiCliente, ascensore: e.target.checked });
    const onChangeSetZTLCliente = e => setDatiCliente({ ...datiCliente, ztl: e.target.checked });


    const [datiPreventivo, setDatiPreventivo] = useState({
        fattura: false,
        misure: 'venditore',
        totale: null,
        acconto: null,
        accontiPersonalizzati : [
            {
                nomeAcconto:'',
                cifraAcconto: null
            },{
                nomeAcconto:'',
                cifraAcconto: null
            }
        ],
        saldo: null,
        dataPrevista: new Date(),
        indirizzoPreventivo: '',
        capPreventivo: '',
        cittaPreventivo: '',
        pianoPreventivo: '',
        scalaPreventivo: '',
        ascensorePreventivo: false,
        ztlPreventivo: false,
        descrizioneGenerale: '',
        note: '',
        tipologia: 'cucina',
        metodoPagamento: '',
        notePagamento: '',
        notePreventivo: '',
        costoProgetto: {
            prezzo: null,
            conteggiato: false
        },
        costoMisure: {
            prezzo: null,
            conteggiato: false
        },
        costoMagazzino: {
            prezzo: null,
            conteggiato: false
        },
        costoSmontaggio: {
            prezzo: null,
            conteggiato: false
        },
        costoElettricista: {
            prezzo: null,
            conteggiato: false
        },
        costoIdraulico: {
            prezzo: null,
            conteggiato: false
        },
        costoElevatore: {
            prezzo: null,
            conteggiato: false
        }
    });

    const { fattura, misure, totale, acconto, accontiPersonalizzati, saldo, dataPrevista, indirizzoPreventivo, capPreventivo, cittaPreventivo, pianoPreventivo, scalaPreventivo, ascensorePreventivo, ztlPreventivo, descrizioneGenerale, note, tipologia, metodoPagamento, notePagamento, notePreventivo, costoProgetto, costoMisure, costoMagazzino, costoSmontaggio, costoElettricista, costoIdraulico, costoElevatore } = datiPreventivo;


    const onChangeSetFatturaPreventivo = e => setDatiPreventivo({ ...datiPreventivo, fattura: e.target.value === 'true' ? true : false });
    const onChangeSetMisurePreventivo = e => setDatiPreventivo({ ...datiPreventivo, misure: e.target.value });
    const onChangeSetTotalePreventivo = e => {
        if (isNaN(parseFloat(e.target.value)) || !e.target.value) {
            setDatiPreventivo({ ...datiPreventivo, totale: null, acconto: 0, saldo: 0 });
            prezziTotaleControl(parseFloat(e.target.value))
        } else {
            var newAccontiPersonalizzati = accontiPersonalizzati
            newAccontiPersonalizzati.map(acconto => {
                acconto.cifraAcconto = isNaN(parseFloat(acconto.cifraAcconto)) ? 0 : parseFloat(acconto.cifraAcconto);
            });
            var somma = newAccontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
            let newSaldo = (parseFloat(e.target.value) - (parseFloat(e.target.value) * 0.3) - somma).toFixed(2);
            if(newSaldo < 0){
                newSaldo = 0;
            }
            setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(e.target.value), acconto: (parseFloat(e.target.value) * 0.3).toFixed(2), saldo: newSaldo });
            prezziTotaleControl(parseFloat(e.target.value))
        }
    }
    
    const onChangeSetAccontoPreventivo = e => {
        var newAccontiPersonalizzati = accontiPersonalizzati
        newAccontiPersonalizzati.map(acconto => {
            acconto.cifraAcconto = isNaN(parseFloat(acconto.cifraAcconto)) ? 0 : parseFloat(acconto.cifraAcconto);
        });
        var somma = newAccontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
        if (isNaN(parseFloat(e.target.value)) || !e.target.value) {
            setDatiPreventivo({ ...datiPreventivo, acconto: 0, saldo: totale - somma });
        } else {
            if(totale - somma - parseFloat(e.target.value) < 0){
                setDatiPreventivo({ ...datiPreventivo, acconto: 0, saldo: totale - somma });
            }else{
                setDatiPreventivo({ ...datiPreventivo, acconto: parseFloat(e.target.value), saldo: (totale - parseFloat(e.target.value) - somma).toFixed(2)});
            }
        }
    }

   
    const onChangeSetNomeAccontiPersonalizzatiPreventivo = (e, i) => {
        var newAccontiPersonalizzati = accontiPersonalizzati
        newAccontiPersonalizzati[i].nomeAcconto = e.target.value;
        setDatiPreventivo({ ...datiPreventivo, accontiPersonalizzati: newAccontiPersonalizzati });
    }


    const onChangeSetCifraAccontiPersonalizzatiPreventivo = (e, i) => {
        var newAccontiPersonalizzati = accontiPersonalizzati
        newAccontiPersonalizzati.map(acconto => {
            acconto.cifraAcconto = isNaN(parseFloat(acconto.cifraAcconto)) ? 0 : parseFloat(acconto.cifraAcconto);
        });
        var somma = newAccontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
        if (isNaN(parseFloat(e.target.value)) || !e.target.value) {
            newAccontiPersonalizzati[i].cifraAcconto = null;
            somma = newAccontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
            setDatiCliente({ ...datiCliente, accontiPersonalizzati: accontiPersonalizzati, saldo: totale - acconto - somma });
        } else {
            if (totale - acconto - somma - parseFloat(e.target.value) + newAccontiPersonalizzati[i].cifraAcconto < 0) {
                newAccontiPersonalizzati[i].cifraAcconto = 0;
                somma = newAccontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
                setDatiCliente({ ...datiCliente, accontiPersonalizzati: accontiPersonalizzati, saldo: totale - acconto - somma });
            } else {
                newAccontiPersonalizzati[i].cifraAcconto = parseFloat(e.target.value)
                somma = newAccontiPersonalizzati.map(acconto => acconto.cifraAcconto).reduce((a, b) => a + b);
                setDatiCliente({ ...datiCliente, accontiPersonalizzati: accontiPersonalizzati, saldo: (totale - acconto - somma).toFixed(2) });
            }
        }
    }

    const onChangeDataprevista = e => setDatiPreventivo({ ...datiPreventivo, dataPrevista: e });
    const onChangeSetAscensorePreventivo = e => setDatiPreventivo({ ...datiPreventivo, ascensorePreventivo: e.target.checked });
    const onChangeSetZTLPreventivo = e => setDatiPreventivo({ ...datiPreventivo, ztlPreventivo: e.target.checked });
    const onChangeSetDatiPreventivo = e => setDatiPreventivo({ ...datiPreventivo, [e.target.name]: e.target.value });
    const onChangeTipologiaPreventivo = e => setDatiPreventivo({ ...datiPreventivo, tipologia: e.target.value });

    const [allegato, setAllegato] = useState();
    const processNuovoAllegato = async() => {
        if(allegato.files.length>0){
            const imageFile = allegato.files[0];
            try {
                const file = imageFile;
                const name = file.name.replace(/\s/g, '');
                const fileType = imageFile.type;
                const part = Math.floor(Date.now() / 1000);
                const fileName = ""+part+"_"+name;
                uploadNuovoAllegato({ file, fileName, nomeOriginale: file.name, fileType, tipologia: 'disegno' });
              } catch (error) {
                console.log(error);
              }
        }
    }


    const onSubmitCreatePreventivo = async e => {
        e.preventDefault();
        datiPreventivo.elementi = elementiPreventivo;
        datiPreventivo.indirizzoConsegna = copiaIndirizzo;
        datiPreventivo.cliente = datiCliente
        datiPreventivo.allegati = allegati;
        createNewPreventivoNuovoCliente(datiPreventivo);
    };

    const [copiaIndirizzo, setCopiaIndirizzo] = useState(true);

    const toggleCopiaIndirizzo = (copiaIndirizzo) => {
        setCopiaIndirizzo(!copiaIndirizzo);
        if(!copiaIndirizzo){
            setDatiPreventivo({ ...datiPreventivo, indirizzoPreventivo: indirizzo, capPreventivo: cap, cittaPreventivo: citta, pianoPreventivo: piano, scalaPreventivo: scala, ascensorePreventivo: ascensore, ztlPreventivo: ztl, dataPrevista: dataPrevista, misure: misure });
        }
    };

    const onChangeCheckCostoProgetto = e => {
        let data = [...elementiPreventivo];
        let newCostoProgetto = costoProgetto;
        if(e.target.value === 'true'){
            let somma = costoProgetto.prezzo + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
            newCostoProgetto.conteggiato = true
            setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2)}, newCostoProgetto);
        }else{
            let somma = (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) +  (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
            newCostoProgetto.conteggiato = false
            setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2)}, newCostoProgetto);
        }
    };

    const onChangeCheckCostoMisure = e => {
        let data = [...elementiPreventivo];
        let newCostoMisure = costoMisure;
        if(e.target.value === 'true'){
            let somma = costoMisure.prezzo + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino.prezzo : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) +  (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
            ));
            newCostoMisure.conteggiato = true;
            setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoMisure});
        }else{
            let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino.prezzo : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) +  (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
            ));
            newCostoMisure.conteggiato = false;
            setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoMisure});
        }
    };

    const onChangeCheckCostoMagazzino = e => {
        let data = [...elementiPreventivo];
        let newCostoMagazzino = costoMagazzino;
        if(e.target.value === 'true'){
            let somma = costoMagazzino.prezzo + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) +  (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
            ));
            newCostoMagazzino.conteggiato = true
            setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoMagazzino});
        }else{
            let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) +  (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
            ));
            newCostoMagazzino.conteggiato = false
            setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoMagazzino});
        }
    };

    const onChangeCheckCostoSmontaggio = e => {
        let newCostoSmontaggio = costoSmontaggio;
        let data = [...elementiPreventivo];
        if(e.target.value === 'true'){
            let somma = costoSmontaggio.prezzo + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
            ));
            newCostoSmontaggio.conteggiato = true
            setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoSmontaggio});
        }else{
            let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) +  (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
            ));
            newCostoSmontaggio.conteggiato = false
            setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoSmontaggio});
        }
    };

    const onChangeCheckCostoElettricista = e => {
        let data = [...elementiPreventivo];
        let newCostoElettricista = costoElettricista;
        if(e.target.value === 'true'){
            let somma = costoElettricista.prezzo + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
            ));
            newCostoElettricista.conteggiato = true
            setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoElettricista});
        }else{
            let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
            ));
            newCostoElettricista.conteggiato = false;
            setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoElettricista});
        }
    };

    const onChangeCheckCostoIdraulico = e => {
        let data = [...elementiPreventivo];
        let newCostoIdraulico = costoIdraulico;
        if(e.target.value === 'true'){
            let somma = costoIdraulico.prezzo + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) +  (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
            ));
            newCostoIdraulico.conteggiato = true;
            setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoIdraulico});
        }else{
            let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) +  (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
            ));
            newCostoIdraulico.conteggiato = false;
            setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoIdraulico});
        }
    };

    const onChangeCheckCostoElevatore = e => {
        let data = [...elementiPreventivo];
        let newCostoElevatore = costoElevatore;
        if(e.target.value === 'true'){
            let somma = costoElevatore.prezzo + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) +  (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
            ));
            newCostoElevatore.conteggiato = true;
            setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoElevatore});
        }else{
            let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) +  (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
            ));
            newCostoElevatore.conteggiato = false;
            setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoElevatore});
        }
    };

    const onChangeSetCostoExtra = (e, i) => {
        let data = [...elementiPreventivo];
        if (isNaN(parseFloat(e.target.value)) || !e.target.value) {
            if(e.target.name === 'costoProgetto'){
                let somma = (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoProgetto = costoProgetto;
                newCostoProgetto.prezzo = null;
                setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoProgetto  });
            }else if(e.target.name === 'costoMisure'){
                let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoMisure = costoMisure;
                newCostoMisure.prezzo = null;
                setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoMisure });
            }else if(e.target.name === 'costoMagazzino'){
                let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) +  (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoMagazzino = costoMagazzino;
                newCostoMagazzino.prezzo = null;
                setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoMagazzino  });
            }else if(e.target.name === 'costoSmontaggio'){
                let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoSmontaggio = costoSmontaggio;
                newCostoSmontaggio.prezzo = null;
                setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoSmontaggio });
            }else if(e.target.name === 'costoElettricista'){
                let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoElettricista = costoElettricista;
                newCostoElettricista.prezzo = null;
                setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoElettricista });
            }else if(e.target.name === 'costoIdraulico'){
                let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoIdraulico = costoIdraulico;
                newCostoIdraulico.prezzo = null;
                setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoIdraulico });
            }else{
                let somma = (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoElevatore = costoElevatore;
                newCostoElevatore.prezzo = null;
                setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoElevatore });
            }
        } else {
            if(e.target.name === 'costoProgetto'){
                let somma = (costoProgetto.conteggiato ? parseFloat(e.target.value) : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoProgetto = costoProgetto;
                newCostoProgetto.prezzo = parseFloat(e.target.value);
                setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoProgetto });
            }else if(e.target.name === 'costoMisure'){
                let somma = (costoMisure.conteggiato ? parseFloat(e.target.value) : 0) + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoMisure = costoMisure;
                newCostoMisure.prezzo = parseFloat(e.target.value);
                setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoMisure });
            }else if(e.target.name === 'costoMagazzino'){
                let somma = (costoMagazzino.conteggiato ? parseFloat(e.target.value) : 0) + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoMagazzino = costoMagazzino;
                newCostoMagazzino.prezzo = parseFloat(e.target.value);
                setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoMagazzino });
            }else if(e.target.name === 'costoSmontaggio'){
                let somma = (costoSmontaggio.conteggiato ? parseFloat(e.target.value) : 0) + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoSmontaggio = costoSmontaggio;
                newCostoSmontaggio.prezzo = parseFloat(e.target.value);
                setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoSmontaggio });
            }else if(e.target.name === 'costoElettricista'){
                let somma = (costoElettricista.conteggiato ? parseFloat(e.target.value) : 0) + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoElettricista = costoElettricista;
                newCostoElettricista.prezzo = parseFloat(e.target.value);
                setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoElettricista });
            }else if(e.target.name === 'costoIdraulico'){
                let somma = (costoIdraulico.conteggiato ? parseFloat(e.target.value) : 0) + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0) + (costoElevatore.conteggiato ? costoElevatore.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoIdraulico = costoIdraulico;
                newCostoIdraulico.prezzo = parseFloat(e.target.value);
                setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoIdraulico });
            }else{
                let somma = (costoElevatore.conteggiato ? parseFloat(e.target.value) : 0) + (costoProgetto.conteggiato ? costoProgetto.prezzo : 0) + (costoMisure.conteggiato ? costoMisure.prezzo : 0) + (costoMagazzino.conteggiato ? costoMagazzino : 0) + (costoSmontaggio.conteggiato ? costoSmontaggio.prezzo : 0) + (costoIdraulico.conteggiato ? costoIdraulico.prezzo : 0) + (costoElettricista.conteggiato ? costoElettricista.prezzo : 0);
                data.map((elemento) => (
                    somma = somma + elemento.prezzo
                ));
                let newCostoElevatore = costoElevatore;
                newCostoElevatore.prezzo = parseFloat(e.target.value);
                setDatiPreventivo({ ...datiPreventivo, totale: parseFloat(somma).toFixed(2), acconto: (parseFloat(somma) * 0.3).toFixed(2), saldo: (parseFloat(somma) - (parseFloat(somma) * 0.3)).toFixed(2), newCostoElevatore });
            }
        }
    }

    const onClickEliminaAllegato = (_id, idOrdine) => {
        var result = window.confirm("Sei sicuro di voler eliminare l'allegato?");
        if (result) {
            eliminaAllegato(_id, idOrdine)
        }
    } 

    return ( loading || !user ? <Spinner /> :
        <form className="form" onSubmit={e => onSubmitCreatePreventivo(e)} >
            <div className="card-body">
                <h3 className="font-size-lg text-dark font-weight-bold mb-6">Dati cliente:</h3>
                <div className="mb-15">
                    <div class="form-group row">
                    <div class="col-lg-6">
                                <label>Fattura:</label>
                                <div class="radio-inline">
                                    <label class="radio">
                                        <input type="radio" value={true} checked={fattura} onChange={e => onChangeSetFatturaPreventivo(e)} />
                                        <span></span>   Si
                                          
                                    </label>
                                    <label class="radio">
                                        <input type="radio" value={false} checked={!fattura} onChange={e => onChangeSetFatturaPreventivo(e)} />
                                        <span></span>    No
                                    
                                    </label>
                                </div>
                            </div>
                        <div class="col-lg-6">
                            <span></span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-3">
                            <label>Cognome: <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" name="cognome" value={cognome} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci cognome" required/>
                        </div>
                        <div class="col-lg-3">
                            <label>Nome: <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" name="nome" value={nome} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci nome" required/>
                        </div>
                        <div class="col-lg-3">
                            <label>Email 1:</label>
                            <input type="email" class="form-control" name="email_1" value={email_1} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci email"/>
                        </div>
                        <div class="col-lg-3">
                            <label>Email 2:</label>
                            <input type="email" class="form-control" name="email_2" value={email_2} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci email"/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-3">
                            <label>Telefono 1: <span class="text-danger">*</span></label>
                            <input type="text" class="form-control" name="telefono_1" value={telefono_1} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci telefono" required/>
                        </div>
                        <div class="col-lg-3">
                            <label>Telefono 2:</label>
                            <input type="text" class="form-control" name="telefono_2" value={telefono_2} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci telefono"/>
                        </div>
                        <div class="col-lg-3">
                            <label>Telefono 3:</label>
                            <input type="text" class="form-control" name="telefono_3" value={telefono_3} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci telefono"/>
                        </div>
                        <div class="col-lg-3">
                            <label>Telefono 4:</label>
                            <input type="text" class="form-control" name="telefono_4" value={telefono_4} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci telefono"/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label>P.Iva/C.F.:</label>
                            <input type="text" class="form-control" name="pIvaCodiceFiscale" value={pIvaCodiceFiscale} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci P.Iva/C.F." />
                        </div>
                        <div class="col-lg-6">
                            <span></span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label>Indirizzo:</label>
                            <input type="text" class="form-control" name="indirizzo" value={indirizzo} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci indirizzo"/>
                        </div>
                        <div class="col-lg-3">
                            <label>CAP:</label>
                            <input type="text" class="form-control" name="cap" value={cap} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci cap"/>
                        </div>
                        <div class="col-lg-3">
                            <label>Città:</label>
                            <input type="text" class="form-control" name="citta" value={citta} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci città"/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-3">
                            <label>Piano:</label>
                            <input type="text" class="form-control" name="piano" value={piano} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci piano" />
                        </div>
                        <div class="col-lg-3">
                            <label>Scala:</label>
                            <input type="text" class="form-control" name="scala" value={scala} onChange={e => onChangeSetDatiCliente(e)} placeholder="Inserisci scala" />
                        </div>
                        <div class="col-lg-6">
                            <label>Accessibilità:</label>
                            <div class="checkbox-inline">
                                <label class="checkbox">
                                <input type="checkbox" checked={ascensore} onChange={e => onChangeSetAscensoreCliente(e)} />
                                    <span></span> 
                                    Ascensore
                                    
                                </label>
                                <label class="checkbox">
                                <input type="checkbox" checked={ztl} onChange={e => onChangeSetZTLCliente(e)} />
                                    <span></span>
                                    ZTL
                                    
                                </label>
                            </div>
                        </div>
                    </div>
                    </div>
                    <h3 className="font-size-lg text-dark font-weight-bold mb-6">Indirizzo di consegna:</h3>
                    <div class="checkbox-inline mb-5">
                        <label class="checkbox">
                            <input type="checkbox" checked={copiaIndirizzo} onClick={() => toggleCopiaIndirizzo(copiaIndirizzo)} />
                            <span></span> Copia l'indirizzo del cliente come indirizzo di consegna
                        </label><br/>
                    </div>
                <div style={{display: copiaIndirizzo ? 'none': null}} className="mb-15">
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label>Indirizzo:</label>
                            <input disabled={copiaIndirizzo} type="text" class="form-control" name="indirizzoPreventivo" value={indirizzoPreventivo} onChange={e => onChangeSetDatiPreventivo(e)} placeholder="Inserisci indirizzo" />
                        </div>
                        <div class="col-lg-3">
                            <label>CAP:</label>
                            <input disabled={copiaIndirizzo} type="text" class="form-control" name="capPreventivo" value={capPreventivo} onChange={e => onChangeSetDatiPreventivo(e)} placeholder="Inserisci cap" />
                        </div>
                        <div class="col-lg-3">
                            <label>Città:</label>
                            <input disabled={copiaIndirizzo} type="text" class="form-control" name="cittaPreventivo" value={cittaPreventivo} onChange={e => onChangeSetDatiPreventivo(e)} placeholder="Inserisci città" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-3">
                            <label>Piano:</label>
                            <input disabled={copiaIndirizzo} type="text" class="form-control" name="pianoPreventivo" value={pianoPreventivo} onChange={e => onChangeSetDatiPreventivo(e)} placeholder="Inserisci piano" />
                        </div>
                        <div class="col-lg-3">
                            <label>Scala:</label>
                            <input disabled={copiaIndirizzo} type="text" class="form-control" name="scalaPreventivo" value={scalaPreventivo} onChange={e => onChangeSetDatiPreventivo(e)} placeholder="Inserisci scala" />
                        </div>
                        <div class="col-lg-6">
                            <label>Accessibilità:</label>
                            <div class="checkbox-inline">
                                <label class="checkbox">
                                <input disabled={copiaIndirizzo} type="checkbox" checked={ascensorePreventivo} onChange={e => onChangeSetAscensorePreventivo(e)} />
                                    <span></span> 
                                    Ascensore
                                    
                                </label>
                                <label class="checkbox">
                                <input disabled={copiaIndirizzo} type="checkbox" checked={ztlPreventivo} onChange={e => onChangeSetZTLPreventivo(e)} />
                                    <span></span>
                                    ZTL
                                    
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                        <div class="col-lg-12">
                            <label>Note consegna:</label>
                            <textarea type="text" class="form-control" name="note" value={note} onChange={e => onChangeSetDatiPreventivo(e)} placeholder="Inserisci note" />
                        </div>
                    </div>
            </div>
            <div className="card-footer">
                <div className="row">
                    <div className="col-lg-3"></div>
                </div>
            </div>
            <div className="card-body">
                <h3 className="font-size-lg text-dark font-weight-bold mb-6">Dati preventivo:</h3>
                <div className="mb-0">
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label>Misure:</label>
                            <div class="radio-inline">
                                <label class="radio">
                                    <input type="radio" value="venditore" checked={misure === "venditore"} onChange={e => onChangeSetMisurePreventivo(e)} />
                                    <span></span>
                                    A carico del venditore
                                </label>
                                <label class="radio">
                                    <input type="radio" value="cliente" checked={misure === "cliente"} onChange={e => onChangeSetMisurePreventivo(e)} />
                                    <span></span>
                                    Comunicati dall'acquirente
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                            <div class="col-lg-10">
                                <label>Descrizione generale:</label>
                                <textarea type="text" class="form-control" placeholder="Inserisci descrizione generale" name="descrizioneGenerale" value={descrizioneGenerale} onChange={e => onChangeSetDatiPreventivo(e)} />

                            </div>
                            <div class="col-lg-2">
                            <label>Tipologia preventivo:</label>
                                <div className="input">
                                    <select class="form-control" name="tipologia" onChange={e => onChangeTipologiaPreventivo(e)} >
                                        <option selected={tipologia === 'cucina'} value={'cucina'}>Cucina</option>
                                        <option selected={tipologia === 'soggiorno'} value={'soggiorno'}>Soggiorno</option>
                                        <option selected={tipologia === 'cameradaletto'} value={'cameradaletto'}>Camera da letto</option>
                                        <option selected={tipologia === 'interoarredamento'} value={'interoarredamento'}>Intero arredamento</option>
                                        <option selected={tipologia === 'complementi'} value={'complementi'}>Complementi</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row" style={{marginBottom: "0px"}}>
                    <div class="col-lg-2">
                                <label>Fornitore</label>
                            </div>
                        <div class="col-lg-5">
                            <label>Descrizione:</label>
                        </div>
                        <div class="col-lg-2">
                            <label>Prezzo Listino</label>
                        </div>
                        <div class="col-lg-2">
                            <label>Prezzo Scontato</label>
                        </div>
                        <div class="col-lg-1">
                            <span></span>
                        </div>
                    </div>
                    {createUIElementiPreventivo(elementiPreventivo)}
                    <a class="btn btn-success font-weight-bold btn-pill" onClick={() => addElementoPreventivo()}>+</a>
                </div>
            </div>
            <div className="card-body pb-0">
                <div class="form-group row">
                    <div class="col-lg-6">
                        <label>Note Preventivo:</label>
                        <textarea type="text" class="form-control" placeholder="Inserisci note preventivo" name="notePreventivo" value={notePreventivo} onChange={e => onChangeSetDatiPreventivo(e)} />
                    </div>
                </div>
            </div>
            <div className="card-body pb-0">
                <div className="mb-0">
                <div class="form-group row">
                        <div class="col-lg-8">
                            <label>Consulenza, progetto/i (di proprietà AR-CA arredamenti fino a stipula del contratto), preventivo/i:</label>
                            <div class="radio-inline">
                                <label class="radio">
                                    <input type="radio" value={true} checked={costoProgetto.conteggiato} onChange={e => onChangeCheckCostoProgetto(e)} />
                                    <span></span>
                                    Si
                                </label>
                                <label class="radio">
                                    <input type="radio" value={false} checked={!costoProgetto.conteggiato} onChange={e => onChangeCheckCostoProgetto(e)} />
                                    <span></span>
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <input type="number" step="0.001" class="form-control" name="costoProgetto"  value={costoProgetto.prezzo} onChange={e => onChangeSetCostoExtra(e)} placeholder="Inserisci prezzo"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body pb-0">
                <div className="mb-0">
                <div class="form-group row">
                        <div class="col-lg-8">
                            <label>Rilevo misure, disegni per architettonico, disegno per impianti, verifica esecuzione lavori:</label>
                            <div class="radio-inline">
                                <label class="radio">
                                    <input type="radio" value={true} checked={costoMisure.conteggiato} onChange={e => onChangeCheckCostoMisure(e)} />
                                    <span></span>
                                    Si
                                </label>
                                <label class="radio">
                                    <input type="radio" value={false} checked={!costoMisure.conteggiato} onChange={e => onChangeCheckCostoMisure(e)} />
                                    <span></span>
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <input type="number" step="0.001" class="form-control" name="costoMisure"  value={costoMisure.prezzo} onChange={e => onChangeSetCostoExtra(e)} placeholder="Inserisci prezzo"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body pb-0">
                <div className="mb-0">
                <div class="form-group row">
                        <div class="col-lg-8">
                            <label>Servizi di magazzinaggio, carico, trasporto, scarico al piano, sballaggio, montaggio, smaltimento imballi:</label>
                            <div class="radio-inline">
                                <label class="radio">
                                    <input type="radio" value={true} checked={costoMagazzino.conteggiato} onChange={e => onChangeCheckCostoMagazzino(e)} />
                                    <span></span>
                                    Si
                                </label>
                                <label class="radio">
                                    <input type="radio" value={false} checked={!costoMagazzino.conteggiato} onChange={e => onChangeCheckCostoMagazzino(e)} />
                                    <span></span>
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <input type="number" step="0.001" class="form-control" name="costoMagazzino"  value={costoMagazzino.prezzo} onChange={e => onChangeSetCostoExtra(e)} placeholder="Inserisci prezzo"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body pb-0">
                <div className="mb-0">
                <div class="form-group row">
                        <div class="col-lg-8">
                            <label>Eventuali servizi per smontaggio, smaltimento, riposizionamento, modifiche, trasloco, montaggio mobili/elettrodomestici del cliente (30,00€ per operaio):</label>
                            <div class="radio-inline">
                                <label class="radio">
                                    <input type="radio" value={true} checked={costoSmontaggio.conteggiato} onChange={e => onChangeCheckCostoSmontaggio(e)} />
                                    <span></span>
                                    Si
                                </label>
                                <label class="radio">
                                    <input type="radio" value={false} checked={!costoSmontaggio.conteggiato} onChange={e => onChangeCheckCostoSmontaggio(e)} />
                                    <span></span>
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <input type="number" step="0.001" class="form-control" name="costoSmontaggio"  value={costoSmontaggio.prezzo} onChange={e => onChangeSetCostoExtra(e)} placeholder="Inserisci prezzo"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body pb-0">
                <div className="mb-0">
                <div class="form-group row">
                        <div class="col-lg-8">
                            <label>Allacciamenti elettrici (montaggio lampade):</label>
                            <div class="radio-inline">
                                <label class="radio">
                                    <input type="radio" value={true} checked={costoElettricista.conteggiato} onChange={e => onChangeCheckCostoElettricista(e)} />
                                    <span></span>
                                    Si
                                </label>
                                <label class="radio">
                                    <input type="radio" value={false} checked={!costoElettricista.conteggiato} onChange={e => onChangeCheckCostoElettricista(e)} />
                                    <span></span>
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <input type="number" step="0.001" class="form-control" name="costoElettricista"  value={costoElettricista.prezzo} onChange={e => onChangeSetCostoExtra(e)} placeholder="Inserisci prezzo"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body pb-0">
                <div className="mb-0">
                <div class="form-group row">
                        <div class="col-lg-8">
                            <label>Allacciamenti idraulici (acqua e gas):</label>
                            <div class="radio-inline">
                                <label class="radio">
                                    <input type="radio" value={true} checked={costoIdraulico.conteggiato} onChange={e => onChangeCheckCostoIdraulico(e)} />
                                    <span></span>
                                    Si
                                </label>
                                <label class="radio">
                                    <input type="radio" value={false} checked={!costoIdraulico.conteggiato} onChange={e => onChangeCheckCostoIdraulico(e)} />
                                    <span></span>
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <input type="number" step="0.001" class="form-control" name="costoIdraulico"  value={costoIdraulico.prezzo} onChange={e => onChangeSetCostoExtra(e)} placeholder="Inserisci prezzo"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body pb-0">
                <div className="mb-0">
                <div class="form-group row">
                        <div class="col-lg-8">
                            <label>Eventuale servizio per occupazione suolo pubblico ed elevatore o facchinaggio:</label>
                            <div class="radio-inline">
                                <label class="radio">
                                    <input type="radio" value={true} checked={costoElevatore.conteggiato} onChange={e => onChangeCheckCostoElevatore(e)} />
                                    <span></span>
                                    Si
                                </label>
                                <label class="radio">
                                    <input type="radio" value={false} checked={!costoElevatore.conteggiato} onChange={e => onChangeCheckCostoElevatore(e)} />
                                    <span></span>
                                    No
                                </label>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <input type="number" step="0.001" class="form-control" name="costoElevatore"  value={costoElevatore.prezzo} onChange={e => onChangeSetCostoExtra(e)} placeholder="Inserisci prezzo"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card-body">
                <div className="mb-15">
                    <div class="form-group row">
                        <div class="col-lg-8">
                        </div>
                        <div class="col-lg-4">
                            <label>Totale preventivo </label> {alertPrezziTotaleControl ? <span class="label label-xl label-danger label-pill label-inline mr-2">Totale non corretto</span> : null}
                            <input type="number" step="0.001" class="form-control" name="totale"  value={totale} onChange={e => onChangeSetTotalePreventivo(e)} placeholder="Inserisci totale" required/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-8">
                        </div>
                        <div class="col-lg-4">
                            <label>Caparra confirmatoria </label> 
                            { acconto < totale*0.3 ? <span class="ml-1 label label-xl label-danger label-pill label-inline mr-2">minore del 30%</span> : null}
                            <input type="text" class="form-control" name="acconto" value={acconto} placeholder="Inserisci caparra confirmatoria" onChange={e => onChangeSetAccontoPreventivo(e)} required/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label>Note Pagamento:</label>
                            <textarea type="text" class="form-control" placeholder="Inserisci note pagamento" name="notePagamento" value={notePagamento} onChange={e => onChangeSetDatiPreventivo(e)} />
                        </div>
                        <div class="col-lg-2">
                        </div>
                        <div class="col-lg-2">
                            <label>Opzione pers.</label> 
                            <input type="text" class="form-control" name="accontoPersonalizzato1" value={accontiPersonalizzati[0].nomeAcconto} placeholder="Inserisci nome" onChange={e => onChangeSetNomeAccontiPersonalizzatiPreventivo(e, 0)}/>
                        </div>
                        <div class="col-lg-2">
                            <input type="number" class="form-control mt-8" name="accontoPersonalizzato1.cifraAcconto" value={accontiPersonalizzati[0].cifraAcconto} placeholder="Inserisci numero" onChange={e => onChangeSetCifraAccontiPersonalizzatiPreventivo(e, 0)}/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-8">
                        </div>
                        <div class="col-lg-2">
                        <label>Opzione pers.</label> 
                            <input type="text" class="form-control" name="accontoPersonalizzato2.nomeAcconto" value={accontiPersonalizzati[1].nomeAcconto} placeholder="Inserisci nome" onChange={e => onChangeSetNomeAccontiPersonalizzatiPreventivo(e, 1)}/>
                        </div>
                        <div class="col-lg-2">
                            <input type="number" class="form-control mt-8" name="accontoPersonalizzato2.cifraAcconto" value={accontiPersonalizzati[1].cifraAcconto} placeholder="Inserisci numero" onChange={e => onChangeSetCifraAccontiPersonalizzatiPreventivo(e, 1)}/>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-8">
                        </div>
                        <div class="col-lg-4">
                            <label>Saldo</label>
                            <input type="text" class="form-control" name="saldo" value={saldo} placeholder="Inserisci saldo" required/>
                        </div>
                    </div>
                    <div class="form-group row">
                                <div class="col-lg-8">
                                </div>
                                <div class="col-lg-4">
                                    <label className="mr-5">Stima data consegna merce</label>
                                    <DatePicker
                                        onChange={e => onChangeDataprevista(e)}
                                        dateFormat='dd/MM/yyyy'
                                        selected={dataPrevista}
                                        className="field w-input form-control"
                                        minDate={new Date()}
                                        locale="it"
                                    />                                
                                </div>
                            </div>
                </div>
            </div>
            <div className="card-footer">
            </div>
            <div className="card-body">
            <div className="col-lg-12 mb-15">
            <h3 className="font-size-lg text-dark font-weight-bold mb-6">Allegati disegno:</h3>
            {allegati.map((allegato) => (
                                allegato.type === 'image/jpeg' || allegato.type === 'image/png' ?
                                <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4 elemento-file">
                                    <div class="d-flex flex-column">
                                        <a href={allegato.url} target="_blank">
                                            <div class="symbol-label mb-3" style={{backgroundImage: "url(" + allegato.url + ")"}}>
                                            <span class="svg-icon svg-icon-lg mx-2"><i class="far fa-image"></i></span>
                                            </div>
                                            <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                        </a>
                                        <a onClick={()=> { onClickEliminaAllegato(allegato.url) }} class="btn btn-danger font-weight-bolder py-2 font-size-sm">Elimina</a>
                                    </div>   
                                </div> 
                                :
                                <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4 elemento-file">
                                    <div class="d-flex flex-column">
                                    <a href={allegato.url} target="_blank">
                                        <div class="symbol-label mb-3" style={{backgroundImage: "url(" + allegato.url + ")"}}>
                                        <span class="svg-icon svg-icon-lg mx-2"><i class="fas fa-file-pdf"></i></span>PDF
                                        </div>
                                        <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                        </a>
                                        <a onClick={()=> { onClickEliminaAllegato(allegato.url) }} class="btn btn-danger font-weight-bolder py-2 font-size-sm">Elimina</a>
                                    </div>   
                                </div>   
                            ))}
                            <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4">
                            <label htmlFor="file-input-image">
                                    <div class="d-flex flex-column">
                                    <a>
                                            <div class="symbol-label mb-3 btn-light-primary">
                                            <span class="svg-icon svg-icon-lg mx-2"><i class="fas fa-file-upload"></i></span>
                                            <input style={{display: "none"}} id="file-input-image" ref={(ref) => { setAllegato(ref)}} onChange={() => processNuovoAllegato()} onClick={(event)=> { event.target.value = null }} type="file"/>  
                                            </div>
                                        </a>
                                    </div>  
                                    </label> 
                                </div> 
                                </div>
                                
                    </div>
            <div className="card-footer">
                <div className="row">
                    <div className="col-lg-12 text-right">
                        <button type="submit" className="btn btn-success salva-btn">Salva preventivo</button>
                    </div>
                </div>
            </div>
        </form>
    )
}

NuovoCliente.protoTypes = {
    createNewPreventivoNuovoCliente: PropTypes.func.isRequired,
    uploadNuovoAllegato: PropTypes.func.isRequired,
    eliminaAllegato: PropTypes.func.isRequired,
    cleanAllegati: PropTypes.func.isRequired,
    cliente: PropTypes.object.isRequired,
    allegati: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    cliente: state.cliente,
    allegati: state.preventivo.allegati,
    auth: state.auth
});

export default connect(mapStateToProps, { createNewPreventivoNuovoCliente, uploadNuovoAllegato, eliminaAllegato, cleanAllegati })(NuovoCliente);

