import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from 'date-fns/locale/it';
import setHours from "date-fns/setHours";
import setMinutes from "date-fns/setMinutes";
import Spinner from '../../layout/Spinner';
import Ordine from '../ordini/Ordine';
import "react-datepicker/dist/react-datepicker.css";
import { getOrdiniDaConsegnare, filtraOrdiniDaConsegnareMontatore, setDataConsegnaOrdineOnDrag, cleanOrdini } from '../../../actions/ordine';
import { setSidebarPosition } from '../../../actions/sidebar';
import { getMontatori } from '../../../actions/montatore';
registerLocale('it', it)
const GestioneConsegne = ({ auth: { user }, sidebar, ordine: { ordini, numberOrdini, loading }, montatore: {montatori}, getOrdiniDaConsegnare, filtraOrdiniDaConsegnareMontatore, setDataConsegnaOrdineOnDrag, setSidebarPosition, cleanOrdini, getMontatori }) => {

    const [modalCalendario, setModalCalendar] = useState(false);
    const [ordineOpen, setOrdineOpen] = useState(false);
    const [idOrdine, setIdOrdine] = useState(null);
    const [calendarWeekends, setCalendarWeekends] = useState(true);
    const [calendarEvents, setCalendarEvents] = useState([]);

    let calendarComponentRef = React.createRef();
    
    const [montatore, setMontatore] = useState(null);
    const [ordineConsegna, setOrdineConsegna] = useState({idOrdine: '', numeroOrdine: '', nome: '', cognome: ''});
    const [nuovaDataConsegnaStart, setNuovaDataConsegnaStart] = useState(new Date());
    const [nuovaDataConsegnaEnd, setNuovaDataConsegnaEnd] = useState(new Date());
    

    const handleDateClick = arg => {
        ordini.filter((ordine) =>{
            if(!ordine.dataConsegnaStart){
                setModalCalendar(true)
                setNuovaDataConsegnaStart(new Date(arg.date))
                setNuovaDataConsegnaEnd(new Date(arg.date))
                return
            }
        })
      };

      const submitConsegnaPreventivo = arg => {
        setModalCalendar(false)
        let startDate = new Date(nuovaDataConsegnaStart)
        let endDate = new Date (nuovaDataConsegnaEnd)
        setDataConsegnaOrdineOnDrag(ordineConsegna.idOrdine, startDate, endDate, montatore)
        const colorMontatore = montatori.filter(elemento => elemento._id === montatore)[0].coloreCalendario;
            setCalendarEvents([...calendarEvents, {
                title: 'N.'+ordineConsegna.numeroOrdine+' - '+ordineConsegna.cognome+' '+ordineConsegna.nome + + ' ' + (ordineConsegna.montatore && ordineConsegna.montatore.cognome ? ordineConsegna.montatore.cognome : '') + ' ' + (ordineConsegna.montatore && ordineConsegna.montatore.nome ? ordineConsegna.montatore.nome : ''),
                start: moment(startDate).format('YYYY-MM-DD HH:mm'),
                end: moment(endDate).format('YYYY-MM-DD HH:mm'),
                //start: moment(startDate).utcOffset(120).format('YYYY-MM-DD HH:mm'),
                //end: moment(endDate).utcOffset(120).format('YYYY-MM-DD HH:mm'),
                allDay: false,
                color: colorMontatore
              }])
      }; 

    useEffect(() => {
        getMontatori()
        getOrdiniDaConsegnare();
        return () => {
            cleanOrdini();
        }
    }, [getOrdiniDaConsegnare, cleanOrdini]);

    const onClickChiudiOrdine = e => {
        setOrdineOpen(false);
        getOrdiniDaConsegnare();
    }

    useEffect(() => { 
        let arrayCalendar = [];
            ordini.map((ordine) => (
                ordine.dataConsegnaStart ? 
                arrayCalendar.push({
                    id: ordine._id,
                    montatore: ordine.montatore._id,
                    title: 'N.'+ordine.numero+' - '+ordine.cliente.cognome+' '+ordine.cliente.nome + ' ' + (ordine.montatore && ordine.montatore.cognome ? ordine.montatore.cognome : '') + ' ' + (ordine.montatore && ordine.montatore.nome ? ordine.montatore.nome : ''),
                    start: moment(ordine.dataConsegnaStart).format('YYYY-MM-DD HH:mm'),
                    end: moment(ordine.dataConsegnaEnd).format('YYYY-MM-DD HH:mm'),
                    //start: moment(ordine.dataConsegnaStart).utcOffset(120).format('YYYY-MM-DD HH:mm'),
                    //end: moment(ordine.dataConsegnaEnd).utcOffset(120).format('YYYY-MM-DD HH:mm'),
                    color: ordine.montatore.coloreCalendario,
                    allDay: false
                })
                    : null
              )) 
              setCalendarEvents(arrayCalendar)
    }, [ordini]);

    useEffect(() => {
        if(sidebar.position === 'consegne'){
            getOrdiniDaConsegnare();
        }
    }, [sidebar, getOrdiniDaConsegnare]);
    
    const onChangeSetOrdineConsegna = (idOrdine) => {
        ordini.map((ordine, i) => {
            if(ordine._id === idOrdine){
                setOrdineConsegna({ ...ordineConsegna, idOrdine: idOrdine, numeroOrdine: ordine.numero, nome: ordine.cliente.nome, cognome: ordine.cliente.cognome });
            }
        })
    }


    const onChangeSetMontatore = e => {
        setMontatore(e);
        filtraOrdiniDaConsegnareMontatore(e);
    }

    return ( loading || !ordini || !montatori ? <Spinner /> :
        <div onClick={(e) => e.target.id && e.target.id === 'modalpopup' ? setModalCalendar(false) : null} className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="subheader py-3 py-lg-8  subheader-transparent " id="kt_subheader">
                <div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                            <div className="d-flex align-items-baseline flex-wrap mr-5">
                                <h2 className="subheader-title text-dark font-weight-bold my-1 mr-3">Gestione consegne</h2>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <a onClick={() => setSidebarPosition(user.role, 'consegne')} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-calendar-alt icon-lg"></i>
                            </span> Gestione Consegne 
			            </a>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                {ordineOpen ? 
                        <Ordine
                            idOrdine={idOrdine}
                            onClickChiudiOrdine={onClickChiudiOrdine}
                        /> :
                        <div className="card card-custom">
                            <div className="card-header flex-wrap border-0 pt-6 pb-0">
                                <div className="card-title">
                                    <h3 className="card-label">
                                        Lista Ordini
				                        <span className="d-block  pt-2 font-size-sm">Tutti gli ordini</span>
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="mb-7">
                                    <div className="row align-items-center">
                                        <div className="col-lg-9 col-xl-8">
                                            <div className="row align-items-center">
                                                <div class="col-md-4 my-2 my-md-0">
                                                    <div className="input">
						                                <select class="form-control" name="statoOrdine"  onChange={e => onChangeSetMontatore(e.target.value)} >
                                                        <option selected={montatore === ''} value={''}>Tutti i montatori</option>
                {montatori.map((montatore) => (
				<option value={montatore._id}>{montatore.cognome} - {montatore.nome}</option>
                ))}
						                                </select>
					                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                        <div class={`modal fade ${modalCalendario ? 'show' : null}`} style={{display: modalCalendario ? 'block': 'none'}} id="modalpopup" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Pianifica consegna</h5>
                <button onClick ={() => setModalCalendar(false)} type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <i aria-hidden="true" class="ki ki-close"></i>
                </button>
            </div>
            <div class="modal-body">
                <div className="ordine mb-3">
                    <select class="form-control" name="statoPreventivo"  onChange={e => onChangeSetOrdineConsegna(e.target.value)} >
                    <option selected disabled >Selezione ordine... </option>
                        {ordini.map((ordine) => (
                         !ordine.dataConsegnaStart ?
				        <option value={ordine._id}>{ordine.numero}: {ordine.cliente.cognome} {ordine.cliente.nome}</option>
                        : null
                         ))}
                </select>
            </div>
            <div className="squadra mb-3">
            <select class="form-control" name="statoPreventivo"  onChange={e => setMontatore(e.target.value)} >
                <option selected disabled >Seleziona montatore</option>
                {montatori.map((montatore) => (
				<option value={montatore._id}>{montatore.cognome} - {montatore.nome}</option>
                ))}
			</select>
            </div>
            <div class="card-title py-5"
            ><span class="mx-2">Da</span>
            <DatePicker
                selected={nuovaDataConsegnaStart}
                onChange={date => setNuovaDataConsegnaStart(date)}
                showTimeSelect
                minTime={setHours(setMinutes(new Date(), 0), 8)}
                maxTime={setHours(setMinutes(new Date(), 0), 20)}
                className="field w-input form-control"
                dateFormat='dd/MM/yyyy HH:mm'
                locale="it"
            />
             <span className="mx-2">a</span>
            <DatePicker
                selected={nuovaDataConsegnaEnd}
                onChange={date => setNuovaDataConsegnaEnd(date)}
                showTimeSelect
                minTime={setHours(setMinutes(new Date(), 0), 8)}
                maxTime={setHours(setMinutes(new Date(), 0), 20)}
                className="field w-input form-control"
                dateFormat='dd/MM/yyyy HH:mm'
                locale="it"
            />
                </div>
            </div>
            <div class="modal-footer">
                <button onClick ={() => setModalCalendar(false)} type="button" class="btn btn-light-primary font-weight-bold" data-dismiss="modal">Annulla</button>
                <button disabled={!ordineConsegna || !montatore ? true : false } onClick ={() => submitConsegnaPreventivo()} type="button" class="btn btn-primary font-weight-bold salva-btn">Salva</button>
            </div>
        </div>
    </div>
</div>
                                <div className="animated fadeIn p-4 demo-app">
          <div className="demo-app">
              
        <div className="demo-app-calendar">
          <FullCalendar
            timeZone= 'local'
            editable={true}
            slotDuration= '00:30:00'
            expandRows= {true}
            locale= 'it'
            firstDay= '1'
            allDaySlot= {false}
            hiddenDays= {[ 0, 6 ]}
            initialView= 'timeGridWeek'
            slotMinTime='08:00:00'
            slotMaxTime='19:30:00'
            slotLabelFormat= {{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: false, 
                meridiem: 'short'
              }}
            displayEventTime= {true}
            buttonText= {{
                today:    'Oggi',
                month:    'Mese',
                week:     'Settimana',
                day:      'Giorno',
                list:     'Lista'
              }}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay"
            }}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            ref={calendarComponentRef}
            weekends={calendarWeekends}
            events={calendarEvents}
            eventDurationEditable={false}
            eventStartEditable={false}
            dateClick={handleDateClick}
            eventClick={info =>{
                setIdOrdine(info.event._def.publicId)
                setOrdineOpen(true)
            }}
          />
        </div>
      </div>
      </div>
          </div>
                    </div>
}
                </div>
            </div>
        </div>
    )
}

GestioneConsegne.protoTypes = {
    getOrdiniDaConsegnare: PropTypes.func.isRequired,
    filtraOrdiniDaConsegnareMontatore: PropTypes.func.isRequired,
    setDataConsegnaOrdineOnDrag: PropTypes.func.isRequired,
    setSidebarPosition: PropTypes.func.isRequired,
    getMontatori: PropTypes.func.isRequired,
    cleanOrdini: PropTypes.func.isRequired,
    sidebar: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    ordine: PropTypes.object.isRequired,
    montatore: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    sidebar: state.sidebar,
    auth: state.auth,
    ordine: state.ordine,
    montatore: state.montatore
});

export default connect(mapStateToProps, { getOrdiniDaConsegnare, filtraOrdiniDaConsegnareMontatore, setDataConsegnaOrdineOnDrag, setSidebarPosition, cleanOrdini, getMontatori })(GestioneConsegne);

