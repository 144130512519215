import { GET_ORDINI, GET_TUTTI_ORDINI, GET_ORDINI_CONFERMATI, GET_ORDINI_DA_CONSEGNARE, GET_ORDINI_DA_CONSEGNARE_MONTATORI, GET_ORDINI_DEBITORI, GET_TUTTI_ORDINI_DEBITORI, MODIFICA_PAGAMENTO, GET_ORDINE, FILTRA_ORDINI, FILTRA_ORDINI_CONFERMATI, FILTRA_ORDINI_DA_CONSEGNARE, FILTRA_ORDINI_DA_CONSEGNARE_MONTATORI, FILTRA_ORDINI_DEBITORI, SET_ANNULLA_ORDINE, MODIFICA_MAGAZZINO, SET_TUTTO_MAGAZZINO_ELEMENTI_ORDINE, SET_ORDINE_CONSEGNATO, SET_DATA_CONSEGNA_ORDINE, SET_DATA_CONSEGNA_ORDINE_ON_DRAG, MODIFICA_ORDINE, ABILITA_CONSEGNA, SET_PAGAMENTO_NOTE_ORDINE, CREATE_ORDINE_RIPARAZIONE, UPLOAD_ALLEGATI_ORDINE, UPLOAD_ALLEGATO_ORDINE, CLEAN_ORDINE, CLEAN_ORDINI, CLEAN_ALLEGATI_ORDINE, ORDINE_ERROR } from "../actions/types";

const initialState = {
    ordine: null,
    ordini: [],
    tuttiOrdini: [],
    numberOrdini: 0,
    allegati: [],
    error: {},
    loading: true
}

export default function(state = initialState, action){
    const { type, payload } = action;
    switch(type){
        case GET_ORDINE:
        case MODIFICA_MAGAZZINO:
        case SET_TUTTO_MAGAZZINO_ELEMENTI_ORDINE:
        case SET_ORDINE_CONSEGNATO:
        case CREATE_ORDINE_RIPARAZIONE:
        case SET_ANNULLA_ORDINE:
        case MODIFICA_ORDINE:
        case ABILITA_CONSEGNA:
        case SET_PAGAMENTO_NOTE_ORDINE:
        case SET_DATA_CONSEGNA_ORDINE:
        case MODIFICA_PAGAMENTO:
            return {
                ...state,
                ordine: payload,
                loading: false
            };
        case GET_ORDINI:
        case GET_ORDINI_CONFERMATI:
        case GET_ORDINI_DA_CONSEGNARE:
        case GET_ORDINI_DA_CONSEGNARE_MONTATORI:
        case FILTRA_ORDINI:
        case FILTRA_ORDINI_CONFERMATI:
        case FILTRA_ORDINI_DA_CONSEGNARE:
        case FILTRA_ORDINI_DA_CONSEGNARE_MONTATORI:
        case SET_DATA_CONSEGNA_ORDINE_ON_DRAG:
        case GET_ORDINI_DEBITORI:
        case FILTRA_ORDINI_DEBITORI:
            return {
                ...state,
                ordini: payload.ordini,
                numberOrdini: payload.numberOrdini,
                loading: false
            };
        case GET_TUTTI_ORDINI:
        case GET_TUTTI_ORDINI_DEBITORI:
            return {
                ...state,
                tuttiOrdini: payload,
                loading: false
            };
        case UPLOAD_ALLEGATO_ORDINE:
            return {
                ...state,
                allegati: state.allegati.concat(payload),  
                loading: false
            }
        case UPLOAD_ALLEGATI_ORDINE:
            return {
                ...state,
                allegati: payload,  
                loading: false
            }
        case CLEAN_ORDINE:
            return {
                ...state,
                ordine: null,
                loading: false
            };
        case CLEAN_ORDINI:
            return {
                ...state,
                ordini: [],
                tuttiOrdini: [],
                loading: false
            };
        case CLEAN_ALLEGATI_ORDINE:
            return {
                ...state,
                allegati: [],
                loading: false
            };
        case ORDINE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}