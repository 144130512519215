import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import Ordine from './Ordine';
import Spinner from '../../layout/Spinner';
import { switchSidebarLogo, trasformaCifra, pageButtonUI } from '../../../utility/Utility';
import { getOrdiniMagazziniere, filtraOrdiniMagazziniere, cleanOrdini } from '../../../actions/ordine';
import { setSidebarPosition } from '../../../actions/sidebar';
const OrdiniMagazziniere = ({ auth: { user }, sidebar, ordine: { ordini, numberOrdini, loading }, getOrdiniMagazziniere, filtraOrdiniMagazziniere, setSidebarPosition, cleanOrdini }) => {

    const [totalOrdini, setTotalOrdini] = useState(0);
    const [page, setPage] = useState(0);
    const [ordineOpen, setOrdineOpen] = useState(false);
    const [idOrdine, setIdOrdine] = useState(null);
    const [statoOrdini, setStatoOrdini] = useState('tutti');
    const [parola, setParola] = useState("");
    const [openRow, setOpenRow] = useState(null);
    const stato = statoOrdini;
    const pagination = 10;



    useEffect(() => {
        getOrdiniMagazziniere(page, pagination);
        return () => {
            cleanOrdini();
        }
    }, [getOrdiniMagazziniere, cleanOrdini]);

    useEffect(() => {
        setTotalOrdini(numberOrdini);
        if (numberOrdini / pagination < page) {
            filtraOrdiniMagazziniere(parola, stato, 0, pagination)
            setPage(0);
        }
    }, [ordini]);




    //FUNZIONI BUTTON TABELLA
    const onClickGoToPage = (page) => {
        filtraOrdiniMagazziniere(parola, stato, page, pagination);
        setPage(page);
    }

    const onClickNextPage = () => {
        if (page + 1 !== Math.ceil(totalOrdini / pagination)) {
            filtraOrdiniMagazziniere(parola, stato, page + 1, pagination);
            setPage(page + 1);
        }
    }

    const onClickBackPage = () => {
        if (page !== 0) {
            filtraOrdiniMagazziniere(parola, stato, page - 1, pagination);
            setPage(page - 1);
        }
    }

    const onClickToggleRow = (id) => {
        if (id === openRow) {
            setOpenRow(null);
        } else {
            setOpenRow(id);
        }
    }



    //PER USCIRE DA SCHERMATA ORDINE
    useEffect(() => {
        if (sidebar.position === 'ordinimagazziniere') {
            getOrdiniMagazziniere(page, pagination);
            setOrdineOpen(false);
            setPage(0);
        }
    }, [sidebar, getOrdiniMagazziniere]);

    const onClickGetOrdine = (idOrdine) => {
        setSidebarPosition(user.role, 'ordine');
        setOrdineOpen(true);
        setIdOrdine(idOrdine);
    }

    const onClickChiudiOrdine = () => {
        setOrdineOpen(false);
        getOrdiniMagazziniere(page, pagination);
    }



    //FUNZIONI FILTRA
    const onChangeSearchOrdine = e => {
        setParola(e);
        filtraOrdiniMagazziniere(e, stato, page, pagination);
    }

    const onChangeStatoOrdine = e => {
        setStatoOrdini(e.target.value);
        filtraOrdiniMagazziniere(parola, e.target.value, page, pagination);
    }



    return (loading || !ordini ? <Spinner /> :
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="subheader py-3 py-lg-8  subheader-transparent " id="kt_subheader">
                <div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                            <div className="d-flex align-items-baseline flex-wrap mr-5">
                                <h2 className="subheader-title text-dark font-weight-bold my-1 mr-3">Commissioni</h2>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <a onClick={() => setSidebarPosition(user.role, 'nuovopreventivo')} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-file-medical icon-lg"></i>
                            </span> Nuovo Preventivo
			            </a>
                        <a onClick={() => setSidebarPosition(user.role, 'nuovocliente')} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-user-plus icon-lg"></i>
                            </span> Nuovo Cliente
			            </a>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    {ordineOpen ?
                        <Ordine
                            idOrdine={idOrdine}
                            onClickChiudiOrdine={onClickChiudiOrdine}
                        /> :
                        <div className="card card-custom">
                            <div className="card-header flex-wrap border-0 pt-6 pb-0">
                                <div className="card-title">
                                    <h3 className="card-label">
                                        Lista Commissioni
				                        <span className="d-block  pt-2 font-size-sm">Tutte le commissioni</span>
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="mb-7">
                                    <div className="row align-items-center">
                                        <div className="col-lg-9 col-xl-8">
                                            <div className="row align-items-center">
                                                <div className="col-md-4 my-2 my-md-0">
                                                    <div className="input-icon">
                                                        <input onChange={e => onChangeSearchOrdine(e.target.value)} value={parola} type="text" className="form-control" placeholder="Cerca..." id="kt_datatable_search_query" />
                                                        <span><i className="flaticon2-search-1 "></i></span>
                                                    </div>
                                                </div>
                                                <div class="col-md-4 my-2 my-md-0">
                                                    <div className="input">
                                                        <select class="form-control" name="statoOrdine" onChange={e => onChangeStatoOrdine(e)} >
                                                            <option selected={stato === 'tutti'} value={'tutti'}>Qualsiasi Stato</option>
                                                            <option selected={stato === 'magazzino'} value={'magazzino'}>In magazzino</option>
                                                            <option selected={stato === 'magazzinoparziale'} value={'magazzinoparziale'}>In magazzino parz.</option>
                                                            <option selected={stato === 'consegna'} value={'consegna'}>Da consegnare</option>
                                                            <option selected={stato === 'consegnaparziale'} value={'consegnaparziale'}>Da consegnare parz.</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded" id="kt_datatable" >
                                    <table className="datatable-table" style={{ display: "block" }}>
                                        <thead className="datatable-head">
                                            <tr className="datatable-row" style={{ left: "0px" }}>
                                                <th className="datatable-cell datatable-toggle-detail">
                                                    <span></span>
                                                </th>
                                                <th data-field="OrderID" className="datatable-cell">
                                                    <span style={{ width: "50px" }}>N.</span>
                                                </th>
                                                <th data-field="ShipAddress" className="datatable-cell">
                                                    <span style={{ width: "130px" }}>Cognome</span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell">
                                                    <span style={{ width: "130px" }}>Nome</span>
                                                </th>
                                                <th data-field="ShipDate" className="datatable-cell">
                                                    <span style={{ width: "150px" }}>Venditore</span>
                                                </th>
                                                <th data-field="Status" className="datatable-cell">
                                                    <span style={{ width: "100px" }}>Totale</span>
                                                </th>
                                                <th data-field="Status" className="datatable-cell">
                                                    <span style={{ width: "180px" }}>Stato</span>
                                                </th>
                                                <th data-field="Actions" data-autohide-disabled="false" className="datatable-cell">
                                                    <span style={{ width: "80px" }}>Modifica</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="datatable-body" >
                                            {ordini.map((ordine, i) => (
                                                <Fragment>
                                                    <tr data-row="0" className="datatable-row" style={{ left: "0px" }}>
                                                        <td className="datatable-cell datatable-toggle-detail">
                                                            <a className="datatable-toggle-detail" onClick={() => onClickToggleRow(ordine._id)} >
                                                                <i className={ordine._id === openRow ? "fa fa-caret-down" : "fa fa-caret-right"}></i>
                                                            </a>
                                                        </td>
                                                        <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                            <span style={{ width: "50px" }}>{ordine.numero}</span>
                                                        </td>
                                                        <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                            <span style={{ width: "130px" }}>{ordine.cliente.cognome.charAt(0).toUpperCase() + ordine.cliente.cognome.slice(1)}</span>
                                                        </td>
                                                        <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                            <span style={{ width: "130px" }}>{ordine.cliente.nome.charAt(0).toUpperCase() + ordine.cliente.nome.slice(1)}</span>
                                                        </td>
                                                        <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                            <span style={{ width: "150px" }}>
                                                                <div class="d-flex align-items-center">
                                                                    <div class="mr-1">
                                                                        <a class="text-dark-75  mb-0">{ordine.venditore.cognome.charAt(0).toUpperCase() + ordine.venditore.cognome.slice(1)}</a>
                                                                    </div>
                                                                    <div class="symbol symbol-50 symbol-sm flex-shrink-0">
                                                                        <div class="symbol-label">
                                                                            <img class="h-75 align-self-center" src={switchSidebarLogo(ordine.puntoVendita.nomeDb, 'admin')} alt="photo" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </span>
                                                        </td>
                                                        <td data-field="Country" aria-label="Brazil" className="datatable-cell">
                                                            <span style={{ width: "100px" }}>{trasformaCifra(ordine.totale)}€</span>
                                                        </td>
                                                        <td data-field="Status" aria-label="3" className="datatable-cell">
                                                            <span style={{ width: "180px" }} >
                                                                <span className={`${ordine.stato === 'confermato' ? "label label-lg font-weight-bold btn btn-danger label-inline" : ordine.stato === 'magazzinovuoto' ? "label label-lg font-weight-bold btn btn-secondary label-inline" : ordine.stato === 'magazzinoparziale' ? "btn-custom btn btn-light-primary font-weight-bold mr-2-light-primary label-inline" : ordine.stato === 'magazzinopieno' ? "label label-lg font-weight-bold btn btn-primary label-inline" : ordine.stato === 'attesadataconsegna' ? "label label-lg font-weight-bold btn btn-info label-inline" : ordine.stato === 'consegna' ? "label label-lg font-weight-bold btn btn-success label-inline" : ordine.stato === 'consegnaparziale' ? "btn-custom btn btn-light-success font-weight-bold mr-2-light-success label-inline" : "label label-lg font-weight-bold btn btn-dark label-inline"}`}>{ordine.stato === 'confermato' ? "Confermato" : ordine.stato === 'magazzinovuoto' ? "Atteso magazzino " : ordine.stato === 'magazzinoparziale' ? "Magazzino parz." : ordine.stato === 'magazzinopieno' ? "Tutto in Magazzino" : ordine.stato === 'attesadataconsegna' ? "Consegna da programmare" : ordine.stato === 'consegna' ? "Da consegnare" : ordine.stato === 'consegnaparziale' ? "Da consegnare parz." : "Consegnato"}</span>
                                                            </span>
                                                        </td>
                                                        <td data-field="Actions" data-autohide-disabled="false" aria-label="null" className="datatable-cell">
                                                            <span style={{ overflow: "visible", position: "relative", width: "80px" }}>
                                                                <a onClick={() => onClickGetOrdine(ordine._id)} className="btn btn-sm btn-clean btn-icon mr-2" title="Edit details">
                                                                    <span className="svg-icon svg-icon-md">
                                                                        <i className="fas fa-edit icon-md"></i>
                                                                    </span>
                                                                </a>
                                                                {ordine.ordineRiparato ?
                                                                    <a onClick={() => onClickGetOrdine(ordine.ordineRiparazioneAllegato)} className="btn btn-sm btn-clean btn-icon mr-2" title="Edit details">
                                                                        <span className="svg-icon svg-icon-md">
                                                                            <i className="far fa-arrow-alt-circle-right icon-lg"></i>
                                                                            rip.
                                                                        </span>
                                                                    </a>
                                                                    : null}
                                                                {ordine.ordineRiparazione ?
                                                                    <a onClick={() => onClickGetOrdine(ordine.ordineRiparazioneAllegato)} className="btn btn-sm btn-clean btn-icon mr-2" title="Edit details">
                                                                        <span className="svg-icon svg-icon-md">
                                                                            <i className="fas fa-arrow-alt-circle-left icon-lg"></i>
                                                                            orig.
                                                                        </span>
                                                                    </a>
                                                                    : null}
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <tr className="datatable-row-detail" style={openRow !== ordine._id ? { display: "none" } : null}>
                                                        <td className="datatable-detail" colspan="13">
                                                            <table>
                                                                <tr className="datatable-row">
                                                                    <td className="datatable-cell">
                                                                        <span>Fattura:</span>
                                                                    </td>
                                                                    <td data-field="Status" aria-label="3" className="datatable-cell">
                                                                        <span style={{ width: "110px" }}>
                                                                            <span className={`label label-lg font-weight-bold label-light${ordine.fattura ? "-primary label-inline" : "-danger label-inline"}`}>{ordine.fattura ? "Si" : "No"}</span>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr className="datatable-row">
                                                                    <td className="datatable-cell">
                                                                        <span>Caparra confirmatoria:</span>
                                                                    </td>
                                                                    <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                        <span style={{ width: "136px" }}>{trasformaCifra(ordine.acconto)}€</span>
                                                                    </td>
                                                                </tr>
                                                                <tr className="datatable-row">
                                                                    <td className="datatable-cell">
                                                                        <span>Saldo:</span>
                                                                    </td>
                                                                    <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                        <span style={{ width: "136px" }}>{trasformaCifra(ordine.saldo)}€</span>
                                                                    </td>
                                                                </tr>
                                                                <tr className="datatable-row">
                                                                    <td className="datatable-cell">
                                                                        <span>Data preventivo:</span>
                                                                    </td>
                                                                    <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                        <span style={{ width: "136px" }}><Moment format='DD/MM/YYYY'>{ordine.date}</Moment></span>
                                                                    </td>
                                                                </tr>
                                                                <tr className="datatable-row">
                                                                    <td className="datatable-cell">
                                                                        <span>Data conferma preventivo:</span>
                                                                    </td>
                                                                    <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                        <span style={{ width: "136px" }}>{ordine.dataConfermaPreventivo ? <Moment format='DD/MM/YYYY'>{ordine.dataConfermaPreventivo}</Moment> : "Da definire"}</span>
                                                                    </td>
                                                                </tr>
                                                                <tr className="datatable-row">
                                                                    <td className="datatable-cell">
                                                                        <span>Previsione consegna:</span>
                                                                    </td>
                                                                    <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                        <span style={{ width: "136px" }}><Moment format='DD/MM/YYYY'>{ordine.dataPrevista}</Moment></span>
                                                                    </td>
                                                                </tr>
                                                                <tr className="datatable-row">
                                                                    <td className="datatable-cell">
                                                                        <span>Data consegna:</span>
                                                                    </td>
                                                                    <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                        <span style={{ width: "136px" }}>{ordine.dataConsegnaStart ? <Moment format='DD/MM/YYYY'>{ordine.dataConsegnaStart}</Moment> : "Da definire"}</span>
                                                                    </td>
                                                                </tr>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                </Fragment>
                                            )
                                            )}
                                        </tbody>
                                    </table>
                                    <div className="datatable-pager datatable-paging-loaded">
                                        <ul className="datatable-pager-nav mb-5 mb-sm-0">
                                            <li>
                                                <a title="Previous" className={`datatable-pager-link datatable-pager-link-prev ${page === 0 ? "datatable-pager-link-disabled" : null}`} data-page="1" disabled={page === 0} onClick={() => onClickBackPage()}>
                                                    <i className="flaticon2-back"></i>
                                                </a>
                                            </li>
                                            {pageButtonUI(totalOrdini, pagination, page, onClickGoToPage)}
                                            <li>
                                                <a title="Next" className={`datatable-pager-link datatable-pager-link-first ${page + 1 === Math.ceil(totalOrdini / pagination) ? "datatable-pager-link-disabled" : null}`} data-page="1" disabled={page + 1 === Math.ceil(totalOrdini / pagination)} onClick={() => onClickNextPage()}>
                                                    <i className="flaticon2-next"></i>
                                                </a>
                                            </li>
                                        </ul>
                                        <div className="datatable-pager-info">
                                            <span className="datatable-pager-detail">Risultati: {totalOrdini}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

OrdiniMagazziniere.protoTypes = {
    getOrdiniMagazziniere: PropTypes.func.isRequired,
    filtraOrdiniMagazziniere: PropTypes.func.isRequired,
    setSidebarPosition: PropTypes.func.isRequired,
    cleanOrdini: PropTypes.func.isRequired,
    sidebar: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    ordine: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    sidebar: state.sidebar,
    auth: state.auth,
    ordine: state.ordine
});

export default connect(mapStateToProps, { getOrdiniMagazziniere, filtraOrdiniMagazziniere, setSidebarPosition, cleanOrdini })(OrdiniMagazziniere);

