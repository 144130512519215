import axios from 'axios';
import { GET_ORDINI, GET_TUTTI_ORDINI, GET_ORDINI_CONFERMATI, GET_ORDINI_DA_CONSEGNARE, GET_ORDINI_DA_CONSEGNARE_MONTATORI, GET_ORDINI_DEBITORI, GET_TUTTI_ORDINI_DEBITORI, GET_ORDINE, FILTRA_ORDINI, FILTRA_ORDINI_CONFERMATI, FILTRA_ORDINI_DA_CONSEGNARE, FILTRA_ORDINI_DA_CONSEGNARE_MONTATORI, FILTRA_ORDINI_DEBITORI, SET_PAGAMENTO_NOTE_ORDINE, SET_ANNULLA_ORDINE, SET_DATA_CONSEGNA_ORDINE, SET_DATA_CONSEGNA_ORDINE_ON_DRAG, SET_ORDINE_CONSEGNATO, MODIFICA_MAGAZZINO, SET_TUTTO_MAGAZZINO_ELEMENTI_ORDINE, MODIFICA_ORDINE, ABILITA_CONSEGNA, CREATE_ORDINE_RIPARAZIONE, MODIFICA_PAGAMENTO, UPLOAD_ALLEGATI_ORDINE, CLEAN_ORDINE, CLEAN_ORDINI, CLEAN_ALLEGATI_ORDINE, ORDINE_ERROR } from './types';
import { setAlert } from './alert';
import { setSidebarPosition } from '../actions/sidebar'


export const getOrdini = (page, pagination, dataInizio, dataFine) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        page: page,
        pagination: pagination,
        dataInizio: dataInizio,
        dataFine: dataFine
        });
    try{
        const res = await axios.post('/api/ordine/ordini', body, config);
        dispatch({
            type: GET_ORDINI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getTuttiOrdini = (parola, stato, puntoVendita, dataInizio, dataFine, venditoreSelezionato) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        parola: parola,
        stato: stato,
        puntoVendita: puntoVendita,
        dataInizio: dataInizio,
        dataFine: dataFine,
        venditoreSelezionato: venditoreSelezionato
    });
    try{
        const res = await axios.post('/api/ordine/ordini/tuttiOrdini', body, config);
            dispatch({
                type: GET_TUTTI_ORDINI,
                payload: res.data
            });
        
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const getOrdiniMagazziniere = (page, pagination) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        page: page,
        pagination: pagination
        });
    try{
        const res = await axios.post('/api/ordine/ordiniMagazziniere', body, config);
        dispatch({
            type: GET_ORDINI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getOrdiniConfermati = (page, pagination) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        page: page,
        pagination: pagination
        });
    try{
        const res = await axios.post('/api/ordine/ordiniConfermati', body, config);
        dispatch({
            type: GET_ORDINI_CONFERMATI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getOrdiniDaConsegnare = () => async dispatch => {
    try{
        const res = await axios.get('/api/ordine/ordiniDaConsegnare');
        dispatch({
            type: GET_ORDINI_DA_CONSEGNARE,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getOrdiniDaConsegnareMontatori = () => async dispatch => {
    try{
        const res = await axios.get('/api/ordine/ordiniDaConsegnareMontatori');
        dispatch({
            type: GET_ORDINI_DA_CONSEGNARE_MONTATORI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getOrdiniDebitori = (page, pagination) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        page: page,
        pagination: pagination
        });
    try{
        const res = await axios.post('/api/ordine/ordiniDebitori', body, config);
        dispatch({
            type: GET_ORDINI_DEBITORI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getTuttiOrdiniDebitori = (parola, stato, puntoVendita) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        parola: parola,
        stato: stato,
        puntoVendita: puntoVendita
        });
    try{
        const res = await axios.post('/api/ordine/getTuttiOrdiniDebitori', body, config);
        dispatch({
            type: GET_TUTTI_ORDINI_DEBITORI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getOrdine = (idOrdine) => async dispatch => {
    try{
        const res = await axios.get(`/api/ordine/ordini/ordine/${idOrdine}`);
        dispatch({
            type: GET_ORDINE,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const filtraOrdini = (parola, stato, puntoVendita, page, pagination, dataInizio, dataFine, venditoreSelezionato) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        parola: parola,
        stato: stato,
        puntoVendita: puntoVendita,
        page: page,
        pagination: pagination,
        dataInizio: dataInizio,
        dataFine: dataFine,
        venditoreSelezionato: venditoreSelezionato
    });
    try{
        const res = await axios.post('/api/ordine/ordini/filtraOrdini', body, config);
            dispatch({
                type: FILTRA_ORDINI,
                payload: res.data
            });
        
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const filtraOrdiniPerNominativo = (parola, page, pagination, dataInizio, dataFine) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        parola: parola,
        page: page,
        pagination: pagination,
        dataInizio: dataInizio, 
        dataFine: dataFine
    });
    try{
        const res = await axios.post('/api/ordine/ordini/filtraOrdiniPerNominativo', body, config);
            dispatch({
                type: FILTRA_ORDINI,
                payload: res.data
            });
        
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const filtraTuttiOrdiniPerNominativo = (parola, dataInizio, dataFine) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        parola: parola,
        dataInizio: dataInizio, 
        dataFine: dataFine
    });
    try{
        const res = await axios.post('/api/ordine/ordini/filtraTuttiOrdiniPerNominativo', body, config);
            dispatch({
                type: GET_TUTTI_ORDINI,
                payload: res.data
            });
        
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const filtraOrdiniMagazziniere = (parola, stato, page, pagination) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        parola: parola,
        stato: stato,
        page: page,
        pagination: pagination
    });
    try{
        const res = await axios.post('/api/ordine/ordini/filtraOrdiniMagazziniere', body, config);
            dispatch({
                type: FILTRA_ORDINI,
                payload: res.data
            });
        
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const filtraOrdiniDebitori = (parola, stato, puntoVendita, page, pagination) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        parola: parola,
        stato: stato,
        puntoVendita: puntoVendita,
        page: page,
        pagination: pagination
    });
    try{
        const res = await axios.post('/api/ordine/ordini/filtraOrdiniDebitori', body, config);
            dispatch({
                type: FILTRA_ORDINI_DEBITORI,
                payload: res.data
            });
        
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const filtraOrdiniConfermati = (parola, puntoVendita, page, pagination) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        parola: parola,
        puntoVendita: puntoVendita,
        page: page,
        pagination: pagination
    });
    try{
        const res = await axios.post('/api/ordine/ordini/filtraOrdiniConfermati', body, config);
            dispatch({
                type: FILTRA_ORDINI_CONFERMATI,
                payload: res.data
            });
        
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const filtraOrdiniDaConsegnare = (parola, stato, puntoVendita) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        parola: parola,
        puntoVendita: puntoVendita,
        stato: stato,
    });
    try{
        const res = await axios.post('/api/ordine/ordini/filtraOrdiniDaConsegnare', body, config);
            dispatch({
                type: FILTRA_ORDINI_DA_CONSEGNARE,
                payload: res.data
            });
        
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const filtraOrdiniDaConsegnareMontatore = (montatore) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        montatore: montatore
    });
    try{
        const res = await axios.post('/api/ordine/ordini/filtraOrdiniDaConsegnareMontatore', body, config);
            dispatch({
                type: FILTRA_ORDINI_DA_CONSEGNARE,
                payload: res.data
            });
        
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const filtraOrdiniDaConsegnareMontatori = (parola, puntoVendita) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        parola: parola,
        puntoVendita: puntoVendita
    });
    try{
        const res = await axios.post('/api/ordine/ordini/filtraOrdiniDaConsegnareMontatori', body, config);
            dispatch({
                type: FILTRA_ORDINI_DA_CONSEGNARE_MONTATORI,
                payload: res.data
            });
        
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const modificaMagazzino = (idOrdine, elementi, statoPagamento, metodoPagamento, note) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idOrdine: idOrdine,
        elementi: elementi,
        statoPagamento: statoPagamento, 
        metodoPagamento: metodoPagamento, 
        note: note
    });
    try{
        const res = await axios.post('/api/ordine/modificaMagazzino', body, config);
            dispatch({
                type: MODIFICA_MAGAZZINO,
                payload: res.data
            });
        dispatch(setAlert('magazzino modificato con successo!', 'success'));

    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const modificaMagazzinoEAcconti = (idOrdine, elementi, statoPagamento, metodoPagamento, note, accontiPersonalizzati, saldo, totale) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idOrdine: idOrdine,
        elementi: elementi,
        statoPagamento: statoPagamento, 
        metodoPagamento: metodoPagamento, 
        note: note,
        accontiPersonalizzati: accontiPersonalizzati,
        saldo: saldo,
        totale: totale
    });
    try{
        const res = await axios.post('/api/ordine/modificaMagazzinoEAcconti', body, config);
            dispatch({
                type: MODIFICA_MAGAZZINO,
                payload: res.data
            });
        dispatch(setAlert('magazzino modificato con successo!', 'success'));

    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const abilitaAllaConsegna = (idOrdine, elementi, statoPagamento, metodoPagamento, note) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idOrdine: idOrdine,
        elementi: elementi,
        statoPagamento: statoPagamento, 
        metodoPagamento: metodoPagamento, 
        note: note
    });
    try{
        const res = await axios.post('/api/ordine/abilitaAllaConsegna', body, config);
            dispatch({
                type: ABILITA_CONSEGNA,
                payload: res.data
            });
        dispatch(setAlert('Commissione abilitata alla consegna!', 'success'));

    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const setTuttoMagazzinoElementi = (idOrdine) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idOrdine: idOrdine
    });
    try{
        const res = await axios.post('/api/ordine/elementi/tuttoMagazzino', body, config);
            dispatch({
                type: SET_TUTTO_MAGAZZINO_ELEMENTI_ORDINE,
                payload: res.data
            });
        
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const annullaOrdine = (idOrdine) => async dispatch => {
    try{
        const res = await axios.get(`/api/ordine/annulla/${idOrdine}`);
        dispatch({
            type: SET_ANNULLA_ORDINE,
            payload: res.data
        });
        dispatch(setAlert('Ordine annullato con successo!', 'success'));
        dispatch(setSidebarPosition('admin', 'ordini'));
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const modificaPagamento = ({idOrdine, statoPagamento, metodoPagamento, notePagamento}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idOrdine: idOrdine,
        metodoPagamento: metodoPagamento,
        notePagamento: notePagamento,
        statoPagamento: statoPagamento
    });
    try{
        const res = await axios.post('/api/ordine/modificaPagamento', body, config);
            dispatch({
                type: MODIFICA_PAGAMENTO,
                payload: res.data
            });
            dispatch(setAlert('Ordine modificato con successo!', 'success'));
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const annullaFornitori = (idOrdine) => async dispatch => {
    try{
        const res = await axios.get(`/api/ordine/annullaFornitori/${idOrdine}`);
        dispatch({
            type: SET_ANNULLA_ORDINE,
            payload: res.data
        });
        dispatch(setAlert('Fornitori annullati con successo!', 'success'));
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const annullaConsegnaOrdine = (idOrdine) => async dispatch => {
    try{
        const res = await axios.get(`/api/ordine/annullaConsegna/${idOrdine}`);
        dispatch({
            type: SET_ANNULLA_ORDINE,
            payload: res.data
        });
        dispatch(setAlert('Consegna annullata con successo!', 'success'));
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const setOrdineConsegnato = (idOrdine, dataConsegnaStart, dataConsegnaEnd, idMontatore, elementi, metodoPagamento, statoPagamento, note) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idOrdine: idOrdine,
        idMontatore: idMontatore,
        dataConsegnaStart: dataConsegnaStart,
        dataConsegnaEnd: dataConsegnaEnd,
        elementi: elementi,
        metodoPagamento: metodoPagamento,
        statoPagamento: statoPagamento,
        note: note
        });
    try{
        const res = await axios.post('/api/ordine/ordini/ordine/consegnato', body, config);
        dispatch({
            type: SET_ORDINE_CONSEGNATO,
            payload: res.data
        });
        dispatch(setAlert('Ordine consegnato con successo!', 'success'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const setDataConsegnaOrdineOnDrag = (idOrdine, dataConsegnaStart, dataConsegnaEnd, montatore) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idOrdine: idOrdine,
        dataConsegnaStart: dataConsegnaStart,
        dataConsegnaEnd: dataConsegnaEnd,
        montatore: montatore
        });
    try{
        const res = await axios.post('/api/ordine/dataConsegnaOnDrag', body, config);
        dispatch({
            type: SET_DATA_CONSEGNA_ORDINE_ON_DRAG,
            payload: res.data
        });
        dispatch(setAlert('Data di consegna impostata con successo!', 'success'));
    }catch(err){
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const setDataConsegnaOrdine = (idOrdine, dataConsegnaStart, dataConsegnaEnd, montatore, elementi, metodoPagamento, statoPagamento, note, accontiPersonalizzati, saldo, totale) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idOrdine: idOrdine,
        dataConsegnaStart: dataConsegnaStart,
        dataConsegnaEnd: dataConsegnaEnd,
        montatore: montatore,
        elementi: elementi,
        metodoPagamento: metodoPagamento,
        statoPagamento: statoPagamento,
        note: note,
        accontiPersonalizzati: accontiPersonalizzati, 
        saldo: saldo, 
        totale: totale
        });
    try{
        const res = await axios.post('/api/ordine/dataConsegna', body, config);
        dispatch({
            type: SET_DATA_CONSEGNA_ORDINE,
            payload: res.data
        });

        dispatch(setSidebarPosition('admin', 'aggiornaordine'));
        dispatch(setAlert('Dati modificati con successo!', 'success'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const abilitaAlMagazzino = (idOrdine, metodoPagamento, statoPagamento, note) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idOrdine: idOrdine,
        metodoPagamento: metodoPagamento,
        statoPagamento: statoPagamento,
        note: note
        });
    try{
        const res = await axios.post('/api/ordine/abilitaAlMagazzino', body, config);
        dispatch({
            type: MODIFICA_ORDINE,
            payload: res.data
        });

        dispatch(setAlert('Dati modificati con successo!', 'success'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const modificaPagamentoNote = (idOrdine, metodoPagamento, statoPagamento, note, accontiPersonalizzati, saldo, totale) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idOrdine: idOrdine,
        metodoPagamento: metodoPagamento,
        statoPagamento: statoPagamento,
        note: note,
        accontiPersonalizzati: accontiPersonalizzati, 
        saldo: saldo, 
        totale: totale
        });
    try{
        const res = await axios.post('/api/ordine/modificaPagamentoNote', body, config);
        dispatch({
            type: SET_PAGAMENTO_NOTE_ORDINE,
            payload: res.data
        });

        dispatch(setAlert('Dati modificati con successo!', 'success'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const modificaOrdine = (datiOrdine) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        _id: datiOrdine._id,
        fattura: datiOrdine.fattura, 
        misure: datiOrdine.misure, 
        totale: datiOrdine.totale,
        acconto: datiOrdine.acconto,
        accontiPersonalizzati: datiOrdine.accontiPersonalizzati,
        saldo: datiOrdine.saldo,
        elementi: datiOrdine.elementi,
        descrizioneGenerale: datiOrdine.descrizioneGenerale,
        note: datiOrdine.note,
        notePagamento: datiOrdine.notePagamento,
        notePreventivo: datiOrdine.notePreventivo,
        tipologia: datiOrdine.tipologia,
        costoProgetto: !datiOrdine.costoProgetto ? 0 : datiOrdine.costoProgetto,
        costoMisure: !datiOrdine.costoMisure ? 0 : datiOrdine.costoMisure,
        costoMagazzino: !datiOrdine.costoMagazzino ? 0 : datiOrdine.costoMagazzino,
        costoSmontaggio: !datiOrdine.costoSmontaggio ? 0 : datiOrdine.costoSmontaggio,
        costoElettricista: !datiOrdine.costoElettricista ? 0 : datiOrdine.costoElettricista,
        costoIdraulico: !datiOrdine.costoIdraulico ? 0 : datiOrdine.costoIdraulico,
        costoElevatore: !datiOrdine.costoElevatore ? 0 : datiOrdine.costoElevatore,
        metodoPagamento: datiOrdine.metodoPagamento,
        pIvaCodiceFiscale: datiOrdine.cliente.pIvaCodiceFiscale,
        nome: datiOrdine.cliente.nome,
        cognome: datiOrdine.cliente.cognome,
        telefono_1: datiOrdine.cliente.telefono_1,
        telefono_2: datiOrdine.cliente.telefono_2,
        telefono_3: datiOrdine.cliente.telefono_3,
        telefono_4: datiOrdine.cliente.telefono_4,
        email_1: datiOrdine.cliente.email_1,
        email_2: datiOrdine.cliente.email_2,
        indirizzo: datiOrdine.cliente.indirizzo,
        cap: datiOrdine.cliente.cap,
        citta: datiOrdine.cliente.citta,
        piano: datiOrdine.cliente.piano,
        scala: datiOrdine.cliente.scala,
        ascensore: datiOrdine.cliente.ascensore,
        ztl: datiOrdine.cliente.ztl,
        dataPrevista: datiOrdine.dataPrevista,
        indirizzoPreventivo: datiOrdine.indirizzoConsegna ? datiOrdine.cliente.indirizzo : datiOrdine.indirizzoPreventivo,
        capPreventivo: datiOrdine.indirizzoConsegna ? datiOrdine.cliente.cap : datiOrdine.capPreventivo,
        cittaPreventivo: datiOrdine.indirizzoConsegna ? datiOrdine.cliente.citta : datiOrdine.cittaPreventivo,
        pianoPreventivo: datiOrdine.indirizzoConsegna ? datiOrdine.cliente.piano : datiOrdine.pianoPreventivo,
        scalaPreventivo: datiOrdine.indirizzoConsegna ? datiOrdine.cliente.scala : datiOrdine.scalaPreventivo,
        ascensorePreventivo: datiOrdine.indirizzoConsegna ? datiOrdine.cliente.ascensore : datiOrdine.ascensorePreventivo,
        ztlPreventivo: datiOrdine.indirizzoConsegna ? datiOrdine.cliente.ztl : datiOrdine.ztlPreventivo
    });
    try{
        const res = await axios.post('/api/ordine/modifica', body, config);
        dispatch({
            type: MODIFICA_ORDINE,
            payload: res.data
        });
        dispatch(setAlert('Ordine modificato con successo!', 'success'));
        //dispatch(setSidebarPosition('admin', 'aggiornaordine'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: ORDINE_ERROR
        });
    }
};

export const CreateNewOrdineRiparazione = (datiPreventivo) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idCliente: datiPreventivo.cliente.idCliente,
        fattura: datiPreventivo.fattura,
        misure: datiPreventivo.misure, 
        elementi: datiPreventivo.elementi,
        allegati: datiPreventivo.allegati,
        dataPrevista: datiPreventivo.dataPrevista,
        descrizioneGenerale: datiPreventivo.descrizioneGenerale,
        note: datiPreventivo.note,
        tipologia: datiPreventivo.tipologia,
        metodoPagamento: datiPreventivo.metodoPagamento,
        ordineAssociato: datiPreventivo.ordineAssociato,
        indirizzoPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.indirizzo : datiPreventivo.indirizzoPreventivo,
        capPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.cap : datiPreventivo.capPreventivo,
        cittaPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.citta : datiPreventivo.cittaPreventivo,
        pianoPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.piano : datiPreventivo.pianoPreventivo,
        scalaPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.scala : datiPreventivo.scalaPreventivo,
        ascensorePreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.ascensore : datiPreventivo.ascensorePreventivo,
        ztlPreventivo: datiPreventivo.indirizzoConsegna ? datiPreventivo.cliente.ztl : datiPreventivo.ztlPreventivo
        });
    try{
        const res = await axios.post('/api/ordine/nuovoOrdineRiparazione', body, config);
        dispatch({
            type: CREATE_ORDINE_RIPARAZIONE,
            payload: res.data
        });
        dispatch(setSidebarPosition('admin', 'ordini'));
        dispatch(setAlert('Nuovo Ordine riparazione creato!', 'success'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: ORDINE_ERROR
        });
    }
};

export const uploadNuovoAllegato = ({ file, fileName, nomeOriginale, fileType, idOrdine, tipologia }) => async dispatch => {
    axios.post("/api/signS3/nuovoAllegato",{
      fileName : fileName,
      fileType : fileType
    })
    .then(response => {
      var returnData = response.data.data.returnData;
      var signedRequest = returnData.signedRequest;
      var options = {
        headers: {
          'Content-Type': fileType
        }
      };
      axios.put(signedRequest,file,options)
      .then( async res => {
        const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const allegato = { "url" : response.data.data.urlImg, "nomeOriginale": nomeOriginale, "type": fileType, "tipologia": tipologia }
    const body = JSON.stringify({ idOrdine: idOrdine, allegato: allegato });
    try{
        const res = await axios.post('/api/ordine/uploadAllegatoOrdine', body, config);
        console.log(res.data)
        dispatch({
            type: UPLOAD_ALLEGATI_ORDINE,
            payload: res.data
        });
        dispatch(setAlert('Allegato aggiunto con successo!', 'success'));
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: ORDINE_ERROR
        });
    }
      })
      .catch(error => {
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: error.response.statusText, status: error.response.status}
        });
      })
    })
    .catch(error => {
        dispatch({
            type: ORDINE_ERROR,
            payload: { msg: error.response.statusText, status: error.response.status}
        });
    })
};


export const eliminaAllegato = (allegato, idOrdine) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idOrdine: idOrdine,
        allegato: allegato
        });
    try{
        const res = await axios.post('/api/ordine/eliminaAllegatoOrdine', body, config);
        dispatch({
            type: UPLOAD_ALLEGATI_ORDINE,
            payload: res.data
        });
        dispatch(setAlert('Allegato eliminato con successo!', 'success'));

    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: ORDINE_ERROR
        });
    }
};


export const uploadAllegatiOrdine = (allegati) => async dispatch => {
    dispatch({
        type: UPLOAD_ALLEGATI_ORDINE,
        payload: allegati
    });
};

export const cleanOrdini = () => async dispatch => {
    dispatch({
        type: CLEAN_ORDINI
    });
};

export const cleanOrdine = () => async dispatch => {
    dispatch({
        type: CLEAN_ORDINE
    });
};

export const cleanAllegati = () => async dispatch => {
    dispatch({
        type: CLEAN_ALLEGATI_ORDINE
    });
};
