import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from 'date-fns/locale/it';
import { uploadNuovoAllegato, eliminaAllegato, getDcumentoPrint } from '../../../actions/preventivo';
import { getOrdine, cleanOrdine, CreateNewOrdineRiparazione, cleanAllegati} from '../../../actions/ordine';
import { setSidebarPosition } from '../../../actions/sidebar';
registerLocale('it', it)

const OrdineRiparazione = ({ uploadNuovoAllegato, eliminaAllegato, cleanAllegati, ordine: { ordine, loading }, allegati, sidebar: { position }, getOrdine, cleanOrdine, CreateNewOrdineRiparazione, setSidebarPosition, idRiparazione, setCorrezioneOpen }) => {

    useEffect(() => {
        cleanAllegati();
        getOrdine(idRiparazione);
        return () => {
            cleanOrdine();
        }
    }, [getOrdine, cleanOrdine, cleanAllegati]);
    const onClickSetCorrezioneOpen = (e) => {
        if(position === 'ordineconsegna'){
            setSidebarPosition('admin', 'ordine');
            setCorrezioneOpen(false)
        }else{
            setSidebarPosition('admin', 'aggiornaordine');
            setCorrezioneOpen(false)
        }
        
    }
    
    const [datiCliente, setDatiCliente] = useState({
        idCliente: '',
        pIvaCodiceFiscale: '',
        nome: '',
        cognome: '',
        telefono_1: '',
        telefono_2: '',
        telefono_3: '',
        telefono_4: '',
        email_1: '',
        email_2: '',
        indirizzo: '',
        cap: '',
        citta: '',
        piano: '',
        scala: '',
        ascensore: '',
        ztl: '',
        indirizzoPreventivo: '',
        capPreventivo: '',
        cittaPreventivo: '',
        pianoPreventivo: '',
        scalaPreventivo: '',
        ascensorePreventivo: '',
        ztlPreventivo: '',
        nomePuntoVendita: '',
        indirizzoPuntoVendita: '',
        telefonoPuntoVendita: '',
        numero: '',
        date: '',
        stato: '',
        magazzino: '',
        misure: '',
        elementi: [],
        dataPrevista: new Date()
    });

    useEffect(() => {
        setDatiCliente({
            idCliente: loading || !ordine ? '' : ordine.cliente._id,
            pIvaCodiceFiscale: loading || !ordine ? '' : ordine.cliente.pIvaCodiceFiscale,
            nome: loading || !ordine ? '' : ordine.cliente.nome,
            cognome: loading || !ordine ? '' : ordine.cliente.cognome,
            telefono_1: loading || !ordine ? '' : ordine.cliente.telefono_1,
            telefono_2: loading || !ordine ? '' : ordine.cliente.telefono_2,
            telefono_3: loading || !ordine ? '' : ordine.cliente.telefono_3,
            telefono_4: loading || !ordine ? '' : ordine.cliente.telefono_4,
            email_1: loading || !ordine ? '' : ordine.cliente.email_1,
            email_2: loading || !ordine ? '' : ordine.cliente.email_2,
            indirizzo: loading || !ordine ? '' : ordine.cliente.indirizzo,
            cap: loading || !ordine ? '' : ordine.cliente.cap,
            citta: loading || !ordine ? '' : ordine.cliente.citta,
            piano: loading || !ordine ? '' : ordine.cliente.piano,
            scala: loading || !ordine ? '' : ordine.cliente.scala,
            ascensore: loading || !ordine ? '' : ordine.cliente.ascensore,
            ztl: loading || !ordine ? '' : ordine.cliente.ztl
        });
    }, [ordine, loading]);

    const { pIvaCodiceFiscale, nome, cognome, telefono_1, telefono_2, telefono_3, telefono_4, email_1, email_2, indirizzo, cap, citta, piano, scala, ascensore, ztl } = datiCliente;

    const [datiPreventivo, setDatiPreventivo] = useState({
        fattura: false,
        misure: 'venditore',
        dataPrevista: new Date(),
        indirizzoPreventivo: '',
        capPreventivo: '',
        cittaPreventivo: '',
        pianoPreventivo: '',
        scalaPreventivo: '',
        ascensorePreventivo: false,
        ztlPreventivo: false,
        preventivoAssociato: idRiparazione,
        descrizioneGenerale: '',
        note: '',
        tipologia: '',
        metodoPagamento: '',
        fileDocumenti: ''
    });

    useEffect(() => {
        setDatiPreventivo({
            fattura: false,
            misure: 'venditore',
            dataPrevista: new Date(),
            indirizzoPreventivo: loading || !ordine ? '' : ordine.indirizzoPreventivo,
            capPreventivo: loading || !ordine ? '' : ordine.capPreventivo,
            cittaPreventivo: loading || !ordine ? '' : ordine.cittaPreventivo,
            pianoPreventivo: loading || !ordine ? '' : ordine.pianoPreventivo,
            scalaPreventivo: loading || !ordine ? '' : ordine.scalaPreventivo,
            ascensorePreventivo: loading || !ordine ? '' : ordine.ascensorePreventivo,
            ztlPreventivo: loading || !ordine ? '' : ordine.ztlPreventivo,
            riparazione: true,
            ordineAssociato: idRiparazione,
            descrizioneGenerale: '',
            note: '',
            tipologia: loading || !ordine ? '' : ordine.tipologia,
            metodoPagamento: 'Nessuno',
            costoProgetto: !ordine ? 0 : ordine.costoProgetto,
            costoMisure: !ordine ? 0 : ordine.costoMisure,
            costoMagazzino: !ordine ? 0 : ordine.costoMagazzino,
            costoSmontaggio: !ordine ? 0 : ordine.costoSmontaggio,
            costoElettricista: !ordine ? 0 : ordine.costoElettricista,
            costoElevatore: !ordine ? 0 : ordine.costoElevatore
        });
    }, [ordine]);

    const { fattura, misure, dataPrevista, indirizzoPreventivo, capPreventivo, cittaPreventivo, pianoPreventivo, scalaPreventivo, ascensorePreventivo, ztlPreventivo, descrizioneGenerale, note, tipologia, metodoPagamento } = datiPreventivo;

    const initialStateElementi = [{ marca: "", descrizione: "", prezzo: 0, riparazione: true }]; 

    const addElementoPreventivo = () => setElementiPreventivo([...elementiPreventivo, { marca: "", descrizione: "", prezzo: null, riparazione: true }]);
    const createUIElementiPreventivo = () => {
        return elementi.map((elemento, i) => (
            <div class="form-group row">
                <div class="col-lg-2">
                    <textarea type="text" class="form-control" placeholder="Inserisci fornitore" name="marca" onChange={e => onChangeSetElementoPreventivo(e, i)} />
                </div>
                <div class="col-lg-8">
                    <textarea type="text" class="form-control" placeholder="Inserisci descrizione" name="descrizione" onChange={e => onChangeSetElementoPreventivo(e, i)} />
                </div>
            </div>
        ))
    }


    const onChangeSetElementoPreventivo = (e, i) => {
        let data = [...elementi];
        data[i] = { ...data[i], [e.target.name]: e.target.value };
        setElementiPreventivo(data);
    }

    
    const [elementiPreventivo, setElementiPreventivo] = useState(initialStateElementi);
    const elementi = elementiPreventivo;

    const onChangeSetDatiPreventivo = e => setDatiPreventivo({ ...datiPreventivo, [e.target.name]: e.target.value });
    const onChangeSetFatturaPreventivo = e => setDatiPreventivo({ ...datiPreventivo, fattura: e.target.value === 'true' ? true : false });
    const onChangeSetMisurePreventivo = e => setDatiPreventivo({ ...datiPreventivo, misure: e.target.value });
    const onChangeDataPrevista = e => setDatiPreventivo({ ...datiPreventivo, dataPrevista: e });

    const [allegato, setAllegato] = useState();
    const processNuovoAllegato = async() => {
        if(allegato.files.length>0){
            const imageFile = allegato.files[0];
            try {
                const file = imageFile;  
                const name = file.name.replace(/\s/g, '');
                const fileType = imageFile.type;
                const part = Math.floor(Date.now() / 1000);
                const fileName = ""+part+"_"+name;
                uploadNuovoAllegato({ file, fileName, nomeOriginale: file.name, fileType, tipologia: 'disegno' });
              } catch (error) {
                console.log(error);
              }
        }
    }


    const onSubmitCreatePreventivo = async e => {
        e.preventDefault();
        datiPreventivo.elementi = elementi;
        datiPreventivo.cliente = datiCliente;
        datiPreventivo.allegati = allegati;
        CreateNewOrdineRiparazione(datiPreventivo);
    };

    const onClickEliminaAllegato = (_id, idOrdine) => {
        var result = window.confirm("Sei sicuro di voler eliminare l'allegato?");
        if (result) {
            eliminaAllegato(_id, idOrdine)
        }
    } 



    return (
        <div className="content  d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="card card-custom example example-compact">
                                <div className="card-header">
                                    <h3 className="card-title">
                                        Ordine di riparazione
				                    </h3>
                                </div>
                                <form className="form" onSubmit={e => onSubmitCreatePreventivo(e)} >
            <div className="card-body">
                <h3 className="font-size-lg text-dark font-weight-bold mb-6">Dati cliente:</h3>
                <div className="">
                    <div class="form-group row">
                    <div class="col-lg-6">
                                <label>Fattura:</label>
                                <div class="radio-inline">
                                    <label class="radio">
                                        <input type="radio" value={true} checked={fattura} onChange={e => onChangeSetFatturaPreventivo(e)} />
                                        <span></span>   Si
                                          
                                    </label>
                                    <label class="radio">
                                        <input type="radio" value={false} checked={!fattura} onChange={e => onChangeSetFatturaPreventivo(e)} />
                                        <span></span>    No
                                    
                                    </label>
                                </div>
                            </div>
                    </div>
                    <div className="mb-15">
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label>P.Iva/C.F.:</label>
                            <input disabled={true} type="text" class="form-control" name="pIvaCodiceFiscale" value={pIvaCodiceFiscale} disabled="true" placeholder="Inserisci P.Iva/C.F." />
                        </div>
                        <div class="col-lg-6">
                            <span></span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-3">
                            <label>Nome:</label>
                            <input disabled={true} type="text" class="form-control" name="nome" value={nome} disabled="true" placeholder="Inserisci nome" />
                        </div>
                        <div class="col-lg-3">
                            <label>Cognome:</label>
                            <input disabled={true} type="text" class="form-control" name="cognome" value={cognome} disabled="true" placeholder="Inserisci cognome" />
                        </div>
                        <div class="col-lg-3">
                            <label>Email 1:</label>
                            <input disabled={true} type="email" class="form-control" name="email_1" value={email_1} disabled="true" placeholder="Inserisci email" />
                        </div>
                        <div class="col-lg-3">
                            <label>Email 2:</label>
                            <input disabled={true} type="email" class="form-control" name="email_2" value={email_2} disabled="true" placeholder="Inserisci email" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-3">
                            <label>Telefono 1:</label>
                            <input disabled={true} type="text" class="form-control" name="telefono_1" value={telefono_1} disabled="true" placeholder="Inserisci telefono" />
                        </div>
                        <div class="col-lg-3">
                            <label>Telefono 2:</label>
                            <input disabled={true} type="text" class="form-control" name="telefono_2" value={telefono_2} disabled="true" placeholder="Inserisci telefono" />
                        </div>
                        <div class="col-lg-3">
                            <label>Telefono 3:</label>
                            <input disabled={true} type="text" class="form-control" name="telefono_3" value={telefono_3} disabled="true" placeholder="Inserisci telefono" />
                        </div>
                        <div class="col-lg-3">
                            <label>Telefono 4:</label>
                            <input disabled={true} type="text" class="form-control" name="telefono_4" value={telefono_4} disabled="true" placeholder="Inserisci telefono" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label>Indirizzo:</label>
                            <input disabled={true} type="text" class="form-control" name="indirizzo" value={indirizzo} disabled="true" placeholder="Inserisci indirizzo" />
                        </div>
                        <div class="col-lg-3">
                            <label>CAP:</label>
                            <input disabled={true} type="text" class="form-control" name="cap" value={cap} disabled="true" placeholder="Inserisci cap" />
                        </div>
                        <div class="col-lg-3">
                            <label>Città:</label>
                            <input disabled={true} type="text" class="form-control" name="citta" value={citta} disabled="true" placeholder="Inserisci città" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-3">
                            <label>Piano:</label>
                            <input disabled={true} type="text" class="form-control" name="piano" value={piano ? piano : "Nessuno"} disabled="true" placeholder="Inserisci piano" />
                        </div>
                        <div class="col-lg-3">
                            <label>Scala:</label>
                            <input disabled={true} type="text" class="form-control" name="scala" value={scala ? scala : "Nessuna"} disabled="true" placeholder="Inserisci scala" />
                        </div>
                        <div class="col-lg-6">
                            <label>Accessibilità:</label>
                            <div class="checkbox-inline">
                                <label class="checkbox">
                                <input disabled={true} type="checkbox" checked={ascensore}  />
                                    <span></span>
                                    Ascensore
                                    
                                </label>
                                <label class="checkbox">
                                <input disabled={true} type="checkbox" checked={ztl}  />
                                    <span></span>
                                     ZTL
                                    
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                    <div className="card-footer mt-5">
                        <div className="row">
                            <div className="col-lg-3"></div>
                        </div>
                    </div>
                    <h3 className="font-size-lg text-dark font-weight-bold mb-6">Indirizzo di consegna:</h3>
                <div className="mb-15">
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label>Indirizzo:</label>
                            <input disabled={true} type="text" class="form-control" name="indirizzoPreventivo" value={indirizzoPreventivo} placeholder="Inserisci indirizzo" />
                        </div>
                        <div class="col-lg-3">
                            <label>CAP:</label>
                            <input disabled={true} type="text" class="form-control" name="capPreventivo" value={capPreventivo} placeholder="Inserisci cap" />
                        </div>
                        <div class="col-lg-3">
                            <label>Città:</label>
                            <input disabled={true} type="text" class="form-control" name="cittaPreventivo" value={cittaPreventivo} placeholder="Inserisci città" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-3">
                            <label>Piano:</label>
                            <input disabled={true} type="text" class="form-control" name="pianoPreventivo" value={pianoPreventivo} placeholder="Inserisci piano" />
                        </div>
                        <div class="col-lg-3">
                            <label>Scala:</label>
                            <input disabled={true} type="text" class="form-control" name="scalaPreventivo" value={scalaPreventivo} placeholder="Inserisci scala" />
                        </div>
                        <div class="col-lg-6">
                            <label>Accessibilità:</label>
                            <div class="checkbox-inline">
                                <label class="checkbox">
                                <input disabled={true} type="checkbox" checked={ascensorePreventivo} />
                                    <span></span> 
                                    Ascensore
                                    
                                </label>
                                <label class="checkbox">
                                <input disabled={true} type="checkbox" checked={ztlPreventivo} />
                                    <span></span>
                                    ZTL
                                    
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-12">
                            <label>Note consegna:</label>
                            <textarea type="text" class="form-control" name="note" value={note} onChange={e => onChangeSetDatiPreventivo(e)} placeholder="Inserisci note" />
                        </div>
                    </div>
                </div>
                <h3 className="font-size-lg text-dark font-weight-bold mb-6">Dati preventivo:</h3>

                    <div className="mb-0">
                        <div class="form-group row">
                            <div class="col-lg-6">
                                <label>Misure:</label>
                                <div class="radio-inline">
                                    <label class="radio">
                                        <input type="radio" value="venditore" checked={misure === "venditore"} onChange={e => onChangeSetMisurePreventivo(e)} />
                                        <span></span>   A carico del venditore
                                          
                                    </label>
                                    <label class="radio">
                                        <input type="radio" value="cliente" checked={misure === "cliente"} onChange={e => onChangeSetMisurePreventivo(e)} />
                                        <span></span>    Comunicati dall'acquirente
                                    
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="form-group row">
                            <div class="col-lg-12">
                                <label>Descrizione generale:</label>
                            </div>
                        </div> 
                        <div class="form-group row">
                            <div class="col-lg-12">
                            <textarea type="text" class="form-control" placeholder="Inserisci descrizione generale" name="descrizioneGenerale" value={descrizioneGenerale} onChange={e => onChangeSetDatiPreventivo(e)} />
                            </div>
                        </div>
                        <div class="form-group row">
                        <div class="col-lg-2">
                                <label>Fornitore</label>
                            </div>
                            <div class="col-lg-8">
                                <label>Descrizione:</label>
                            </div>
                        </div>
                        {createUIElementiPreventivo()}
                        <a class="btn btn-success font-weight-bold btn-pill" onClick={() => addElementoPreventivo()}>+</a>
                    </div>
                    <div className="card-body">
                        <div className="mb-15">
                            <div class="form-group row">
                                <div class="col-lg-8">
                                </div>
                                <div class="col-lg-4">
                                    <label className="mr-5">Stima data consegna merce</label>
                                    <DatePicker
                                        onChange={e => onChangeDataPrevista(e)}
                                        dateFormat='dd/MM/yyyy'
                                        selected={dataPrevista}
                                        className="field w-input form-control"
                                        minDate={new Date()}
                                        locale="it"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
            <div className="col-lg-12 mb-15">
            <h3 className="font-size-lg text-dark font-weight-bold mb-6">Allegati disegno:</h3>
                    {allegati.map((allegato) => (
                                allegato.type === 'image/jpeg' || allegato.type === 'image/png' ?
                                <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4 elemento-file">
                                    <div class="d-flex flex-column">
                                        <a href={allegato.url} target="_blank">
                                            <div class="symbol-label mb-3" style={{backgroundImage: "url(" + allegato.url + ")"}}>
                                            <span class="svg-icon svg-icon-lg mx-2"><i class="far fa-image"></i></span>
                                            </div>
                                            <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                        </a>
                                        <a onClick={()=> { onClickEliminaAllegato(allegato.url) }} class="btn btn-danger font-weight-bolder py-2 font-size-sm">Elimina</a>
                                    </div>   
                                </div> 
                                :
                                <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4 elemento-file">
                                    <div class="d-flex flex-column">
                                    <a href={allegato.url} target="_blank">
                                        <div class="symbol-label mb-3" style={{backgroundImage: "url(" + allegato.url + ")"}}>
                                        <span class="svg-icon svg-icon-lg mx-2"><i class="fas fa-file-pdf"></i></span>PDF
                                        </div>
                                        <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                        </a>
                                        <a onClick={()=> { onClickEliminaAllegato(allegato.url) }} class="btn btn-danger font-weight-bolder py-2 font-size-sm">Elimina</a>
                                    </div>   
                                </div>   
                            ))}
                            <div class="symbol symbol-50 symbol-2by3 flex-shrink-0 mr-4">
                            <label htmlFor="file-input-image">
                                    <div class="d-flex flex-column">
                                    <a>
                                            <div class="symbol-label mb-3 btn-light-primary">
                                            <span class="svg-icon svg-icon-lg mx-2"><i class="fas fa-file-upload"></i></span>
                                            <input style={{display: "none"}} id="file-input-image" ref={(ref) => { setAllegato(ref)}} onChange={() => processNuovoAllegato()} onClick={(event)=> { event.target.value = null }} type="file"/>  
                                            </div>
                                        </a>
                                    </div>  
                                    </label> 
                                </div> 
                    </div>                    
                    </div>
                    <div className="card-footer">
                    <div class="row justify-content-center  px-8  px-md-0">
                    <div class="col-md-12">
                        <div class="d-flex justify-content-between">
                        <button class='btn btn-secondary font-weight-bold' onClick={() => onClickSetCorrezioneOpen(false)}>Indietro</button>
                        <button type="submit" className="btn btn-success mr-2 salva-btn">Salva riparazione</button>
                            
                        </div>
                    </div>
                </div>
                    </div>
                </div>
            </div>
        </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

OrdineRiparazione.protoTypes = {
    uploadNuovoAllegato: PropTypes.func.isRequired,
    eliminaAllegato: PropTypes.func.isRequired,
    CreateNewOrdineRiparazione: PropTypes.func.isRequired,
    getOrdine: PropTypes.func.isRequired,
    cleanOrdine: PropTypes.func.isRequired,
    cleanAllegati: PropTypes.func.isRequired,
    setSidebarPosition: PropTypes.func.isRequired,
    ordine: PropTypes.object.isRequired,
    sidebar: PropTypes.object.isRequired,
    allegati: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    ordine: state.ordine,
    sidebar: state.sidebar,
    allegati: state.preventivo.allegati
});

export default connect(mapStateToProps, { CreateNewOrdineRiparazione, getOrdine, cleanOrdine, cleanAllegati, setSidebarPosition, uploadNuovoAllegato, eliminaAllegato })(OrdineRiparazione);