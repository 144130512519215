import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import { getMontatori } from '../../../actions/montatore';
import { setSidebarPosition } from '../../../actions/sidebar';
const Montatori = ({ auth: { user }, montatore: {montatori, loading}, getMontatori, setSidebarPosition }) => {

    useEffect(() => {
        getMontatori();
    }, [getMontatori]);
    


    return ( loading || !montatori ? <Spinner /> :
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="subheader py-3 py-lg-8  subheader-transparent " id="kt_subheader">
                <div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                            <div className="d-flex align-items-baseline flex-wrap mr-5">
                                <h2 className="subheader-title text-dark font-weight-bold my-1 mr-3">Staff</h2>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <a onClick={() => setSidebarPosition(user.role, 'nuovomontatore')} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-user-plus icon-lg"></i>
                            </span> Nuovo Montatore
			            </a>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-column-fluid">
                <div className=" container "><div className="card card-custom">
                        <div className="card-header flex-wrap border-0 pt-6 pb-0">
                            <div className="card-title">
                                <h3 className="card-label">
                                    Lista Montatori
				<span className="d-block  pt-2 font-size-sm">Tutti i Montatori</span>
                                </h3>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded" id="kt_datatable" >
                                    <table className="datatable-table" style={{display: "block"}}>
                                        <thead className="datatable-head">
                                            <tr className="datatable-row" style={{left: "0px"}}>
                                                <th className="datatable-cell datatable-toggle-detail">
                                                    <span></span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "150px"}}>Username</span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "150px"}}>Nome</span>
                                                </th>
                                                <th data-field="ShipAddress" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "150px"}}>Cognome</span>
                                                </th>
                                                <th data-field="ShipAddress" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "150px"}}>Colore calendario</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="datatable-body" >
                                            {loading ? null : 
                                                montatori.map((montatore, i) => (
                                                    <Fragment>

                                            <tr data-row="0" className="datatable-row" style={{left: "0px"}}>
                                            <th className="datatable-cell datatable-toggle-detail">
                                                    <span></span>
                                                </th>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "150px"}}>{montatore.username}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "150px"}}>{montatore.nome}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "150px"}}>{montatore.cognome}</span>
                                                </td>    
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "150px"}}><input type="color" disabled={true} value={montatore.coloreCalendario} /></span>
                                                </td>  
                                            </tr>
                                            </Fragment>
                                                )
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

Montatori.protoTypes = {
    getMontatori: PropTypes.func.isRequired,
    setSidebarPosition: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    montatore: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    montatore: state.montatore
});

export default connect(mapStateToProps, { getMontatori, setSidebarPosition })(Montatori);

