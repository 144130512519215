import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { switchSidebarLogo, trasformaCifra, getDcumentoPrint } from '../../../../utility/Utility';
import { confermaPreventivo } from '../../../../actions/preventivo';
import condizioniGenerali from '../../../../img/Condizionigenerali.jpg'

const PreventivoDaConfermare = ({ auth: { user }, preventivo, confermaPreventivo, onClickChiudiPreventivo, setModificaOpen, onClickEliminaPreventivo }) => {


    const [datiPreventivo, setDatiPreventivo] = useState({
        idPreventivo: '',
        pIvaCodiceFiscale: '',
        nome: '',
        cognome: '',
        telefono_1: '',
        telefono_2: '',
        telefono_3: '',
        telefono_4: '',
        email_1: '',
        email_2: '',
        indirizzo: '',
        cap: '',
        citta: '',
        piano: '',
        scala: '',
        ascensore: '',
        ztl: '',
        indirizzoPreventivo: '',
        capPreventivo: '',
        cittaPreventivo: '',
        pianoPreventivo: '',
        scalaPreventivo: '',
        ascensorePreventivo: '',
        ztlPreventivo: '',
        nomePuntoVendita: '',
        nomePuntoVenditaDb: '',
        indirizzoPuntoVendita: '',
        telefonoPuntoVendita: '',
        emailPuntoVendita: '',
        sitoWebPuntoVendita: '',
        emailVenditore: '',
        nomeVenditore: '',
        cognomeVenditore: '',
        numero: '',
        date: '',
        stato: '',
        magazzino: '',
        misure: '',
        fattura: '',
        elementi: [],
        acconto: '',
        accontiPersonalizzati : [
            {
                nomeAcconto:'',
                cifraAcconto: null
            },{
                nomeAcconto:'',
                cifraAcconto: null
            }
        ],
        saldo: '',
        totale: '',
        dataPrevista: new Date(),
        descrizioneGenerale: '',
        note: '',
        notePagamento: '',
        notePreventivo: '',
        metodoPagamento: '',
        costoProgetto: {
            prezzo: 0,
            conteggiato: false
        },
        costoMisure: {
            prezzo: 0,
            conteggiato: false
        },
        costoMagazzino: {
            prezzo: 0,
            conteggiato: false
        },
        costoSmontaggio: {
            prezzo: 0,
            conteggiato: false
        },
        costoElettricista: {
            prezzo: 0,
            conteggiato: false
        },
        costoIdraulico: {
            prezzo: 0,
            conteggiato: false
        },
        costoElevatore: {
            prezzo: 0,
            conteggiato: false
        },
        fileDocumenti: ''
    });

    useEffect(() => {
        setDatiPreventivo({
            idPreventivo: !preventivo ? '' : preventivo._id,
            pIvaCodiceFiscale: !preventivo ? '' : preventivo.cliente.pIvaCodiceFiscale,
            nome: !preventivo ? '' : preventivo.cliente.nome,
            cognome: !preventivo ? '' : preventivo.cliente.cognome,
            telefono_1: !preventivo ? '' : preventivo.cliente.telefono_1,
            telefono_2: !preventivo ? '' : preventivo.cliente.telefono_2,
            telefono_3: !preventivo ? '' : preventivo.cliente.telefono_3,
            telefono_4: !preventivo ? '' : preventivo.cliente.telefono_4,
            email_1: !preventivo ? '' : preventivo.cliente.email_1,
            email_2: !preventivo ? '' : preventivo.cliente.email_2,
            indirizzo: !preventivo ? '' : preventivo.cliente.indirizzo,
            cap: !preventivo ? '' : preventivo.cliente.cap,
            citta: !preventivo ? '' : preventivo.cliente.citta,
            indirizzoPreventivo: !preventivo ? '' : preventivo.indirizzoPreventivo,
            capPreventivo: !preventivo ? '' : preventivo.capPreventivo,
            cittaPreventivo: !preventivo ? '' : preventivo.cittaPreventivo,
            pianoPreventivo: !preventivo ? '' : preventivo.pianoPreventivo,
            scalaPreventivo: !preventivo ? '' : preventivo.scalaPreventivo,
            ascensorePreventivo: !preventivo ? '' : preventivo.ascensorePreventivo,
            ztlPreventivo: !preventivo ? '' : preventivo.ztlPreventivo,
            nomePuntoVendita: !preventivo ? '' : preventivo.puntoVendita.nome,
            nomePuntoVenditaDb: !preventivo ? '' : preventivo.puntoVendita.nomeDb,
            indirizzoPuntoVendita: !preventivo ? '' : preventivo.puntoVendita.indirizzo,
            telefonoPuntoVendita: !preventivo ? '' : preventivo.puntoVendita.telefono,
            emailPuntoVendita: !preventivo ? '' : preventivo.puntoVendita.email,
            sitoWebPuntoVendita: !preventivo ? '' : preventivo.puntoVendita.sitoWeb,
            nomeVenditore: !preventivo ? '' : preventivo.venditore.nome,
            cognomeVenditore: !preventivo ? '' : preventivo.venditore.cognome,
            emailVenditore: !preventivo ? '' : preventivo.venditore.email,
            fileDocumenti: !preventivo ? '' : preventivo.venditore.fileDocumenti,
            numero: !preventivo ? '' : preventivo.numero,
            date: !preventivo ? '' : preventivo.date,
            misure: !preventivo ? '' : preventivo.misure,
            fattura: !preventivo ? '' : preventivo.fattura,
            elementi: !preventivo ? '' : preventivo.elementi,
            acconto: !preventivo ? '' : preventivo.acconto,
            accontiPersonalizzati: !preventivo || !preventivo.accontiPersonalizzati || preventivo.accontiPersonalizzati.length <= 0 ? [
                {
                    nomeAcconto:'',
                    cifraAcconto: null
                },{
                    nomeAcconto:'',
                    cifraAcconto: null
                }
            ] : preventivo.accontiPersonalizzati,
            saldo: !preventivo ? '' : preventivo.saldo,
            totale: !preventivo ? '' : preventivo.totale,
            dataPrevista: !preventivo ? '' : new Date(preventivo.dataPrevista),
            descrizioneGenerale: !preventivo ? '' : preventivo.descrizioneGenerale,
            note: !preventivo ? '' : preventivo.note,
            notePagamento: !preventivo ? '' : preventivo.notePagamento,
            notePreventivo: !preventivo ? '' : preventivo.notePreventivo,
            metodoPagamento: !preventivo ? '' : preventivo.metodoPagamento,
            costoProgetto: !preventivo || !preventivo.costoProgetto ? {
                prezzo: 0,
                conteggiato: false
            } : preventivo.costoProgetto,
            costoMisure: !preventivo || !preventivo.costoMisure ? {
                prezzo: 0,
                conteggiato: false
            } : preventivo.costoMisure,
            costoMagazzino: !preventivo || !preventivo.costoMagazzino ? {
                prezzo: 0,
                conteggiato: false
            } : preventivo.costoMagazzino,
            costoSmontaggio: !preventivo || !preventivo.costoSmontaggio ? {
                prezzo: 0,
                conteggiato: false
            } : preventivo.costoSmontaggio,
            costoElettricista: !preventivo || !preventivo.costoElettricista ? {
                prezzo: 0,
                conteggiato: false
            } : preventivo.costoElettricista,
            costoIdraulico: !preventivo || !preventivo.costoIdraulico ? {
                prezzo: 0,
                conteggiato: false
            } : preventivo.costoIdraulico,
            costoElevatore: !preventivo || !preventivo.costoElevatore ? {
                prezzo: 0,
                conteggiato: false
            } : preventivo.costoElevatore
        });
    }, [preventivo]);

    const { idPreventivo, pIvaCodiceFiscale, nome, cognome, telefono_1, telefono_2, telefono_3, telefono_4, email_1, email_2, indirizzo, cap, citta, indirizzoPreventivo, capPreventivo, cittaPreventivo, pianoPreventivo, scalaPreventivo, ascensorePreventivo, ztlPreventivo, nomePuntoVendita, nomePuntoVenditaDb, indirizzoPuntoVendita, telefonoPuntoVendita, emailPuntoVendita, sitoWebPuntoVendita, nomeVenditore, cognomeVenditore, emailVenditore, numero, date, misure, fattura, elementi, acconto, accontiPersonalizzati, saldo, totale, dataPrevista, descrizioneGenerale, note, notePagamento, notePreventivo, metodoPagamento, costoProgetto, costoMisure, costoMagazzino, costoSmontaggio, costoElettricista, costoIdraulico, costoElevatore, fileDocumenti } = datiPreventivo;





    const createUIElementi = () => {
        if (elementi.length > 0) {
            return elementi.map((elemento, i) => (
                <tr class="font-weight-boldest">
                    <td class=" pt-7">
                        {elemento.marca}
                    </td>
                    <td class=" pt-7">
                        {elemento.descrizione}
                    </td>
                    <td class="text-right pt-7 align-middle">{trasformaCifra(elemento.prezzoListino)}€</td>
                    <td class="text-right pt-7 align-middle">{trasformaCifra(elemento.prezzo)}€</td>
                </tr>
            ))
        }
    }

    const onClickConfermaPreventivo = (idPreventivo) => {
        confermaPreventivo(idPreventivo);
    }

    
    const openDocumenti = () => {
        let objFra = document.createElement('iframe');     // Create an IFrame.
        objFra.style.visibility = 'hidden';   
        // Hide the frame.
        objFra.src = getDcumentoPrint(fileDocumenti);                   // Set source.

        document.body.appendChild(objFra);  // Add the frame to the web page.

        objFra.contentWindow.focus();       // Set focus.
        objFra.contentWindow.print(); 
    }


    return (
        <Fragment>
        <div className="card card-custom card-shadowless rounded-top-0 print" id="preventivo">
            <div class="card-body p-0">
                <div id="preventivo" class="row justify-content-center py-8 px-8 py-md-10 px-md-0">
                    <div class="col-md-10">
                        <div class="d-flex justify-content-between pb-10 pb-md-20 flex-column flex-md-row">
                            <div class="d-flex flex-column px-0 flex-root logo-mobile">
                                <div class="logo-stampa">
                                    <img alt="Logo" src={switchSidebarLogo(nomePuntoVenditaDb, user.role, 'grande')} className="max-h-150px" />
                                </div>
                                <h1>{nomePuntoVendita}</h1>
                                <span class=" d-flex flex-column">
                                    <span>{indirizzoPuntoVendita}</span>
                                    <span>{telefonoPuntoVendita}</span>
                                    <span>Ufficio consegne: 0382 573181</span>
                                    <div>Arredatore: <span className="text-nome-cognome">{nomeVenditore} {cognomeVenditore}</span><span> - {emailVenditore}</span></div>
                                    <span>{sitoWebPuntoVendita}</span>
                                    {nomePuntoVenditaDb !== 'arca_arredamenti' ?
                                        <span style={{"color":"red"}}>Instestato a: ARCA ARREDAMENTI CASA SRL</span>
                                    : 
                                        ''
                                    }  
                                    <span style={{"color":"red"}}>Intesa Sanpaolo - IBAN: IT 81 B 03069 11310 1 000 000 79043</span>
                                    <span style={{"color":"red"}}>Intesa Sanpaolo - BIC: BCITITMM</span>
                                </span>
                            </div>
                            <div class="d-flex flex-column flex-root align-items-md-end">
                                <h1 class="display-5 font-weight-boldest mb-10">PREVENTIVO N. {numero}</h1>
                                <span class="font-weight-bolder mb-2"><span class="mr-2">DATA PREVENTIVO: </span>
                                    <span class=""><Moment format='DD/MM/YYYY'>{date}</Moment></span> </span>
                                <span class="font-weight-bolder mb-2"><span class="mr-2">FATTURA</span>
                                    <span class="">{fattura ? "Si" : 'No'}</span></span>
                                    <h2 className="nominativo_allineato" >{cognome} {nome}</h2>
                            </div>
                        </div>
                        <div class="border-bottom w-100"></div>
                        <div class="d-flex justify-content-between pt-6 colonna-mobile mb-5">
                            <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">NOMINATIVO</span>
                                <div>Cognome Nome: <span className="text-nome-cognome">{cognome} {nome}</span></div>
                                {pIvaCodiceFiscale ?
                                    <span class="">P.Iva/C.F: {pIvaCodiceFiscale}</span>
                                    : null}
                                <div>Tel 1: <span className="text-nome-cognome">{telefono_1}</span></div>
                                {telefono_2 ? 
                                <div>Tel 2: <span className="text-nome-cognome">{telefono_2}</span></div>
                                : null }
                                {telefono_3 ? 
                                <div>Tel 3: <span className="text-nome-cognome">{telefono_3}</span></div>
                                : null }
                                {telefono_4 ? 
                                <div>Tel 4: <span className="text-nome-cognome">{telefono_4}</span></div>
                                : null }
                                {email_1 ?
                                    <span class="">Email 1: {email_1}</span>
                                    : null}
                                {email_2 ?
                                    <span class="">Email 2: {email_2}</span>
                                    : null}
                                {indirizzo || cap || citta ?
                                    <span class="">Indirizzo: {indirizzo} {cap}, {citta}</span>
                                    : null}
                            </div>
                            <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">INDIRIZZO CONSEGNA</span>
                                {indirizzoPreventivo || capPreventivo || cittaPreventivo ?
                                    <span class="">Indirizzo: {indirizzoPreventivo}  {capPreventivo}, {cittaPreventivo}</span>
                                    : null}
                                {pianoPreventivo ?
                                    <span class="">Piano: {pianoPreventivo}</span>
                                    : null}
                                {scalaPreventivo ?
                                    <span class="">Scala: {scalaPreventivo}</span>
                                    : null}
                                <span class="">ZTL: {ztlPreventivo ? 'Si' : 'No'}</span>
                                <span class="">Ascensore: {ascensorePreventivo ? 'Si' : 'No'}</span>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between pt-6">
                            <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2"><span class="mr-2">MISURE</span>
                                    <span class="">{misure === 'cliente' ? "Comunicate dall'acquirente" : 'A carico del venditore'}</span></span>
                            </div>
                            <div class="d-flex flex-column flex-root">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="text-left pr-0 font-weight-bold  text-uppercase">Descrizione generale</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="font-weight-boldest">
                                        <td class=" pt-7 d-flex ">
                                            {descrizioneGenerale}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center  px-8  px-md-0">
                    <div class="col-md-10">
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="text-left pr-0 font-weight-bold  text-uppercase">Fornitore</th>
                                        <th class="pl-0 font-weight-bold   text-uppercase">Descrizione</th>
                                        <th class="text-right font-weight-bold  text-uppercase">Prezzo Listino</th>
                                        <th class="text-right font-weight-bold  text-uppercase">Prezzo Scontato</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {createUIElementi()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0 mx-0">
                <div class="mt-10 col-md-10">
                <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="font-weight-bold   text-uppercase">NOTE PREVENTIVO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="font-weight-bolder">
                                        <td>{notePreventivo}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center bg-gray-100 py-8 px-8 py-md-10 px-md-0 mx-0 pagina-immagine">
                <div class="mt-10 col-md-10">
                        <div class="table-responsive">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td class="text-left">Consulenza, progetto/i (di proprietà AR-CA arredamenti fino a stipula del contratto), preventivo/i</td>
                                        <td class={`text-right pr-0 ${costoProgetto.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoProgetto.prezzo)}€</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left">Rilevo misure, disegni per architettonico, disegno per impianti, verifica esecuzione lavori</td>
                                        <td class={`text-right pr-0 ${costoMisure.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoMisure.prezzo)}€</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left">Servizi di magazzinaggio, carico, trasporto, scarico al piano, sballaggio, montaggio, smaltimento imballi</td>
                                        <td class={`text-right pr-0 ${costoMagazzino.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoMagazzino.prezzo)}€</td>
                                    </tr>
                                    <tr>
                                        <td class="text-left">Eventuali servizi per smontaggio, smaltimento, riposizionamento, modifiche, trasloco, montaggio mobili/elettrodomestici del cliente (30,00€ per operaio)</td>
                                        {!costoSmontaggio.conteggiato && (costoSmontaggio.prezzo == 0 || !costoSmontaggio.prezzo) ?
                                            <td class="text-right pr-0 ">da definire</td>
                                            :
                                            <td class={`text-right pr-0 ${costoSmontaggio.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoSmontaggio.prezzo)}€</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td class="text-left">Allacciamenti elettrici (montaggio lampade)</td>
                                        {!costoElettricista.conteggiato && (costoElettricista.prezzo == 0 || !costoElettricista.prezzo) ?
                                            <td class="text-right pr-0 ">da definire</td>
                                            :
                                            <td class={`text-right pr-0 ${costoElettricista.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoElettricista.prezzo)}€</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td class="text-left">Allacciamenti idraulici (acqua e gas)</td>
                                        {!costoIdraulico.conteggiato && (costoIdraulico.prezzo == 0 || !costoIdraulico.prezzo) ?
                                            <td class="text-right pr-0 ">da definire</td>
                                            :
                                            <td class={`text-right pr-0 ${costoIdraulico.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoIdraulico.prezzo)}€</td>
                                        }
                                    </tr>
                                    <tr>
                                        <td class="text-left">Eventuale servizio per occupazione suolo pubblico ed elevatore o facchinaggio</td>
                                        {!costoElevatore.conteggiato && (costoElevatore.prezzo == 0 || !costoElevatore.prezzo) ?
                                            <td class="text-right pr-0 ">da definire</td>
                                            :
                                            <td class={`text-right pr-0 ${costoElevatore.conteggiato ? '' : "prezzo-non-contato"}`}>{trasformaCifra(costoElevatore.prezzo)}€</td>
                                        }
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td colspan="4" class="border-0"></td>
                                        <td class="border-0 pt-0 pb-0 font-weight-bolder font-size-h5 text-right colore-totale">TOTALE PREVENTIVO (IVA INCL.)</td>
                                        <td class="border-0 pt-0 pb-0 font-weight-bolder font-size-h5 text-right pr-0 colore-totale">{trasformaCifra(totale)}€</td>
                                    </tr>
                                    <tr>
                                        <td colspan="4" class="border-0"></td>
                                        <td class="border-0 font-weight-bolder text-right colore-caparra">CAPARRA CONFIRMATORIA</td>
                                        <td class="border-0 font-weight-bolder text-right pr-0 colore-caparra">{trasformaCifra(acconto)}€</td>
                                    </tr>
                                    {accontiPersonalizzati.length > 0 ? parseFloat(accontiPersonalizzati[0].cifraAcconto) > 0 ?
                                    <tr>
                                        <td colspan="4" class="border-0"></td>
                                        <td class="border-0 pt-0 font-weight-bolder text-right colore-caparra">{accontiPersonalizzati[0].nomeAcconto.toUpperCase()}</td>
                                        <td class="border-0 pt-0 font-weight-bolder text-right pr-0 colore-caparra">{trasformaCifra(accontiPersonalizzati[0].cifraAcconto)}€</td>
                                    </tr>
                                    : null
                                : null}
                                    {accontiPersonalizzati.length > 1 ? parseFloat(accontiPersonalizzati[1].cifraAcconto) > 0 ?
                                    <tr>
                                        <td colspan="4" class="border-0"></td>
                                        <td class="border-0 pt-0 font-weight-bolder text-right colore-caparra">{accontiPersonalizzati[1].nomeAcconto.toUpperCase()}</td>
                                        <td class="border-0 pt-0 font-weight-bolder text-right pr-0 colore-caparra">{trasformaCifra(accontiPersonalizzati[1].cifraAcconto)}€</td>
                                    </tr>
                                    : null
                                    : null}
                                    </tbody>
                                    </table>
                                    <table class="table">
                                        <tbody>
                                            <tr>
                                                <td class={`border-0 pt-0 font-weight-bolder text-right`}>
                                                    <span class="noprint float-right">SALDO ALL’ARRIVO MERCE NOSTRI MAGAZZINI</span>
                                                    <div class="wrap_saldo">
                                                        <div class="firma_saldo div-hide print">
                                                            <div class="rect_saldo-wrap">
                                                                <span class="rect_saldo"></span>
                                                                <div class="firma-saldo">.........................................</div>
                                                            </div>
                                                        </div>
                                                        <span class="div-hide print">SALDO ALL’ARRIVO MERCE NOSTRI MAGAZZINI</span>
                                                    </div></td>
                                                <td class={`border-0 pt-0 font-weight-bolder text-right pr-0 tot2`}>{trasformaCifra(saldo)}€</td>
                                            </tr>
                                        </tbody>
                                    </table>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="font-weight-bold   text-uppercase">NOTE PAGAMENTO</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="font-weight-bolder">
                                        <td>{notePagamento}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="table-responsive">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th class="font-weight-bold   text-uppercase">PREVISIONE CONSEGNA INDICATIVA</th>
                                        <th class="font-weight-bold   text-uppercase">NOTE CONSEGNA</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="font-weight-bolder">
                                        <td><Moment format='DD/MM/YYYY'>{dataPrevista}</Moment></td>
                                        <td>{note}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {preventivo.allegati.length > 0 ?
                    <div class="row justify-content-center py-8 px-8 py-md-10 px-md-0 noprint">
                        <div class="col-md-10">
                            <div class="d-flex flex-column flex-root">
                                <span class="font-weight-bolder mb-2">Allegati disegno:</span>
                                <div class="d-flex mb-8">
                                    {preventivo.allegati.map((allegato) => (
                                        allegato.type === 'image/jpeg' || allegato.type === 'image/png' ?
                                            <div class="symbol symbol-70 flex-shrink-0 mr-4 bg-light">
                                                <a href={allegato.url} target="_blank">
                                                    <div class="symbol-label" style={{ backgroundImage: "url(" + allegato.url + ")" }}>
                                                        <span class="svg-icon svg-icon-lg mx-2"><i class="far fa-image"></i></span>
                                                    </div>
                                                    <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                                </a>
                                            </div>
                                            :
                                            <div>
                                            <div class="symbol symbol-70 flex-shrink-0">
                                                <a href={allegato.url} target="_blank" class="h-70px w-70px btn btn-light-primary d-flex flex-column flex-center font-weight-bolder p-0">
                                                    <span class="svg-icon svg-icon-lg m-0"><i class="fas fa-file-pdf"></i></span>                        PDF
                                        </a>
                                            </div>
                                            <div className="nome-pdf">
                                                <p style={{"margin-bottom": "0px !important"}}>{allegato.nomeOriginale}</p>
                                            </div>
                                            </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
                <div class="row justify-content-center print div-hide">
                    <div class="col-md-10 py-8 py-md-10 ">
                        <div className="card-body">
                        <div class="example"><p>CONDIZIONI DI VENDITA: CONSEGNA: Data di consegna è il giorno in cui la merce viene messa a disposizione del cliente. 2) PAGAMENTO: Saldo all’arrivo della merce nei nostri magazzini tramite bonifico bancario o assegno bancario. 3) FORO: Per qualsiasi contestazione circa l'interpretazione e l'esecuzione del presente contratto le parti convengono la competenza del Foro di Pavia</p> </div>
                    </div>
                    </div>
                    <div class="col-md-6">
                        <div class="example">
                            <p>Il Venditore AR-CA Arredamenti srl &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                            <div class="example-preview">
                                <div class="mt-20">
                                    <div class="separator separator-dashed separator-border-2 mb-2"></div>

                                </div>


                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="example">
                            <p>Il Committente, <Moment format='DD/MM/YYYY'>{new Date()}</Moment> Pavia &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</p>
                            <div class="example-preview">
                                <div class="mt-20">
                                    <div class="separator separator-dashed separator-border-2 mb-2"></div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="row justify-content-center py-8 px-8 py-md-10 px-md-0 noprint">
                    <div class="col-md-10">
                        <div class="d-flex justify-content-between">
                            <button class="btn btn-secondary font-weight-bold" onClick={() => onClickChiudiPreventivo()}>Indietro</button>
                            {user.role === 'magazziniere' ? null :
                            <Fragment>
                                <button class="btn btn-info font-weight-bold" onClick={() => window.print()}>Stampa</button>
                                <button class="btn btn-info font-weight-bold" onClick={() => openDocumenti()}>Stampa Dati</button>
                            </Fragment>
                            }
                            {user.role === 'admin' || user.role === 'venditore' && preventivo.venditore._id === user._id ?
                        <Fragment>
                            <button class="btn btn-warning font-weight-bold" onClick={() => setModificaOpen(true)}>Modifica Preventivo</button>
                            <button class="btn btn-success font-weight-bold" onClick={() => onClickConfermaPreventivo(idPreventivo)}>Conferma Preventivo</button>
                        </Fragment>
                    : null}
                        {user.role === 'admin' ? 
                            <button class="btn btn-danger font-weight-bold" onClick={() => onClickEliminaPreventivo(idPreventivo)}>Elimina</button>
                            :
                            ''
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="print div-hide pagina-immagine ">
        <img  src={condizioniGenerali} width="97%" height="auto"/>
        </div>
        </Fragment>

    )
}

PreventivoDaConfermare.protoTypes = {
    confermaPreventivo: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    auth: state.auth
});

export default connect(mapStateToProps, { confermaPreventivo })(PreventivoDaConfermare);

