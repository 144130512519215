import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import OrdineConfermato from './OrdineConfermato';
import OrdineMagazzino from './OrdineMagazzino';
import OrdineConsegna from './OrdineConsegna';
import OrdineConsegnato from './OrdineConsegnato';
import OrdineRiparazione from './OrdineRiparazione';
import Spinner from '../../layout/Spinner';
import { getOrdine, cleanOrdine } from '../../../actions/ordine';
import { setSidebarPosition } from '../../../actions/sidebar';
const Ordine = ({ ordine: { ordine, loading }, sidebar: { position }, setSidebarPosition, getOrdine, cleanOrdine, idOrdine, onClickChiudiOrdine }) => {

    useEffect(() => {
        getOrdine(idOrdine);
        return () => {
            cleanOrdine();
        }
    }, [getOrdine, cleanOrdine]);

    useEffect(() => {
        if(position === 'aggiornaordine'){
            getOrdine(idOrdine);
            setSidebarPosition('admin', 'ordine');
        }
    }, [getOrdine, setSidebarPosition, position]);


    const [correzioneOpen, setCorrezioneOpen] = useState(false);
    

    return ( loading || !ordine ? <Spinner /> :
        correzioneOpen ?
            <OrdineRiparazione
                ordine = { ordine }
                idRiparazione = { idOrdine }
                setCorrezioneOpen = { setCorrezioneOpen }
            />
        :
        ordine.stato === 'confermato' ? 
            <OrdineConfermato
                onClickChiudiOrdine = { onClickChiudiOrdine }
                ordine = { ordine }
            /> 
        : ordine.stato === 'magazzinovuoto' || ordine.stato === 'magazzinoparziale' || ordine.stato === 'magazzinopieno' ? 
            <OrdineMagazzino
                onClickChiudiOrdine = { onClickChiudiOrdine }
                ordine = { ordine }
            />
        : ordine.stato === 'consegna' || ordine.stato === 'consegnaparziale' || ordine.stato === 'attesadataconsegna' ? 
            <OrdineConsegna
                onClickChiudiOrdine = { onClickChiudiOrdine }
                ordine = { ordine }
            />
        : ordine.stato === 'consegnato' ? 
        <OrdineConsegnato
                onClickChiudiOrdine = { onClickChiudiOrdine }
                ordine = { ordine }
                setCorrezioneOpen = { setCorrezioneOpen }
            />
        : null
    )
}

Ordine.protoTypes = {
    getOrdine: PropTypes.func.isRequired,
    setSidebarPosition: PropTypes.func.isRequired,
    cleanOrdine: PropTypes.func.isRequired,
    ordine: PropTypes.object.isRequired,
    sidebar: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    ordine: state.ordine,
    sidebar: state.sidebar
});

export default connect(mapStateToProps, { getOrdine, cleanOrdine, setSidebarPosition })(Ordine);

