import React, { Fragment } from 'react';

const NotFound = () => {
    return (
        <Fragment>
            <h1 className='x-large text-primary'>
            <i className='fas fa-exclamation-triangle' /> Pagina non trovata
            </h1>
            <p className='large'>Scusa, questa pagina non esiste!</p>
        </Fragment>
    )
}

export default NotFound