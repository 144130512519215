import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { setSidebarPosition } from '../../actions/sidebar';
import { logout } from '../../actions/auth';
import { switchSidebarLogo } from '../../utility/Utility';

const Sidebar = ({auth: { user, loading }, setSidebarPosition, logout }) => {

return (
	<div className="noprint">
<div className="aside aside-left d-flex aside-fixed" id="kt_aside">
	<div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
		<div className="aside-brand d-flex flex-column align-items-center flex-column-auto py-5 py-lg-12">
			<a onClick={() => setSidebarPosition(user.role, 'ordini')} >
				{loading || !user ? null :
				    user.role !== 'montatore' && user.role !== 'magazziniere' ?
					<img alt="Logo" src={switchSidebarLogo(user.puntoVendita.nomeDb, user.role)}  className="max-h-60px" />
					:
					<img alt="Logo" src={switchSidebarLogo()}  className="max-h-60px" />
				}
			</a>
		</div>
		<div className="aside-nav d-flex flex-column align-items-center" style={{overflow: "hidden", height: "460px"}}>
			<ul className="nav flex-column" role="tablist">
			{user && (user.role === 'admin' || user.role === 'venditore') ?
			<Fragment>
			<li className="nav-item mb-3" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="Metronic Features" aria-describedby="tooltip632275">
				<a onClick={() => setSidebarPosition(user.role, 'ordini')} className="nav-link btn btn-icon btn-clean btn-lg active" data-toggle="tab" data-target="#kt_aside_tab_0" role="tab" aria-selected="false">
					<span className="svg-icon svg-icon-xl">
					<i className="far fa-money-bill-alt"></i>
					</span>
				</a>
			</li>
				<li className="nav-item mb-3" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="Metronic Features" aria-describedby="tooltip632275">
					<a onClick={() => setSidebarPosition(user.role, 'preventivi')} className="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" data-target="#kt_aside_tab_1" role="tab" aria-selected="false">
						<span className="svg-icon svg-icon-xl">
						<i className="fas fa-file"></i>
						</span>
					</a>
				</li>
				<li className="nav-item mb-3" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="Metronic Features" aria-describedby="tooltip632275">
					<a onClick={() => setSidebarPosition(user.role, 'clienti')} className="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" data-target="#kt_aside_tab_2" role="tab" aria-selected="false">
						<span className="svg-icon svg-icon-xl">
						<i className="fas fa-user"></i>
						</span>
					</a>
				</li>
				</Fragment>
				: user && (user.role === 'magazziniere') ?
				<li className="nav-item mb-3" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="Metronic Features" aria-describedby="tooltip632275">
					<a onClick={() => setSidebarPosition(user.role, 'ordinimagazziniere')} className="nav-link btn btn-icon btn-clean btn-lg active" data-toggle="tab" data-target="#kt_aside_tab_6" role="tab" aria-selected="false">
						<span className="svg-icon svg-icon-xl">
						<i className="fas fa-boxes"></i>
						</span>
					</a>
				</li>
				: 
				<li className="nav-item mb-3" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="Metronic Features" aria-describedby="tooltip632275">
					<a onClick={() => setSidebarPosition(user.role, 'consegnemontatori')} className="nav-link btn btn-icon btn-clean btn-lg active" data-toggle="tab" data-target="#kt_aside_tab_7" role="tab" aria-selected="false">
						<span className="svg-icon svg-icon-xl">
						<i className="fas fa-calendar-alt"></i>
						</span>
					</a>
				</li>
				}
				{user && user.role === 'admin'?
				<Fragment>
				<li className="nav-item mb-3" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="Metronic Features" aria-describedby="tooltip632275">
					<a onClick={() => setSidebarPosition(user.role, 'venditori')} className="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" data-target="#kt_aside_tab_3" role="tab" aria-selected="false">
						<span className="svg-icon svg-icon-xl">
						<i className="fas fa-user-tie"></i>
						</span>
					</a>
				</li>
				</Fragment>
			:
			''
				}
				{user && user.role === 'admin' ?
				<Fragment>
				<li className="nav-item mb-3" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="Metronic Features" aria-describedby="tooltip632275">
				<a onClick={() => setSidebarPosition(user.role, 'magazzino')} className="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" data-target="#kt_aside_tab_4" role="tab" aria-selected="false">
					<span className="svg-icon svg-icon-xl">
					<i className="fas fa-boxes"></i>
					</span>
				</a>
			</li>
			</Fragment>
			:
			''
				}
				{user && (user.role === 'admin' || user.role === 'venditore')?
				<Fragment>
			<li className="nav-item mb-3" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="Metronic Features" aria-describedby="tooltip632275">
				<a onClick={() => setSidebarPosition(user.role, 'consegne')} className="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" data-target="#kt_aside_tab_5" role="tab" aria-selected="false">
					<span className="svg-icon svg-icon-xl">
					<i className="fas fa-calendar-alt"></i>
					</span>
				</a>
			</li>
			</Fragment>
			:
			''
				}
				{user && user.role === 'admin' && user.username === 'danicor' ?
			<li className="nav-item mb-3" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="Metronic Features" aria-describedby="tooltip632275">
				<a onClick={() => setSidebarPosition(user.role, 'statistichegenerali')} className="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" data-target="#kt_aside_tab_8" role="tab" aria-selected="false">
					<span className="svg-icon svg-icon-xl">
					<i className="fas fa-chart-bar"></i>
					</span>
				</a>
			</li>
			:
			''
				}
								{user && (user.role === 'admin' || user.role === 'venditore') ?
			<li className="nav-item mb-3" data-toggle="tooltip" data-placement="right" data-container="body" data-boundary="window" title="" data-original-title="Metronic Features" aria-describedby="tooltip632275">
				<a onClick={() => setSidebarPosition(user.role, 'ordinidebitori')} className="nav-link btn btn-icon btn-clean btn-lg" data-toggle="tab" data-target="#kt_aside_tab_9" role="tab" aria-selected="false">
					<span className="svg-icon svg-icon-xl">
					<i className="far fa-angry"></i>
					</span>
				</a>
			</li>
			:
			''
				}
			</ul>
			<div className="ps__rail-x" style={{left: "0px", bottom: "0px"}}>
				<div className="ps__thumb-x" tabIndex="0" style={{left: "0px", width: "0px"}}></div>
			</div>
			<div className="ps__rail-y" style={{top: "0px", right: "-2px"}}>
				<div className="ps__thumb-y" tabIndex="0" style={{top: "0px", height: "0px"}}></div>
			</div>
		</div>
		<div className="aside-footer d-flex flex-column align-items-center flex-column-auto py-4 py-lg-10">
		<span className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm aside-toggle-active" id="kt_aside_toggle" dataToggle="tooltip" dataPlacement="right" dataContainer="body" dataBoundary="window" title="" data-original-title="Toggle Aside">
					<i className="ki ki-bold-arrow-back icon-sm"></i>
				</span>
			<a onClick={()=>logout()} className="btn btn-icon btn-light-danger btn-lg mb-1" id="kt_quick_actions_toggle" dataToggle="tooltip" dataPlacement="right" dataContainer="body" dataBoundary="window" title="" data-original-title="Quick Actions">
					<span className="svg-icon svg-icon-xl">
						<i className="fas fa-power-off icon-lg"></i>
					</span>				
			</a>
        </div>
		</div>
	<div className="aside-secondary d-flex flex-row-fluid">

		<div className="aside-workspace scroll scroll-push my-2 ps" style={{overflow: "hidden", height: "738px"}}>

			<div className="tab-content">
				{user && (user.role === 'admin' || user.role == 'venditore') ?
					<Fragment>
					<div className="tab-pane fade show active" id="kt_aside_tab_0">
						<div className="aside-menu-wrapper flex-column-fluid px-10 py-5" id="kt_aside_menu_wrapper">
							<div id="kt_aside_menu" className="aside-menu  min-h-lg-800px" data-menu-vertical="1"
								data-menu-scroll="1">
								<ul className="menu-nav ">
								<li className="menu-section ">
									<h4 className="menu-text">Commissioni</h4>
									<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
								</li>
								<li className="menu-item" aria-haspopup="true">
									<a onClick={() => setSidebarPosition(user.role, 'ordini')} className="menu-link ">
										<span className="svg-icon menu-icon">
											<i className="far fa-money-bill-alt icon-lg"></i>
										</span>
										<span className="menu-text">Commissioni</span>
									</a>
								</li>
							</ul>
							</div>
						</div>
					</div>
					<div className="tab-pane fade" id="kt_aside_tab_1">
						<div className="aside-menu-wrapper flex-column-fluid px-10 py-5" id="kt_aside_menu_wrapper">
							<div id="kt_aside_menu" className="aside-menu  min-h-lg-800px" data-menu-vertical="1"
								data-menu-scroll="1">
								<ul className="menu-nav ">
								<li className="menu-section ">
									<h4 className="menu-text">Preventivi</h4>
									<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
								</li>
								<li className="menu-item" aria-haspopup="true">
									<a onClick={() => setSidebarPosition(user.role, 'nuovopreventivo')} className="menu-link ">
										<span className="svg-icon menu-icon">
											<i className="fas fa-file-medical icon-lg"></i>
										</span>
										<span className="menu-text">Nuovo Preventivo</span>
									</a>
								</li>
								<li className="menu-item" aria-haspopup="true">
									<a onClick={() => setSidebarPosition(user.role, 'preventivi')} className="menu-link ">
										<span className="svg-icon menu-icon">
											<i className="fas fa-archive icon-lg"></i>
										</span>
										<span className="menu-text">Tutti i Preventivi</span>
									</a>
								</li>
							</ul>
							</div>
						</div>
					</div>
					<div className="tab-pane fade" id="kt_aside_tab_2">
						<div className="aside-menu-wrapper flex-column-fluid px-10 py-5" id="kt_aside_menu_wrapper">
							<div id="kt_aside_menu" className="aside-menu  min-h-lg-800px" data-menu-vertical="1"
								data-menu-scroll="1">
								<ul className="menu-nav ">
								<li className="menu-section ">
									<h4 className="menu-text">Clienti</h4>
									<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
								</li>
								<li className="menu-item" aria-haspopup="true">
									<a onClick={() => setSidebarPosition(user.role, 'nuovocliente')} className="menu-link ">
										<span className="svg-icon menu-icon">
											<i className="fas fa-user-plus icon-lg"></i>
										</span>
										<span className="menu-text">Nuovo Cliente</span>
									</a>
								</li>
								<li className="menu-item" aria-haspopup="true">
									<a onClick={() => setSidebarPosition(user.role, 'clienti')} className="menu-link ">
										<span className="svg-icon menu-icon">
											<i className="fas fa-users icon-lg"></i>
										</span>
										<span className="menu-text">Tutti i Clienti</span>
									</a>
								</li>
							</ul>
							</div>
						</div>
					</div>
					</Fragment>
				: user && (user.role === 'magazziniere') ?
				<div className="tab-pane fade show active" id="kt_aside_tab_6">
						<div className="aside-menu-wrapper flex-column-fluid px-10 py-5" id="kt_aside_menu_wrapper">
							<div id="kt_aside_menu" className="aside-menu  min-h-lg-800px" data-menu-vertical="1"
								data-menu-scroll="1">
								<ul className="menu-nav ">
								<li className="menu-section ">
									<h4 className="menu-text">Magazzino</h4>
									<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
								</li>
								<li className="menu-item" aria-haspopup="true">
									<a onClick={() => setSidebarPosition(user.role, 'ordinimagazziniere')} className="menu-link ">
										<span className="svg-icon menu-icon">
											<i className="fas fa-boxes icon-lg"></i>
										</span>
										<span className="menu-text">Gestione magazzino</span>
									</a>
								</li>
							</ul>
							</div>
						</div>
					</div>
					: 
					<div className="tab-pane fade show active" id="kt_aside_tab_7">
							<div className="aside-menu-wrapper flex-column-fluid px-10 py-5" id="kt_aside_menu_wrapper">
								<div id="kt_aside_menu" className="aside-menu  min-h-lg-800px" data-menu-vertical="1"
									data-menu-scroll="1">
									<ul className="menu-nav ">
									<li className="menu-section ">
										<h4 className="menu-text">Consegne</h4>
										<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
									</li>
									<li className="menu-item" aria-haspopup="true">
										<a onClick={() => setSidebarPosition(user.role, 'consegnemontatori')} className="menu-link ">
											<span className="svg-icon menu-icon">
												<i className="fas fa-calendar-alt icon-lg"></i>
											</span>
											<span className="menu-text">Consegne programmate</span>
										</a>
									</li>
								</ul>
								</div>
							</div>
						</div>
				}		
					{user && user.role === 'admin'?
					<Fragment>
					<div className="tab-pane fade" id="kt_aside_tab_3">
						<div className="aside-menu-wrapper flex-column-fluid px-10 py-5" id="kt_aside_menu_wrapper">
							<div id="kt_aside_menu" className="aside-menu  min-h-lg-800px" data-menu-vertical="1"
								data-menu-scroll="2">
								<ul className="menu-nav ">
								<li className="menu-section ">
									<h4 className="menu-text">Venditori</h4>
									<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
								</li>
								<li className="menu-item" aria-haspopup="true">
									<a onClick={() => setSidebarPosition(user.role, 'nuovovenditore')} className="menu-link ">
										<span className="svg-icon menu-icon">
											<i className="fas fa-user-plus icon-lg"></i>
										</span>
										<span className="menu-text">Nuovo Venditore</span>
									</a>
								</li>
								<li className="menu-item" aria-haspopup="true">
									<a onClick={() => setSidebarPosition(user.role, 'venditori')} className="menu-link ">
										<span className="svg-icon menu-icon">
											<i className="fas fa-users icon-lg"></i>
										</span>
										<span className="menu-text">Tutti i Venditori</span>
									</a>
								</li>
								<li className="menu-item" aria-haspopup="true">
									<a onClick={() => setSidebarPosition(user.role, 'nuovomontatore')} className="menu-link ">
										<span className="svg-icon menu-icon">
											<i className="fas fa-hammer icon-lg"></i>
										</span>
										<span className="menu-text">Nuovo Montatore</span>
									</a>
								</li>
								<li className="menu-item" aria-haspopup="true">
									<a onClick={() => setSidebarPosition(user.role, 'montatori')} className="menu-link ">
										<span className="svg-icon menu-icon">
											<i className="fas fa-users icon-lg"></i>
										</span>
										<span className="menu-text">Tutti i Montatori</span>
									</a>
								</li>
							</ul>
							</div>
						</div>
					</div>
					<div className="tab-pane fade" id="kt_aside_tab_4">
						<div className="aside-menu-wrapper flex-column-fluid px-10 py-5" id="kt_aside_menu_wrapper">
							<div id="kt_aside_menu" className="aside-menu  min-h-lg-800px" data-menu-vertical="1"
								data-menu-scroll="2">
								<ul className="menu-nav ">
								<li className="menu-section ">
									<h4 className="menu-text">Magazzino</h4>
									<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
								</li>
								<li className="menu-item" aria-haspopup="true">
									<a onClick={() => setSidebarPosition(user.role, 'magazzino')} className="menu-link ">
										<span className="svg-icon menu-icon">
											<i className="fas fa-box-open icon-lg"></i>
										</span>
										<span className="menu-text">Gestione Magazzino</span>
									</a>
								</li>
							</ul>
							</div>
						</div>
					</div>
					<div className="tab-pane fade" id="kt_aside_tab_5">
						<div className="aside-menu-wrapper flex-column-fluid px-10 py-5" id="kt_aside_menu_wrapper">
							<div id="kt_aside_menu" className="aside-menu  min-h-lg-800px" data-menu-vertical="1"
								data-menu-scroll="2">
								<ul className="menu-nav ">
								<li className="menu-section ">
									<h4 className="menu-text">Consegne</h4>
									<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
								</li>
								<li className="menu-item" aria-haspopup="true">
									<a onClick={() => setSidebarPosition(user.role, 'calendarioconsegne')} className="menu-link ">
										<span className="svg-icon menu-icon">
											<i className="fas fa-calendar-alt icon-lg"></i>
										</span>
										<span className="menu-text">Calendario Consegne</span>
									</a>
								</li>
								<li className="menu-item" aria-haspopup="true">
									<a onClick={() => setSidebarPosition(user.role, 'consegne')} className="menu-link ">
										<span className="svg-icon menu-icon">
											<i className="fas fa-calendar-alt icon-lg"></i>
										</span>
										<span className="menu-text">Gestione Consegne</span>
									</a>
								</li>
							</ul>
							</div>
						</div>
					</div>
					<div className="tab-pane fade" id="kt_aside_tab_8">
						<div className="aside-menu-wrapper flex-column-fluid px-10 py-5" id="kt_aside_menu_wrapper">
							<div id="kt_aside_menu" className="aside-menu  min-h-lg-800px" data-menu-vertical="1"
								data-menu-scroll="2">
								<ul className="menu-nav ">
								<li className="menu-section ">
									<h4 className="menu-text">Statistiche</h4>
									<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
								</li>
								<li className="menu-item" aria-haspopup="true">
									<a onClick={() => setSidebarPosition(user.role, 'statistichegenerali')} className="menu-link ">
										<span className="svg-icon menu-icon">
											<i className="fas fa-chart-bar icon-lg"></i>
										</span>
										<span className="menu-text">Statistiche</span>
									</a>
								</li>
							</ul>
							</div>
						</div>
					</div>
					<div className="tab-pane fade" id="kt_aside_tab_9">
						<div className="aside-menu-wrapper flex-column-fluid px-10 py-5" id="kt_aside_menu_wrapper">
							<div id="kt_aside_menu" className="aside-menu  min-h-lg-800px" data-menu-vertical="1"
								data-menu-scroll="2">
								<ul className="menu-nav ">
								<li className="menu-section ">
									<h4 className="menu-text">Debitori</h4>
									<i className="menu-icon ki ki-bold-more-hor icon-md"></i>
								</li>
								<li className="menu-item" aria-haspopup="true">
									<a onClick={() => setSidebarPosition(user.role, 'ordinidebitori')} className="menu-link ">
										<span className="svg-icon menu-icon">
											<i className="far fa-angry icon-lg"></i>
										</span>
										<span className="menu-text">Lista Debitori</span>
									</a>
								</li>
							</ul>
							</div>
						</div>
					</div>
					</Fragment>
					: null 
					}
					</div>
				<div className="ps__rail-x" style={{left: "0px", bottom: "0px"}}>
					<div className="ps__thumb-x" tabIndex="0" style={{left: "0px", width: "0px"}}></div>
				</div>
				<div className="ps__rail-y" style={{top: "0px", right: "5px"}}>
					<div className="ps__thumb-y" tabIndex="0" style={{top: "0px", height: "0px"}}></div>
				</div>
			

		</div>

		</div></div>
		</div>
	)
	}

	Sidebar.protoTypes = {
		setSidebarPosition: PropTypes.func.isRequired,
		logout: PropTypes.func.isRequired,
		auth: PropTypes.object.isRequired
	};
	
	const mapStateToProps = state => ({
		auth: state.auth
	});
	
	 export default connect(mapStateToProps, { setSidebarPosition, logout })(Sidebar);

