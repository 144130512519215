import React from 'react';

import logoArcaArredamenti from '../img/arca_arredamenti.png';
import logoVenetaCucinePavia from '../img/veneta_cucine_pavia.png';
import logoLubeCreoStorePavia from '../img/lube_creo_store_pavia.png';
import logoLubeCreoStorePaviaGrande from '../img/lube_creo_store_pavia_grande.png';
import logoLubeStoreStradella from '../img/lube_store_stradella.png';

import documentoArcaA from '../img/documentoArcaA.pdf'
import documentoArcaB from '../img/documentoArcaB.pdf'
import documentoArcaC from '../img/documentoArcaC.pdf'
import documentoArcaD from '../img/documentoArcaD.pdf'
import documentoArcaE from '../img/documentoArcaE.pdf'
import documentoLubePaviaA from '../img/documentoLubePaviaA.pdf'
import documentoLubeStradellaA from '../img/documentoLubeStradellaA.pdf'
import documentoVenetaA from '../img/documentoVenetaA.pdf'
import documentoVenetaB from '../img/documentoVenetaB.pdf'


export const switchSidebarLogo = (puntoVendita, ruolo, misura) => {
    if(ruolo === 'admin' || ruolo === 'venditore'){
        switch(puntoVendita) {
        case 'arca_arredamenti':
            return logoArcaArredamenti
        case 'lube_creo_store_pavia':
            if(misura && misura === 'grande'){
                return logoLubeCreoStorePaviaGrande
            }else{
                return logoLubeCreoStorePavia
            }
        case 'lube_store_stradella':
            return logoLubeStoreStradella
        case 'veneta_cucine_pavia':
            return logoVenetaCucinePavia
        default:
            return logoArcaArredamenti
        }
    }else{
        return logoArcaArredamenti
    }
}

export const trasformaCifra = numero => {
    if(numero >0){
        let numeroVirgola = numero.toString().replace(".", ",")
        return numeroVirgola.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "."); 
    }else{
        return 0
    }
}
      
export const pageButtonUI = (totalOrdini, pagination, page, onClickGoToPage) => {
    let array = [];
    let newArray = [];
    for(let i=0; i< Math.ceil(totalOrdini/pagination); i++){
        array.push(i);
    }
    if(page === 0){
        newArray = array;
        newArray.length = 5;
    }else{
        let min = page-2 <0 ? 0 : page-2;
        let max = page+2 > Math.ceil(totalOrdini/pagination) ? Math.ceil(totalOrdini/pagination) : 5;
        newArray = array.splice(min, max);
    }

    return newArray.map((i) => (
        <li> 
            <a 
                className={`datatable-pager-link datatable-pager-link-${page === i ? "active" : "number"}`} 
                dataPage={i} 
                title={i+1}
                onClick ={() => onClickGoToPage(i)}
            >
                {i+1}
            </a>
        </li>
    ))
}

export const getRandomColor = () => {
    var letters = '0123456789ABCDEF';
    var color = '#';
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}


export const getDcumentoPrint = (fileDocumenti) => {
    if(fileDocumenti == 'documentoArcaA'){
        return documentoArcaA;
    }
    else if(fileDocumenti == 'documentoArcaB'){
        return documentoArcaB;
    }
    else if(fileDocumenti == 'documentoArcaC'){
        return documentoArcaC;
    }
    else if(fileDocumenti == 'documentoArcaD'){
        return documentoArcaD;
    }
    else if(fileDocumenti == 'documentoArcaE'){
        return documentoArcaE;
    }
    else if(fileDocumenti == 'documentoLubePaviaA'){
        return documentoLubePaviaA;
    }
    else if(fileDocumenti == 'documentoLubeStradellaA'){
        return documentoLubeStradellaA;
    }
    else if(fileDocumenti == 'documentoVenetaA'){
        return documentoVenetaA;
    }
    else if(fileDocumenti == 'documentoVenetaB'){
        return documentoVenetaB;
    }else{
        return documentoArcaA
    }
}
