import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Spinner from '../../layout/Spinner';
import { switchSidebarLogo } from '../../../utility/Utility';
import { getVenditori } from '../../../actions/venditore';
import { setSidebarPosition } from '../../../actions/sidebar';
const Venditori = ({ auth: { user }, venditore: { venditori, loading }, getVenditori, setSidebarPosition }) => {

    useEffect(() => {
        getVenditori();
    }, [getVenditori]);

    return ( loading || !venditori ? <Spinner /> :
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="subheader py-3 py-lg-8  subheader-transparent " id="kt_subheader">
                <div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                            <div className="d-flex align-items-baseline flex-wrap mr-5">
                                <h2 className="subheader-title text-dark font-weight-bold my-1 mr-3">Staff</h2>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <a onClick={() => setSidebarPosition(user.role, 'nuovovenditore')} className="btn btn-fixed-height btn-white btn-hover-primary font-weight-bold px-2 px-lg-5 mr-2">
                            <span className="svg-icon svg-icon-success svg-icon-lg">
                                <i className="fas fa-user-plus icon-lg"></i>
                            </span> Nuovo Venditore
			            </a>
                    </div>
                </div>
            </div>

            <div className="d-flex flex-column-fluid">
                <div className=" container "><div className="card card-custom">
                        <div className="card-header flex-wrap border-0 pt-6 pb-0">
                            <div className="card-title">
                                <h3 className="card-label">
                                    Lista Venditori
				<span className="d-block  pt-2 font-size-sm">Tutti i venditori</span>
                                </h3>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded" id="kt_datatable" >
                                    <table className="datatable-table" style={{display: "block"}}>
                                        <thead className="datatable-head">
                                            <tr className="datatable-row" style={{left: "0px"}}>
                                                <th className="datatable-cell datatable-toggle-detail">
                                                    <span></span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "100px"}}>Username</span>
                                                </th>
                                                <th data-field="ShipAddress" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "130px"}}>Cognome</span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "130px"}}>Nome</span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "150px"}}>Email</span>
                                                </th>
                                                <th data-field="ShipDate" className="datatable-cell datatable-cell-sort">
                                                    <span style={{width: "200px"}}>Punto Vendita</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="datatable-body" >
                                            {loading ? null : 
                                                venditori.map((venditore, i) => (
                                                    <Fragment>

                                            <tr data-row="0" className="datatable-row" style={{left: "0px"}}>
                                            <th className="datatable-cell datatable-toggle-detail">
                                                    <span></span>
                                                </th>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "100px"}}>{venditore.username}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "130px"}}>{venditore.cognome}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "130px"}}>{venditore.nome}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "150px"}}>{venditore.email}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "200px"}}>
                                                    
                                                    <div class="d-flex align-items-center">
                                                        <div class="mr-2">
                                                            <a class="text-dark-75  mb-0">{venditore.puntoVendita.nome} </a>                        
                                                        </div>   
                                                        <div class="symbol symbol-50 symbol-sm flex-shrink-0">
                                                            <div class="symbol-label">
                                                                <img class="h-75" src={switchSidebarLogo(venditore.puntoVendita.nomeDb, user.role)} alt="photo"/>
                                                            </div>
                                                        </div>                  
                                                    </div>
                                                    </span>
                                                </td>
                                            </tr>
                                            </Fragment>
                                                )
                                                )}
                                        </tbody>
                                    </table>
                                </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

Venditori.protoTypes = {
    getVenditori: PropTypes.func.isRequired,
    setSidebarPosition: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    venditore: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    venditore: state.venditore
});

export default connect(mapStateToProps, { getVenditori, setSidebarPosition })(Venditori);

