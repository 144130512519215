import { GET_VENDITORI, CREATE_VENDITORE_SUCCESS, VENDITORE_ERROR } from "../actions/types";

const initialState = {
    venditore: null,
    venditori: [],
    error: {},
    loading: true
}

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case GET_VENDITORI:
            return {
                ...state,
                venditori: payload,
                loading: false
            };
        case CREATE_VENDITORE_SUCCESS:
            return {
                ...state,
                venditori: payload,
                loading: false
            };
        case VENDITORE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}