import React from 'react';


const SearchBarClienteSelected = ({ _id, pIvaCodiceFiscale, nome, cognome, telefono_1, telefono_2, telefono_3, telefono_4, email_1, email_2, indirizzo, cap, citta, piano, scala, ascensore, ztl  }) => {
    if (_id) {
        return (
                <div className="mb-15">
                    <div class="form-group row">
                        <div class="col-lg-3">
                            <label>Cognome:</label>
                            <input type="text" class="form-control" name="cognome" value={cognome} disabled="true" placeholder="Inserisci cognome" />
                        </div>
                        <div class="col-lg-3">
                            <label>Nome:</label>
                            <input type="text" class="form-control" name="nome" value={nome} disabled="true" placeholder="Inserisci nome" />
                        </div>
                        <div class="col-lg-3">
                            <label>Email 1:</label>
                            <input type="email" class="form-control" name="email_1" value={email_1} disabled="true" placeholder="Inserisci email" />
                        </div>
                        <div class="col-lg-3">
                            <label>Email 2:</label>
                            <input type="email" class="form-control" name="email_2" value={email_2} disabled="true" placeholder="Inserisci email" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-3">
                            <label>Telefono 1:</label>
                            <input type="text" class="form-control" name="telefono_1" value={telefono_1} disabled="true" placeholder="Inserisci telefono" />
                        </div>
                        <div class="col-lg-3">
                            <label>Telefono 2:</label>
                            <input type="text" class="form-control" name="telefono_2" value={telefono_2} disabled="true" placeholder="Inserisci telefono" />
                        </div>
                        <div class="col-lg-3">
                            <label>Telefono 3:</label>
                            <input type="text" class="form-control" name="telefono_3" value={telefono_3} disabled="true" placeholder="Inserisci telefono" />
                        </div>
                        <div class="col-lg-3">
                            <label>Telefono 4:</label>
                            <input type="text" class="form-control" name="telefono_4" value={telefono_4} disabled="true" placeholder="Inserisci telefono" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label>P.Iva/C.F.:</label>
                            <input type="text" class="form-control" name="pIvaCodiceFiscale" value={pIvaCodiceFiscale} disabled="true" placeholder="Inserisci P.Iva/C.F." />
                        </div>
                        <div class="col-lg-6">
                            <span></span>
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-6">
                            <label>Indirizzo:</label>
                            <input type="text" class="form-control" name="indirizzo" value={indirizzo} disabled="true" placeholder="Inserisci indirizzo" />
                        </div>
                        <div class="col-lg-3">
                            <label>CAP:</label>
                            <input type="text" class="form-control" name="cap" value={cap} disabled="true" placeholder="Inserisci cap" />
                        </div>
                        <div class="col-lg-3">
                            <label>Città:</label>
                            <input type="text" class="form-control" name="citta" value={citta} disabled="true" placeholder="Inserisci città" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <div class="col-lg-3">
                            <label>Piano:</label>
                            <input type="text" class="form-control" name="piano" value={piano ? piano : "Nessuno"} disabled="true" placeholder="Inserisci piano" />
                        </div>
                        <div class="col-lg-3">
                            <label>Scala:</label>
                            <input type="text" class="form-control" name="scala" value={scala ? scala : "Nessuna"} disabled="true" placeholder="Inserisci scala" />
                        </div>
                        <div class="col-lg-6">
                            <label>Accessibilità:</label>
                            <div class="checkbox-inline">
                                <label class="checkbox">
                                <input type="checkbox" checked={ascensore}  />
                                    <span></span> 
                                    Ascensore
                                    
                                </label>
                                <label class="checkbox">
                                <input type="checkbox" checked={ztl}  />
                                    <span></span>
                                    ZTL
                                    
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
        )
    } else {
        return null
    }
}


export default SearchBarClienteSelected;