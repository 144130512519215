import React from 'react';

const SearchBarCliente = ({ parola, onChangeSearchClienti }) => {

    return (
        <div class="col-lg-12 mt-5">
            <div class="input-icon h-40px">
				<input type="text" class="form-control form-control-lg form-control-solid h-40px" placeholder="Cerca cliente..." id="generalSearch" onChange={onChangeSearchClienti} value={parola} />
				<span>
					<span class="svg-icon svg-icon-lg">
                        <i class="fa fa-search"></i>
                    </span>									
                </span>
		    </div>
        </div>
    )
}

export default SearchBarCliente;