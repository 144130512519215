import { GET_PREVENTIVI, GET_PREVENTIVO, CREATE_PREVENTIVO, PREVENTIVO_CONFERMATO, MODIFICA_PREVENTIVO, MODIFICA_PREVENTIVO_CONFERMATO, CONFERMA_PREVENTIVO_ANNULLATA, FILTRA_PREVENTIVI, UPLOAD_ALLEGATO, UPLOAD_ALLEGATI_MODIFICA_PREVENTIVO, ELIMINA_ALLEGATO, CLEAN_PREVENTIVI, CLEAN_PREVENTIVO, CLEAN_ALLEGATI, UPLOAD_ERROR, PREVENTIVO_ERROR,  } from "../actions/types";

const initialState = {
    preventivo: null,
    preventivi: [],
    numberPreventivi: 0,
    allegati: [],
    error: {},
    loading: true
}

export default function(state = initialState, action){
    const { type, payload } = action;
    switch(type){
        case GET_PREVENTIVI:
        case FILTRA_PREVENTIVI:
            return {
                ...state,
                preventivi: payload.preventivi,
                numberPreventivi: payload.numberPreventivi,
                loading: false
            };
        case GET_PREVENTIVO:
        case PREVENTIVO_CONFERMATO:
        case CONFERMA_PREVENTIVO_ANNULLATA:
        case MODIFICA_PREVENTIVO:
        case MODIFICA_PREVENTIVO_CONFERMATO:
            return {
                ...state,
                preventivo: payload,
                loading: false
            }
        case CREATE_PREVENTIVO:
            return {
                ...state,
                preventivo: payload,
                loading: false
            };
        case UPLOAD_ALLEGATO:
            return {
                ...state,
                allegati: state.allegati.concat(payload),  
                loading: false
            }
        case UPLOAD_ALLEGATI_MODIFICA_PREVENTIVO:
            return {
                ...state,
                allegati: payload,  
                loading: false
            }
        case ELIMINA_ALLEGATO:
            return {
                ...state,
                allegati: state.allegati.filter((allegato) =>  allegato.url !== payload ), 
                loading: false
            }
        case  CLEAN_PREVENTIVI:
            return {
                ...state,
                preventivi: [],
                loading: false
            };
        case CLEAN_PREVENTIVO:
            return {
                ...state,
                preventivo: null,
                loading: false
            };
        case CLEAN_ALLEGATI:
            return {
                ...state,
                allegati: [],
                loading: false
            };
        case PREVENTIVO_ERROR:
        case UPLOAD_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            };
        default:
            return state;
    }
}