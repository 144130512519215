import axios from 'axios';
import { GET_CLIENTI, GET_CLIENTE, MODIFICA_CLIENTE, CREATE_CLIENTE_SUCCESS, FILTRA_CLIENTI, CLIENTE_ERROR } from './types';
import { setAlert } from './alert';
import { setSidebarPosition } from '../actions/sidebar'


export const getClienti = (page, pagination) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        page: page,
        pagination: pagination
        }); 
    try{
        const res = await axios.post('/api/cliente/clienti', body, config);
        dispatch({
            type: GET_CLIENTI,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: CLIENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const getCliente = (idCliente) => async dispatch => {
    try{
        const res = await axios.get(`/api/cliente/cliente/${idCliente}`);
        dispatch({
            type: GET_CLIENTE,
            payload: res.data
        });
    }catch(err){
        dispatch({
            type: CLIENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};


export const modificaCliente = (idCliente, pIvaCodiceFiscale, nome, cognome, telefono_1, telefono_2, telefono_3, telefono_4, email_1, email_2, indirizzo, cap, citta, piano, scala, ascensore, ztl) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        idCliente: idCliente,
        pIvaCodiceFiscale: pIvaCodiceFiscale,
        nome: nome,
        cognome: cognome,
        telefono_1: telefono_1,
        telefono_2: telefono_2,
        telefono_3: telefono_3,
        telefono_4: telefono_4,
        email_1: email_1,
        email_2: email_2,
        indirizzo: indirizzo,
        cap: cap,
        citta: citta,
        piano: piano,
        scala: scala,
        ascensore: ascensore,
        ztl: ztl
        }); 
    try{
        const res = await axios.post('/api/cliente/modificaCliente', body, config);
        dispatch(setSidebarPosition('admin', 'clienti'));
        dispatch(setAlert('Cliente modificato con successo!', 'success'));
        dispatch({
            type: MODIFICA_CLIENTE,
            payload: res.data
        });
    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        dispatch({
            type: CLIENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const filtraClienti = (parola, page, pagination) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        parola: parola,
        page: page,
        pagination: pagination
    });
    try{
        const res = await axios.post('/api/cliente/filtraClienti', body, config);
            dispatch({
                type: FILTRA_CLIENTI,
                payload: res.data
            });
        
    }catch(err){
        dispatch({
            type: CLIENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};

export const createNewCliente = ({ pIvaCodiceFiscale, nome, cognome, telefono_1, telefono_2, telefono_3, telefono_4, email_1, email_2, indirizzo, cap, citta, piano, scala, ascensore, ztl}) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }

    const body = JSON.stringify({ pIvaCodiceFiscale, nome, cognome, telefono_1, telefono_2, telefono_3, telefono_4, email_1, email_2, indirizzo, cap, citta, piano, scala, ascensore, ztl });
    try{
        const res = await axios.post('/api/cliente/nuovo-cliente', body, config);
        dispatch({
            type: CREATE_CLIENTE_SUCCESS,
            payload: res.data
        });
        dispatch(setAlert('Nuovo Cliente creato!', 'success'));
        dispatch(setSidebarPosition('admin', 'clienti'));

    }catch(err){
        const errors = err.response.data.errors;
        if(errors){
            errors.forEach(error => dispatch(setAlert(error.msg, 'error')));
        }
        
        dispatch({
            type: CLIENTE_ERROR
        });
    }
};


export const filterClienti = (parola) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    }
    const body = JSON.stringify({ 
        parola: parola
    });
    try{
        const res = await axios.post('/api/cliente/clienti/filtra', body, config);
            dispatch({
                type: FILTRA_CLIENTI,
                payload: res.data
            });
        
    }catch(err){
        dispatch({
            type: CLIENTE_ERROR,
            payload: { msg: err.response.statusText, status: err.response.status}
        });
    }
};
