import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Spinner from '../../layout/Spinner';
import OrdineMontatore from './OrdineMontatore';
import { switchSidebarLogo } from '../../../utility/Utility';
import { setSidebarPosition } from '../../../actions/sidebar';
import { getOrdiniDaConsegnareMontatori, filtraOrdiniDaConsegnareMontatori, cleanOrdini } from '../../../actions/ordine';
const ConsegneMontatori = ({ auth: { user }, sidebar, ordine: { ordini, numberOrdini, loading }, getOrdiniDaConsegnareMontatori, filtraOrdiniDaConsegnareMontatori, setSidebarPosition, cleanOrdini }) => {

    const [totalOrdini, setTotalOrdini] = useState(null);
    const [ordineOpen, setOrdineOpen] = useState(false);
    const [idOrdine, setIdOrdine] = useState(null);
    const [puntoVenditaOrdini, setPuntoVenditaOrdini] = useState('');
    const puntoVendita = puntoVenditaOrdini;    
    const [calendarWeekends, setCalendarWeekends] = useState(true);
    const [calendarEvents, setCalendarEvents] = useState([]);

    useEffect(() => {
        getOrdiniDaConsegnareMontatori();
        return () => {
            cleanOrdini();
        }
    }, [getOrdiniDaConsegnareMontatori, cleanOrdini]);

    useEffect(() => {
        setTotalOrdini(numberOrdini);
        let arrayCalendar = [];
            ordini.map((ordine) => (
                ordine.dataConsegnaStart ? 
                arrayCalendar.push({
                    id: ordine._id,
                    montatore: ordine.montatore,
                    title: 'N.'+ordine.numero+' - '+ordine.cliente.cognome+' '+ordine.cliente.nome,
                    start: ordine.dataConsegnaStart,
                    end: ordine.dataConsegnaEnd,
                    color: ordine.montatore.coloreCalendario,
                    allDay: false
                })
                    : null
              )) 
              setCalendarEvents(arrayCalendar)
    }, [ordini]);

    useEffect(() => {
        if(sidebar.position === 'consegnemontatori'){
            getOrdiniDaConsegnareMontatori();
            setOrdineOpen(false)
        }
    }, [sidebar, getOrdiniDaConsegnareMontatori]);
    
    const [openRow, setOpenRow] = useState(null);
    const onClickToggleRow = (id) => {
        if(id === openRow){
            setOpenRow(null);
        }else{
            setOpenRow(id);
        }
    }

    const onClickGetOrdine = (idOrdine) => {
        setSidebarPosition(user.role, 'ordinemontatore')
        setOrdineOpen(true)
        setIdOrdine(idOrdine)
    }

    const [parola, setParola] = useState("");

    const onChangeSearchOrdine = e => {
        setParola(e);
        filtraOrdiniDaConsegnareMontatori(e, puntoVendita)
    }


    const onChangePuntoVenditaOrdine = e => {
        setPuntoVenditaOrdini(e.target.value);
        filtraOrdiniDaConsegnareMontatori(parola, e.target.value)
    }

    const onClickChiudiOrdine = e => {
        setOrdineOpen(false);
        getOrdiniDaConsegnareMontatori();
    }


    return ( loading || !ordini ? <Spinner /> :
        <div className="content d-flex flex-column flex-column-fluid" id="kt_content">
            <div className="subheader py-3 py-lg-8  subheader-transparent " id="kt_subheader">
                <div className=" container  d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                    <div className="d-flex align-items-center flex-wrap mr-1">
                        <div className="d-flex align-items-center flex-wrap mr-1">
                            <div className="d-flex align-items-baseline flex-wrap mr-5">
                                <h2 className="subheader-title text-dark font-weight-bold my-1 mr-3">Gestione consegne</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column-fluid">
                <div className=" container ">
                    {ordineOpen ? 
                        <OrdineMontatore
                            idOrdine={idOrdine}
                            onClickChiudiOrdine={onClickChiudiOrdine}
                        /> :
                        <div className="card card-custom">
                            <div className="animated fadeIn p-4 demo-app">
          <div className="demo-app">
              
        <div className="demo-app-calendar">
          <FullCalendar
            timeZone= 'Europe/Rome'
            editable={false}
            slotDuration= '12:00:00'
            expandRows= {true}
            locale= 'it'
            firstDay= '1'
            allDaySlot= {false}
            hiddenDays= {[ 0, 6 ]}
            initialView= 'timeGridDay'
            displayEventTime= {false}
            buttonText= {{
                today:    'Oggi',
                month:    'Mese',
                week:     'Settimana',
                day:      'Giorno',
                list:     'Lista'
              }}
            headerToolbar={{
              left: "prev,next today",
              center: "title",
              right: "dayGridMonth,timeGridWeek,timeGridDay"
            }}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            weekends={calendarWeekends}
            events={calendarEvents}
            slotLabelFormat={ date =>{
                return date.date.hour.toString()<12 ? 'Mattina': 'Pomeriggio';
            }}
            eventClick={info =>{
                setIdOrdine(info.event._def.publicId)
                setSidebarPosition(user.role, 'ordineconsegna')
                setOrdineOpen(true)
            }}
          />
        </div>
      </div>
          </div>
                            <div className="card-header flex-wrap border-0 pt-6 pb-0">
                                <div className="card-title">
                                    <h3 className="card-label">
                                        Lista Ordini Da Consegnare 
				                        <span className="d-block  pt-2 font-size-sm">Tutti gli ordini da consegnare</span>
                                    </h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="mb-7">
                                    <div className="row align-items-center">
                                        <div className="col-lg-9 col-xl-8">
                                            <div className="row align-items-center">
                                                <div className="col-md-4 my-2 my-md-0">
                                                    <div className="input-icon">
                                                        <input onChange={e => onChangeSearchOrdine(e.target.value)} value={parola} type="text" className="form-control" placeholder="Cerca..." id="kt_datatable_search_query" />
                                                        <span><i className="flaticon2-search-1 "></i></span>
                                                    </div>
                                                </div>
                                                    <div class="col-md-4 my-2 my-md-0">
                                                        <div className="input">
						                                    <select class="form-control" name="puntoVenditaPreventivo"  onChange={e => onChangePuntoVenditaOrdine(e)} >
							                                    <option selected={puntoVendita === ''} value={''}>Qualsiasi Punto Vendita</option>
							                                    <option selected={puntoVendita === 'arca_arredamenti'} value={'arca_arredamenti'}>Arca Arredamenti</option>
							                                    <option selected={puntoVendita === 'lube_creo_store_pavia'} value={'lube_creo_store_pavia'}>Lube Creo Store Pavia</option>
							                                    <option selected={puntoVendita === 'lube_store_stradella'} value={'lube_store_stradella'}>Lube Store Stradella</option>
							                                    <option selected={puntoVendita === 'veneta_cucine_pavia'} value={'veneta_cucine_pavia'}>Veneta Cucine Pavia</option>
						                                    </select>
					                                    </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="datatable datatable-bordered datatable-head-custom datatable-default datatable-primary datatable-loaded" id="kt_datatable" >
                                    <table className="datatable-table" style={{display: "block"}}>
                                        <thead className="datatable-head">
                                            <tr className="datatable-row" style={{left: "0px"}}>
                                                <th className="datatable-cell datatable-toggle-detail">
                                                    <span></span>
                                                </th>
                                                <th data-field="OrderID" className="datatable-cell">
                                                    <span style={{width: "50px"}}>N.</span>
                                                </th>
                                                <th data-field="ShipAddress" className="datatable-cell">
                                                    <span style={{width: "110px"}}>Cognome</span>
                                                </th>
                                                <th data-field="Country" className="datatable-cell">
                                                    <span style={{width: "110px"}}>Nome</span>
                                                </th>
                                                <th data-field="ShipDate" className="datatable-cell">
                                                    <span style={{width: "160px"}}>Venditore</span>
                                                </th>
                                                <th data-field="Status" className="datatable-cell">
                                                    <span style={{width: "100px"}}>Totale</span>
                                                </th>
                                                <th data-field="Status" className="datatable-cell">
                                                    <span style={{width: "180px"}}>Stato</span>
                                                </th>
                                                <th data-field="Actions" data-autohide-disabled="false" className="datatable-cell">
                                                    <span style={{width: "70px"}}>Vedi</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="datatable-body" >
                                            {loading ? null : 
                                                ordini.map((ordine, i) => (
                                                    <Fragment>
                                            <tr data-row="0" className="datatable-row" style={{ background: user.role == 'admin' &&  ordine.acconto < parseFloat(ordine.totale)*0.3 ? 'rgb(255 155 166)' : null }}>
                                                <td className="datatable-cell datatable-toggle-detail">
                                                    <a className="datatable-toggle-detail" onClick ={() => onClickToggleRow(ordine._id)} >
                                                        <i className={ordine._id === openRow ? "fa fa-caret-down" : "fa fa-caret-right"}></i>
                                                    </a>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "50px"}}>{ordine.numero}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "110px"}}>{ordine.cliente.cognome.charAt(0).toUpperCase() + ordine.cliente.cognome.slice(1)}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "110px"}}>{ordine.cliente.nome.charAt(0).toUpperCase() + ordine.cliente.nome.slice(1)}</span>
                                                </td>
                                                <td data-field="OrderID" aria-label="64616-103" className="datatable-cell">
                                                    <span style={{width: "160px"}}>
                                                        <div class="d-flex align-items-center">
                                                            <div class="mr-1">
                                                                <a class="text-dark-75  mb-0">{ordine.venditore.cognome.charAt(0).toUpperCase() + ordine.venditore.cognome.slice(1)}</a>                        
                                                            </div>   
                                                            <div class="symbol symbol-50 symbol-sm flex-shrink-0">
                                                                <div class="symbol-label">
                                                                <img class="h-75 align-self-center" src={switchSidebarLogo(ordine.puntoVendita.nomeDb, 'admin')} alt="photo"/>
                                                                </div>
                                                            </div>                  
                                                        </div>
                                                    </span>
                                                </td>
                                                <td data-field="Country" aria-label="Brazil" className="datatable-cell">
                                                    <span style={{width: "100px"}}>{ordine.totale}€</span>
                                                </td>
                                                <td data-field="Status" aria-label="3" className="datatable-cell">
                                                    <span style={{width: "180px"}} >                                                    
                                                        <span className={`${ordine.stato ==='consegna' ? "label label-lg font-weight-bold btn btn-success label-inline" : "btn-custom btn btn-light-success font-weight-bold mr-2-light-success label-inline"}`}>{ordine.stato === 'consegna' ? "Programmata" : "Programmata parz." }</span>
                                                    </span>
                                                </td>
                                                <td data-field="Actions" data-autohide-disabled="false" aria-label="null" className="datatable-cell">
                                                    <span style={{overflow: "visible", position: "relative", width: "70px"}}>
                                                        <a onClick ={() => onClickGetOrdine(ordine._id)} className="btn btn-sm btn-clean btn-icon mr-2" title="Edit details">
                                                            <span className="svg-icon svg-icon-md">
                                                                <i className="far fa-eye icon-md"></i>
                                                            </span>
                                                        </a>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr className="datatable-row-detail" style={openRow!==ordine._id ? {display: "none"} : null}>
                                                <td className="datatable-detail" colspan="13">
                                                    <table>
                                                    <tr className="datatable-row">
                                                    <td className="datatable-cell">
                                                                <span>Fattura:</span>
                                                            </td>
                                                    <td data-field="Status" aria-label="3" className="datatable-cell">
                                                                <span style={{width: "110px"}}> 
                                                                    <span className={`label label-lg font-weight-bold label-light${ordine.fattura ? "-primary label-inline" : "-danger label-inline" }`}>{ordine.fattura ? "Si" : "No"}</span>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Caparra confirmatoria:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{ordine.acconto}€</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Saldo:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{ordine.saldo}€</span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Data preventivo:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}><Moment format='DD/MM/YYYY'>{ordine.date}</Moment></span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Previsione consegna:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}><Moment format='DD/MM/YYYY'>{ordine.dataPrevista}</Moment></span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Data conferma preventivo:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}><Moment format='DD/MM/YYYY'>{ordine.dataConfermaPreventivo}</Moment></span>
                                                            </td>
                                                        </tr>
                                                        <tr className="datatable-row">
                                                            <td className="datatable-cell">
                                                                <span>Data consegna:</span>
                                                            </td>
                                                            <td data-field="CompanyName" aria-label="null" className="datatable-cell" >
                                                                <span style={{width: "136px"}}>{ordine.dataConsegnaStart ? <Moment format='DD/MM/YYYY'>{ordine.dataConsegnaStart}</Moment> : "Da definire"}</span>
                                                            </td>
                                                        </tr>
                                                    </table>
                                                </td>
                                            </tr>
                                            </Fragment>
                                                )
                                                )}
                                        </tbody>
                                    </table>
                                    <div className="datatable-pager datatable-paging-loaded">
                                        <div className="datatable-pager-info">
                                            <span className="datatable-pager-detail">Risultati: {totalOrdini}</span>
                                        </div>
                                    </div>
                                </div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        </div>
    )
}

ConsegneMontatori.protoTypes = {
    getOrdiniDaConsegnareMontatori: PropTypes.func.isRequired,
    filtraOrdiniDaConsegnareMontatori: PropTypes.func.isRequired,
    setSidebarPosition: PropTypes.func.isRequired,
    cleanOrdini: PropTypes.func.isRequired,
    sidebar: PropTypes.object.isRequired,
    auth: PropTypes.object.isRequired,
    ordine: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    sidebar: state.sidebar,
    auth: state.auth,
    ordine: state.ordine
});

export default connect(mapStateToProps, { getOrdiniDaConsegnareMontatori, filtraOrdiniDaConsegnareMontatori, setSidebarPosition, cleanOrdini })(ConsegneMontatori);

