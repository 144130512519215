import { GET_STATISTICHE_VENDITE_MENSILI_ULTIMO_ANNO, GET_STATISTICHE_CONFRONTO_ORDINI_PREVENTIVI, GET_STATISTICHE_INCASSO_PUNTI_VENDITA, GET_STATISTICHE_CONFRONTO_ORDINI_PREVENTIVI_VENDITORI, GET_STATISTICHE_INCASSI_VENDITORI_TRIMESTRALI, GET_STATISTICHE_TIPOLOGIA_ORDINI, STATISTICHE_ERROR } from '../actions/types';

const initialState = {
    venditeUltimoMese: [],
    venditeMensiliUltimoAnno: [],
    incassoPuntiVendita: [],
    confrontoOrdiniPreventivi: [],
    confrontoPreventiviOrdiniVenditori: [],
    incassiVenditoriTrimestrali: [],
    tipologiaOrdini: [],
    error: {},
    loading: true
}

export default function(state = initialState, action){
    const { type, payload } = action;

    switch(type){
        case GET_STATISTICHE_VENDITE_MENSILI_ULTIMO_ANNO:
            return {
                ...state,
                venditeMensiliUltimoAnno: payload,
                loading: false
            };
        case GET_STATISTICHE_CONFRONTO_ORDINI_PREVENTIVI:
            return {
                ...state,
                confrontoOrdiniPreventivi: payload,
                loading: false
            };
        case GET_STATISTICHE_INCASSO_PUNTI_VENDITA:
            return {
                ...state,
                incassoPuntiVendita: payload,
                loading: false
            };
        case GET_STATISTICHE_CONFRONTO_ORDINI_PREVENTIVI_VENDITORI:
            return {
                ...state,
                confrontoPreventiviOrdiniVenditori: payload,
                loading: false
            }
        case GET_STATISTICHE_INCASSI_VENDITORI_TRIMESTRALI:
            return {
                ...state,
                incassiVenditoriTrimestrali: payload,
                loading: false
            }
        case GET_STATISTICHE_TIPOLOGIA_ORDINI:
            return {
                ...state,
                tipologiaOrdini: payload,
                loading: false
            };
        case STATISTICHE_ERROR:
            return {
                ...state,
                error: payload,
                loading: false
            }
        default:
            return state;
    }
}